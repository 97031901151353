import * as actions from "../../../actions/user/report";

const initialState = {
	loading: false,
	items: [],
	drivers: []
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_USERS: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_USERS_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_USERS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: false
			};
		}
		case actions.GET_DRIVERS: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_DRIVERS_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_DRIVERS_SUCCESS: {
			return {
				...state,
				drivers: payload ? payload : [],
				loading: false
			};
		}

		default: {
			return state;
		}
	}
};

export default reducer;
