import moment from "moment";
import * as actions from "../../../actions/order/create";

const initialOrderState = {
  status: "",
  clientId: "",
  userId: "",
  driverId: "",
  deliveryCity: null,
  deliveryCityId: null,
  checkIn: "08:00",
  checkOut: "08:00",
  city: "",
  people: 1,
  moreInfo: "",
  referral: "",
  phoneNumber: "",
  startDate: moment
    .utc()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0),
  endDate: moment
    .utc()
    .add(1, "day")
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0),
  deliveryType: 0,
  deliveryAddress: "",
  mount: false,
  discount: false,
  errors: {}
};

const initialState = {
  loading: false,
  deliveryCities: [],
  ...initialOrderState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialOrderState
      };
    }
    case actions.ADD_ORDER: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_ORDER_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_ORDER_SUCCESS: {
      return {
        ...state,
        ...initialOrderState,
        loading: false
      };
    }
    case actions.GET_DELIVERY_CITIES: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_DELIVERY_CITIES_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_DELIVERY_CITIES_SUCCESS: {
      return {
        ...state,
        deliveryCities: payload,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
