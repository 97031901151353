import * as actions from "../../../actions/product/create";

const initialProductState = {
	file: null,
	image: "",
	status: "1",
	code: "",
	codeError: "",
	name: "",
	nameError: "",
	productCategoryId: "",
	description: "",
	descriptionError: "",
	buyPrice: 0,
	buyPriceError: "",
	rentalPrice: 0,
	rentalPrices: {},
	rentalPriceError: "",
	sellPrice: 0,
	sellPriceError: "",
	stockPrice: 0,
	stockPriceError: "",
	maxLocation: 0,
	maxLocationError: "",
	type: "0",
	weight: 1,
	baseAmount: "",
	baseYear: "",
	lotLimit: 0,
	errors: {}
};

const initialState = {
	loading: false,
	...initialProductState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...initialProductState
			};
		}
		case actions.ADD_PRODUCT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ADD_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_PRODUCT_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
