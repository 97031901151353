import * as api from "services/api";

import _ from "lodash";
import { isValidNumber } from "libphonenumber-js";
import {
  CPFValidation,
  CNPJValidation,
  emailValidation
} from "../../../../helpers/validators";

import history from "../../../../history";

export const EDIT_CLIENT = "[CLIENT EDIT] EDIT_CLIENT";
export const EDIT_CLIENT_SUCCESS = "[CLIENT EDIT] EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILED = "[CLIENT EDIT] EDIT_CLIENT_FAILED";

export const GET_CLIENT = "[CLIENT EDIT] GET_CLIENT";
export const GET_CLIENT_SUCCESS = "[CLIENT EDIT] GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILED = "[CLIENT EDIT] GET_CLIENT_FAILED";

export const SET_VALUE = "[CLIENT EDIT] SET_VALUE";
export const CLEAR_VALUE = "[CLIENT EDIT] CLEAR_VALUE";

export function getClient(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CLIENT
    });

    const { token } = getState().login;

    let response = await api.sendGet("/client/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let client = json.model;
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: client
      });
    } else {
      dispatch({ type: GET_CLIENT_FAILED });
    }
  };
}

export function editClient() {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_CLIENT
    });

    const { login, clientEdit } = getState();

    let {
      type,
      name,
      lastName,
      vip,
      email,
      document,
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      country,
      gender,
      nationality,
      phone,
      isOwner,
      item
    } = clientEdit;

    const errors = {};

    if (phone && !isValidNumber(phone)) {
      errors.phone = "Telefone inválido";
    }

    if (document) {
      if (type === 0) {
        if (!CPFValidation(document)) {
          errors.document = "CPF inválido";
        }
      } else if (type === 1) {
        if (!CNPJValidation(document)) {
          errors.document = "CNPJ inválido";
        }
      }
    }

    if (type !== 1 && gender === 0) {
      errors.gender = "Selecione um sexo";
    }

    if (email && !emailValidation(email)) {
      errors.email = "E-mail inválido";
    }

    if (!name) {
      errors.name = "Informe um nome";
    }

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0)
      return dispatch({ type: EDIT_CLIENT_FAILED });

    let response = await api.sendPut(
      "/client/" + item.id,
      {
        ...item,
        name,
        lastName,
        email,
        vip,
        isOwner,
        document,
        gender,
        nationality,
        type,
        address: {
          ...item.address,
          street,
          number,
          complement,
          neighborhood,
          city,
          state,
          country,
          zipCode
        },
        phoneNumber: {
          ...item.phoneNumber,
          number: phone
        }
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      const result = await response.json();

      dispatch({
        type: EDIT_CLIENT_SUCCESS
      });

      dispatch(clearValue());

      history.push("/clients/" + result.model.id);
    } else {
      dispatch({ type: EDIT_CLIENT_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
