import * as api from "services/api";

import history from "../../../../history";

export const GET_STOCK = "[STOCK DETAIL] GET_STOCK";
export const GET_STOCK_SUCCESS = "[STOCK DETAIL] GET_STOCK_SUCCESS";
export const GET_STOCK_FAILED = "[STOCK DETAIL] GET_STOCK_FAILED";

export const GET_PRODUCT = "[STOCK DETAIL] GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "[STOCK DETAIL] GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "[STOCK DETAIL] GET_PRODUCT_FAILED";

export const CHANGE_PRODUCT_ITEM_STATUS =
	"[STOCK DETAIL] CHANGE_PRODUCT_ITEM_STATUS";
export const CHANGE_PRODUCT_ITEM_STATUS_SUCCESS =
	"[STOCK DETAIL] CHANGE_PRODUCT_ITEM_STATUS_SUCCESS";
export const CHANGE_PRODUCT_ITEM_STATUS_FAILED =
	"[STOCK DETAIL] CHANGE_PRODUCT_ITEM_STATUS_FAILED";

export const CANCEL_STOCK = "[STOCK DETAIL] CANCEL_STOCK";
export const CANCEL_STOCK_SUCCESS = "[STOCK DETAIL] CANCEL_STOCK_SUCCESS";
export const CANCEL_STOCK_FAILED = "[STOCK DETAIL] CANCEL_STOCK_FAILED";

export const ADD_PRODUCT = "[STOCK DETAIL] ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "[STOCK DETAIL] ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILED = "[STOCK DETAIL] ADD_PRODUCT_FAILED";

export const ADD_PRODUCT_ITEM = "[STOCK DETAIL] ADD_PRODUCT_ITEM";
export const ADD_PRODUCT_ITEM_SUCCESS =
	"[STOCK DETAIL] ADD_PRODUCT_ITEM_SUCCESS";
export const ADD_PRODUCT_ITEM_FAILED = "[STOCK DETAIL] ADD_PRODUCT_ITEM_FAILED";

export const REMOVE_PRODUCT_ITEM = "[STOCK DETAIL] REMOVE_PRODUCT_ITEM";
export const REMOVE_PRODUCT_ITEM_SUCCESS =
	"[STOCK DETAIL] REMOVE_PRODUCT_ITEM_SUCCESS";
export const REMOVE_PRODUCT_ITEM_FAILED =
	"[STOCK DETAIL] REMOVE_PRODUCT_ITEM_FAILED";

export const CHANGE_PRODUCT_ITEM = "[STOCK DETAIL] CHANGE_PRODUCT_ITEM";
export const CHANGE_PRODUCT_ITEM_SUCCESS =
	"[STOCK DETAIL] CHANGE_PRODUCT_ITEM_SUCCESS";
export const CHANGE_PRODUCT_ITEM_FAILED =
	"[STOCK DETAIL] CHANGE_PRODUCT_ITEM_FAILED";

export const EDIT_PRODUCT = "[STOCK DETAIL] EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "[STOCK DETAIL] EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "[STOCK DETAIL] EDIT_PRODUCT_FAILED";

export const EDIT_PRODUCT_ITEM = "[STOCK DETAIL] EDIT_PRODUCT_ITEM";
export const EDIT_PRODUCT_ITEM_SUCCESS =
	"[STOCK DETAIL] EDIT_PRODUCT_ITEM_SUCCESS";
export const EDIT_PRODUCT_ITEM_FAILED =
	"[STOCK DETAIL] EDIT_PRODUCT_ITEM_FAILED";

export const DELETE_PRODUCT = "[STOCK DETAIL] DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "[STOCK DETAIL] DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "[STOCK DETAIL] DELETE_PRODUCT_FAILED";

export const DONE_STOCK = "[STOCK DETAIL] DONE_STOCK";
export const DONE_STOCK_SUCCESS = "[STOCK DETAIL] DONE_STOCK_SUCCESS";
export const DONE_STOCK_FAILED = "[STOCK DETAIL] DONE_STOCK_FAILED";

export const SET_VALUE = "[STOCK DETAIL] SET_VALUE";
export const CLEAR_VALUE = "[STOCK DETAIL] CLEAR_VALUE";

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}

export function getStock(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_STOCK
		});

		const { token } = getState().login;

		let response = await api.sendGet("/stock/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_STOCK_SUCCESS,
				payload: json.model
			});

			callback && callback(null, json.model);
		} else {
			dispatch({ type: GET_STOCK_FAILED });
		}
	};
}

export function cancelStock(reason, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CANCEL_STOCK
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPut(
			"/stock/" + stockDetail.item.id + "/Cancel?reason=" + reason,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CANCEL_STOCK_SUCCESS,
				payload: json.model
			});

			history.push("/stocks");
		} else {
			dispatch({ type: CANCEL_STOCK_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function reload() {
	return (dispatch, getState) => {
		const { stockDetail } = getState();
		return dispatch(getStock(stockDetail.item.id));
	};
}

export function doneStock(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DONE_STOCK
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPut(
			"/stock/" + stockDetail.item.id + "/Finished",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DONE_STOCK_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DONE_STOCK_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function searchProduct(code, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PRODUCT
		});

		const { login } = getState();

		let response = await api.sendGet("/product/code/" + code, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback(null, json.model);
		} else {
			dispatch({ type: GET_PRODUCT_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function addProduct(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_PRODUCT
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPost(
			"/stock/" + stockDetail.item.id + "/Add",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			try {
				var json = await response.json();

				if (json.errors) {
					dispatch({ type: ADD_PRODUCT_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: ADD_PRODUCT_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: ADD_PRODUCT_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function addProductItems(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_PRODUCT_ITEM
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPost(
			"/stock/" + stockDetail.item.id + "/Items",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_PRODUCT_ITEM_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: ADD_PRODUCT_ITEM_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function addProductItemCodes({ items, product }, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_PRODUCT_ITEM
		});

		console.log(items);
		const { login, stockDetail } = getState();

		let response = await api.sendPost(
			"/stock/" +
				stockDetail.item.id +
				"/Product/" +
				product.productId +
				"/Items",
			items,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_PRODUCT_ITEM_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: ADD_PRODUCT_ITEM_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function removeItem(item, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: REMOVE_PRODUCT_ITEM
		});

		const { login, stockDetail } = getState();

		let response = await api.sendDelete(
			"/stock/" + stockDetail.item.id + "/Item/" + item.id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: REMOVE_PRODUCT_ITEM_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: REMOVE_PRODUCT_ITEM_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function changeItem(item, newItem, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_PRODUCT_ITEM
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPut(
			"/stock/" +
				stockDetail.item.id +
				"/item/" +
				item.id +
				"/change/" +
				newItem.id,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CHANGE_PRODUCT_ITEM_SUCCESS,
				payload: json.model
			});

			for (const p of json.model.products) {
				for (const i of p.items) {
					if (i.productItemId === newItem.id) {
						callback(null, i);
					}
				}
			}
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: CHANGE_PRODUCT_ITEM_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: CHANGE_PRODUCT_ITEM_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: CHANGE_PRODUCT_ITEM_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function changeStockProductItemState(item, status, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_PRODUCT_ITEM_STATUS
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPut(
			"/stock/" +
				stockDetail.item.id +
				"/item/" +
				item.id +
				"/Status/" +
				status,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CHANGE_PRODUCT_ITEM_STATUS_SUCCESS,
				payload: json.model
			});

			callback(null, json.model);
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: CHANGE_PRODUCT_ITEM_STATUS_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: CHANGE_PRODUCT_ITEM_STATUS_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: CHANGE_PRODUCT_ITEM_STATUS_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function editProduct(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPut(
			"/stock/" + stockDetail.item.id + "/Edit",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: EDIT_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: EDIT_PRODUCT_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function editItem(item, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT_ITEM
		});

		const { login, stockDetail } = getState();

		let response = await api.sendPut(
			"/stock/" + stockDetail.item.id + "/item/" + item.id,
			item,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;

			dispatch({
				type: EDIT_PRODUCT_ITEM_SUCCESS,
				payload
			});

			callback();
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: EDIT_PRODUCT_ITEM_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: EDIT_PRODUCT_ITEM_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: EDIT_PRODUCT_ITEM_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function deleteProduct(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PRODUCT
		});

		const { login, stockDetail } = getState();

		let response = await api.sendDelete(
			"/stock/" + stockDetail.item.id + "/" + id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DELETE_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DELETE_PRODUCT_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}
