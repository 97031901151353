import { sendGet, sendPut } from "services/api";
import moment from "moment";

export const GET_ORDERS = "[ORDER REPORT] GET_ORDERS";
export const GET_ORDERS_SUCCESS = "[ORDER REPORT] GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILED = "[ORDER REPORT] GET_ORDERS_FAILED";

export const GET_ORDERS_PENDING = "[ORDER REPORT] GET_ORDERS_PENDING";
export const GET_ORDERS_PENDING_SUCCESS =
	"[ORDER REPORT] GET_ORDERS_PENDING_SUCCESS";
export const GET_ORDERS_PENDING_FAILED =
	"[ORDER REPORT] GET_ORDERS_PENDING_FAILED";

export const GET_ORDERS_PERIOD = "[ORDER REPORT] GET_ORDERS_PERIOD";
export const GET_ORDERS_PERIOD_SUCCESS =
	"[ORDER REPORT] GET_ORDERS_PERIOD_SUCCESS";
export const GET_ORDERS_PERIOD_FAILED =
	"[ORDER REPORT] GET_ORDERS_PERIOD_FAILED";

export const GET_ORDERS_LOGISTICS = "[ORDER REPORT] GET_ORDERS_LOGISTICS";
export const GET_ORDERS_LOGISTICS_SUCCESS =
	"[ORDER REPORT] GET_ORDERS_LOGISTICS_SUCCESS";
export const GET_ORDERS_LOGISTICS_FAILED =
	"[ORDER REPORT] GET_ORDERS_LOGISTICS_FAILED";

export const RESOLVE_ORDER = "[ORDER REPORT] RESOLVE_ORDER";
export const RESOLVE_ORDER_SUCCESS = "[ORDER REPORT] RESOLVE_ORDER_SUCCESS";
export const RESOLVE_ORDER_FAILED = "[ORDER REPORT] RESOLVE_ORDER_FAILED";

export const SET_SEARCH_TEXT = "[ORDER REPORT] SET_SEARCH_TEXT";

export const SET_VALUE = "[ORDER REPORT] SET_VALUE";
export const CLEAR_VALUE = "[ORDER REPORT] CLEAR_VALUE";

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(payload) {
	return {
		type: CLEAR_VALUE,
		payload
	};
}

export function getOrders(term = "", callback, clientId, status) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ORDERS
		});

		const { token } = getState().login;

		if (status) {
			status = status.split(",");

			status = status.reduce((a, b) => a + `status=${b}&`, "");
		}

		if (!term) term = "";

		let response = await sendGet(
			`/order?withIncludes=false&limit=500&term=${term}&clientId=${clientId}&${status}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_ORDERS_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_ORDERS_FAILED });
			callback && callback(true);
		}
	};
}

export function getOrdersV2(
	startDate,
	endDate,
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ORDERS
		});

		const { login } = getState();

		const { token } = login;

		let response = await sendGet(
			`/Order/v2?startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
				"&filters=null"}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_ORDERS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_ORDERS_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_ORDERS_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getOrdersPending(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ORDERS_PENDING
		});

		const { token } = getState().login;

		let response = await sendGet("/order/pending", {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_ORDERS_PENDING_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_ORDERS_PENDING_FAILED });
			callback && callback(true);
		}
	};
}

export function getOrdersFromPeriod(from, to, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ORDERS_PERIOD
		});

		const { token } = getState().login;

		if (!from) from = moment().subtract(1, "month");
		if (!to) to = moment();

		if (from.format) from = from.format("YYYY-MM-DD");
		if (to.format) to = to.format();

		let response = await sendGet("/order/Period/" + from + "/" + to, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_ORDERS_PERIOD_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_ORDERS_PERIOD_FAILED });
			callback && callback(true);
		}
	};
}

export function getLogistics(until, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ORDERS_LOGISTICS
		});

		const { token } = getState().login;

		let response = await sendGet("/order/Logistics/" + until, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_ORDERS_LOGISTICS_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_ORDERS_LOGISTICS_FAILED });
			callback && callback(true);
		}
	};
}

export function resolveOrder(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: RESOLVE_ORDER
		});

		const { login } = getState();

		let response = await sendPut("/order/" + id + "/resolve", null, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			dispatch({
				type: RESOLVE_ORDER_SUCCESS,
				payload: id
			});

			callback();
		} else {
			dispatch({ type: RESOLVE_ORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}
