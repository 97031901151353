import * as api from "services/api";

export const GET_SCHEDULE = "[SCHEDULE] GET_SCHEDULE";
export const GET_SCHEDULE_FAILED = "[SCHEDULE] GET_SCHEDULE_FAILED";
export const GET_SCHEDULE_SUCCESS = "[SCHEDULE] GET_SCHEDULE_SUCCESS";

export const EDIT_SCHEDULE = "[SCHEDULE EDIT] EDIT_SCHEDULE";
export const EDIT_SCHEDULE_SUCCESS = "[SCHEDULE EDIT] EDIT_SCHEDULE_SUCCESS";
export const EDIT_SCHEDULE_FAILED = "[SCHEDULE EDIT] EDIT_SCHEDULE_FAILED";

export const SET_VALUE = "[SCHEDULE] SET_VALUE";
export const CLEAR_VALUE = "[SCHEDULE] CLEAR_VALUE";

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(payload) {
	return {
		type: CLEAR_VALUE,
		payload
	};
}

export function getSchedule(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_SCHEDULE
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(`/schedule`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SCHEDULE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_SCHEDULE_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_SCHEDULE_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function editSchedule(item, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_SCHEDULE
		});

		const { login } = getState();

		let response = await api.sendPut(
			"/schedule/" + item.id,
			{
				...item
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			dispatch({
				type: EDIT_SCHEDULE_SUCCESS
			});

			callback && callback(null);
		} else {
			const result = await response.json();

			dispatch({ type: EDIT_SCHEDULE_FAILED });
			callback && callback(result.errors);
		}
	};
}
