import * as api from "services/api";

import _ from "lodash";
import { isValidNumber } from "libphonenumber-js";

import history from "../../../../history";
import moment from "moment";

export const EDIT_ORDER = "[ORDER EDIT] EDIT_ORDER";
export const EDIT_ORDER_SUCCESS = "[ORDER EDIT] EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAILED = "[ORDER EDIT] EDIT_ORDER_FAILED";

export const GET_ORDER = "[ORDER EDIT] GET_ORDER";
export const GET_ORDER_SUCCESS = "[ORDER EDIT] GET_ORDER_SUCCESS";
export const GET_ORDER_FAILED = "[ORDER EDIT] GET_ORDER_FAILED";

export const SET_VALUE = "[ORDER EDIT] SET_VALUE";
export const CLEAR_VALUE = "[ORDER EDIT] CLEAR_VALUE";

export function getOrder(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ORDER
    });

    const { token } = getState().login;

    let response = await api.sendGet("/order/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let order = json.model;
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: order
      });
    } else {
      dispatch({ type: GET_ORDER_FAILED });
    }
  };
}

export function editOrder() {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_ORDER
    });

    const { login, orderEdit } = getState();

    let {
      item,
      clientId,
      userId,
      driverId,
      phoneNumber,
      people,
      mount,
      discount,
      startDate,
      endDate,
      checkIn,
      checkOut,
      referral,
      deliveryType,
      deliveryAddress,
      city,
      deliveryCityId,
      moreInfo,
      discountValue,
      manualDeliveryFee,
      deliveryFee
    } = orderEdit;

    const errors = {};

    if (phoneNumber && !isValidNumber(phoneNumber)) {
      errors.phoneNumber = "Telefone inválido";
    }

    if (people < 1) {
      errors.people = "Campo inválido";
    }

    if (deliveryType === 1 && !deliveryAddress) {
      errors.deliveryAddress = "Campo obrigatório";
    }

    if (deliveryType === 1 && !deliveryCityId) {
      errors.city = "Campo obrigatório";
    }

    if (discountValue < 0) {
      errors.discountValue = "Campo inválido";
    }

    if (manualDeliveryFee && deliveryFee < 0) {
      errors.deliveryFee = "Campo inválido";
    }

    if (moment.parseZone(startDate).isAfter(moment.parseZone(endDate))) {
      errors.startDate = "Data inválida";
      errors.endDate = "Data inválida";
    }

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0) return dispatch({ type: EDIT_ORDER_FAILED });

    let response = await api.sendPut(
      "/order/" + item.id,
      {
        ...item,
        phoneNumber,
        people,
        clientId,
        userId,
        driverId,
        mount,
        discount,
        startDate,
        endDate,
        checkIn,
        checkOut,
        referral,
        deliveryType,
        deliveryAddress,
        city,
        deliveryCityId,
        moreInfo,
        discountValue,
        manualDeliveryFee,
        deliveryFee
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      const result = await response.json();

      dispatch({
        type: EDIT_ORDER_SUCCESS
      });

      dispatch(clearValue());

      history.push("/orders/" + result.model.id);
    } else {
      dispatch({ type: EDIT_ORDER_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue(force) {
  return {
    type: CLEAR_VALUE,
    payload: force
  };
}
