import * as actions from "../../../actions/chartOfAccount/create";

const initialChartOfAccountState = {
  name: "",
  code: "",
  chartOfAccountsType: 0,
  description: "",
  errors: {}
};

const initialState = {
  loading: false,
  ...initialChartOfAccountState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialChartOfAccountState
      };
    }
    case actions.ADD_CHART_OF_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_CHART_OF_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_CHART_OF_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
