import { lazy } from "react";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Create from "@material-ui/icons/Create";
import Apps from "@material-ui/icons/Apps";
import CalendarToday from "@material-ui/icons/CalendarToday";

import ls from "Localization";

const Dashboard = lazy(() => import("views/Dashboard/Dashboard.jsx"));
const Schedule = lazy(() => import("views/Schedule"));
const OrderRegistration = lazy(() => import("views/Registration/Order"));

const LotRegistration = lazy(() => import("views/Registration/Lot"));
const LotReport = lazy(() => import("views/Reports/Lot"));

const LaundryOrderRegistration = lazy(() =>
	import("views/Registration/Laundry")
);
const ProductRegistration = lazy(() => import("views/Registration/Product"));
const ProductCategoryRegistration = lazy(() =>
	import("views/Registration/ProductCategory")
);
const ProductCategoryEdit = lazy(() => import("views/Edit/ProductCategory"));
const BudgetRegistration = lazy(() => import("views/Registration/Budget"));
const ClientRegistration = lazy(() => import("views/Registration/Client"));
const UserRegistration = lazy(() => import("views/Registration/User"));
const ProductReports = lazy(() => import("views/Reports/Product"));
const ProductItemReports = lazy(() => import("views/Reports/ProductItem"));
const ProductByStatusReport = lazy(() =>
	import("views/Reports/ProductByStatus")
);
const ProductDailyRentalsReport = lazy(() =>
	import("views/Reports/ProductDailyRentals")
);
const ProductCategoryReports = lazy(() =>
	import("views/Reports/ProductCategory")
);
const PaymentMethodReport = lazy(() => import("views/Reports/PaymentMethod"));
const FinancialAccountReport = lazy(() =>
	import("views/Reports/FinancialAccount")
);
const ClientReports = lazy(() => import("views/Reports/Client"));
const OrderReports = lazy(() => import("views/Reports/Order"));
const LaundryOrderReports = lazy(() => import("views/Reports/Laundry"));
const BudgetReports = lazy(() => import("views/Reports/Budget"));
const InventoryReport = lazy(() => import("views/Reports/Inventory"));
const PendingOrdersReport = lazy(() => import("views/Reports/PendingOrders"));
const IndicationReport = lazy(() => import("views/Reports/Indication"));
const IndicationByBusinessReport = lazy(() =>
	import("views/Reports/IndicationByBusiness")
);
const ItemsAvailability = lazy(() => import("views/Reports/ItemsAvailability"));
const ItemsAvailability2 = lazy(() =>
	import("views/Reports/ItemsAvailability2")
);
// const RequiredItems = lazy(() => import("views/Reports/RequiredItems"));

const LotDetail = lazy(() => import("views/Detail/Lot"));
const OrderDetail = lazy(() => import("views/Detail/Order"));
const LaundryOrderDetail = lazy(() => import("views/Detail/Laundry"));
const BudgetDetail = lazy(() => import("views/Detail/Budget"));
const BudgetEdit = lazy(() => import("views/Edit/Budget"));
const ProductDetail = lazy(() => import("views/Detail/Product"));
const ClientDetail = lazy(() => import("views/Detail/Client"));
const ClientEdit = lazy(() => import("views/Edit/Client"));
const OrderEdit = lazy(() => import("views/Edit/Order"));
const ProductEdit = lazy(() => import("views/Edit/Product"));
// const CashFlow = lazy(() => import("views/Financial/CashFlow"));
// const DailyRevenue = lazy(() => import("views/Financial/DailyRevenue"));
// const MonthRevenue = lazy(() => import("views/Financial/MonthRevenue"));
// const Bill = lazy(() => import("views/Financial/Bill"));
// const AccountReceivable = lazy(() =>
//   import("views/Financial/AccountReceivable")
// );
const UserDetail = lazy(() => import("views/Detail/User"));
const CashierReport = lazy(() => import("views/Reports/Cashier"));
const CashierDetail = lazy(() => import("views/Detail/Cashier"));
const SaleDetail = lazy(() => import("views/Detail/Sale"));
const Sale = lazy(() => import("views/Sale"));
const SaleCreateOrEdit = lazy(() => import("views/Sale/CreateOrEdit"));
const PaymentMethodRegistration = lazy(() =>
	import("views/Registration/PaymentMethod")
);
const FinancialAccountRegistration = lazy(() =>
	import("views/Registration/FinancialAccount")
);
const PaymentMethodEdit = lazy(() => import("views/Edit/PaymentMethod"));
const FinancialAccountEdit = lazy(() => import("views/Edit/FinancialAccount"));
const SubChartOfAccountRegistration = lazy(() =>
	import("views/Registration/ChartOfAccount/Sub")
);
const SubChartOfAccountEdit = lazy(() =>
	import("views/Edit/ChartOfAccount/Sub")
);
const ChartOfAccountEdit = lazy(() => import("views/Edit/ChartOfAccount"));
const LaundryOrderEdit = lazy(() => import("views/Edit/LaundryOrder"));
const UserReports = lazy(() => import("views/Reports/User"));
const PendingLaundry = lazy(() => import("views/Reports/PendingLaundry"));
const RentalFrequenceReport = lazy(() =>
	import("views/Reports/RentalFrequence")
);
const SaleReports = lazy(() => import("views/Sale/Report"));
const StateReports = lazy(() => import("views/Reports/State"));
const SaleByClientReports = lazy(() => import("views/Reports/SalesByClient"));
const SalesByProductReports = lazy(() =>
	import("views/Reports/SalesByProduct")
);
const PriceSimulatorReport = lazy(() => import("views/Reports/PriceSimulator"));

const ProductsByOrderReports = lazy(() => import("views/Reports/ItemsByOrder"));

const PendingSales = lazy(() => import("views/Reports/PendingSales"));

const PendingSalesCategory = lazy(() =>
	import("views/Reports/PendingSalesCategory")
);

const ClientStockReports = lazy(() => import("views/Reports/ClientStock"));
const ClientStockRegistration = lazy(() =>
	import("views/Registration/ClientStock")
);
const ClientStockDetail = lazy(() => import("views/Detail/ClientStock"));
const ClientStockEdit = lazy(() => import("views/Edit/ClientStock"));

const BillingReport = lazy(() => import("views/Reports/Billing"));

var dashRoutes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: DashboardIcon,
		roles: ["Dashboard", "Attendant"],
		component: Dashboard
	},
	{
		path: "/Sale/PointOfSale",
		exact: true,
		name: ls.pointOfSale,
		icon: DashboardIcon,
		roles: ["Admin", "Attendant"],
		component: Sale
	},
	{
		path: "/orders/create",
		exact: true,
		name: ls.newRental,
		mini: ls.newOrderMini,
		icon: DashboardIcon,
		roles: ["Admin", "Attendant"],
		component: OrderRegistration
	},
	{
		path: "/orders",
		exact: true,
		name: ls.orders,
		mini: ls.ordersMini,
		icon: DashboardIcon,
		roles: ["Dashboard", "Attendant"],
		component: OrderReports
	},
	{
		path: "/stocks",
		exact: true,
		name: ls.clientStock,
		mini: ls.clientStockMini,
		icon: DashboardIcon,
		roles: ["Admin"],
		component: ClientStockReports
	},
	{
		path: "/stocks/create",
		exact: true,
		name: ls.newStock,
		mini: ls.clientStockMini,
		icon: DashboardIcon,
		roles: ["Admin"],
		hidden: true,
		component: ClientStockRegistration
	},
	{
		path: "/stocks/:id/edit",
		exact: true,
		roles: ["Admin"],
		component: ClientStockEdit,
		hidden: true,
		name: ls.clientStockEdit
	},
	{
		path: "/stocks/:id",
		exact: true,
		roles: ["Dashboard"],
		component: ClientStockDetail,
		hidden: true,
		name: ls.clientStock
	},
	{
		path: "/budget",
		exact: true,
		name: ls.budgets,
		mini: ls.budgetsMini,
		icon: DashboardIcon,
		roles: ["Dashboard", "Attendant"],
		component: BudgetReports
	},
	{
		path: "/LaundryOrders",
		exact: true,
		name: ls.laundry,
		mini: ls.laundryMini,
		icon: DashboardIcon,
		roles: ["Dashboard", "Attendant"],
		component: LaundryOrderReports
	},
	{
		path: "/Schedule",
		exact: true,
		name: ls.schedule,
		mini: ls.scheduleMini,
		icon: CalendarToday,
		roles: ["Dashboard", "Attendant"],
		component: Schedule
	},
	{
		collapse: true,
		path: "/registration",
		name: ls.registration,
		state: "openRegistration",
		roles: ["Admin", "Attendant"],
		icon: Create,
		views: [
			{
				path: "/products/categories/create",
				exact: true,
				name: ls.productCategory,
				mini: ls.productCategoryMini,
				roles: ["Admin"],
				component: ProductCategoryRegistration
			},
			{
				path: "/clients/create",
				exact: true,
				name: ls.client,
				mini: ls.clientMini,
				roles: ["Admin", "Attendant"],
				component: ClientRegistration
			},
			{
				path: "/financialAccount/create",
				exact: true,
				name: ls.financialAccount,
				mini: ls.financialAccountMini,
				roles: ["Admin"],
				component: FinancialAccountRegistration
			},
			{
				path: "/lots/create",
				exact: true,
				name: ls.newLot,
				mini: ls.newLotMini,
				icon: DashboardIcon,
				roles: ["Admin", "Attendant"],
				component: LotRegistration
			},
			{
				path: "/paymentMethod/create",
				exact: true,
				name: ls.paymentMethod,
				mini: ls.paymentMethodMini,
				roles: ["Admin"],
				component: PaymentMethodRegistration
			},
			{
				path: "/budgets/create",
				exact: true,
				name: ls.budget,
				mini: ls.budgetMini,
				roles: ["Admin", "Attendant"],
				component: BudgetRegistration
			},
			// {
			//   path: "/ChartOfAccounts/create",
			//   exact: true,
			//   name: ls.chartOfAccounts,
			//   mini: ls.chartOfAccountsMini,
			//   roles: ["Dashboard"],component: ChartOfAccountRegistration
			// },
			{
				path: "/products/create",
				exact: true,
				name: ls.product,
				mini: ls.productMini,
				roles: ["Admin"],
				component: ProductRegistration
			},
			{
				path: "/users/create",
				exact: true,
				name: ls.user,
				mini: ls.userMini,
				roles: ["Admin"],
				component: UserRegistration
			}
		]
	},
	{
		collapse: true,
		path: "/reports",
		name: ls.reports,
		state: "openReports",
		icon: Apps,
		views: [
			{
				path: "/products/daily/rentails",
				exact: true,
				name: ls.productDailyRentals,
				mini: ls.productsMini,
				roles: ["Admin"],
				component: ProductDailyRentalsReport
			},
			{
				path: "/billing",
				exact: true,
				name: ls.billing,
				mini: ls.billingMini,
				roles: ["Admin"],
				component: BillingReport
			},
			{
				path: "/cashiers",
				exact: true,
				name: ls.cashiers,
				mini: ls.cashiersMini,
				roles: ["Admin"],
				component: CashierReport
			},
			{
				path: "/products/categories",
				exact: true,
				name: ls.productCategory,
				mini: ls.productCategoryMini,
				roles: ["Admin"],
				component: ProductCategoryReports
			},
			{
				path: "/clients",
				exact: true,
				name: ls.clients,
				mini: ls.clientsMini,
				roles: ["Dashboard"],
				component: ClientReports
			},
			{
				path: "/financialAccount",
				exact: true,
				name: ls.financialAccount,
				mini: ls.financialAccountMini,
				roles: ["Admin"],
				component: FinancialAccountReport
			},
			{
				path: "/Availability",
				exact: true,
				name: ls.availability,
				mini: ls.availabilityMini,
				roles: ["Dashboard"],
				component: ItemsAvailability
			},
			{
				path: "/Availability2",
				exact: true,
				name: ls.stock,
				mini: ls.stockMini,
				roles: ["Dashboard"],
				component: ItemsAvailability2
			},
			// {
			// 	path: "/Order/Product/Required",
			// 	exact: true,
			// 	name: ls.requiredItems,
			// 	mini: ls.requiredItemsMini,
			// 	roles: ["Dashboard"],
			// 	component: RequiredItems
			// },
			{
				path: "/rentalFrequence",
				exact: true,
				name: ls.rentalFrequence,
				mini: ls.rentalFrequenceMini,
				roles: ["Admin"],
				component: RentalFrequenceReport
			},
			{
				path: "/indications",
				exact: true,
				name: ls.indications,
				mini: ls.indicationsMini,
				roles: ["Admin"],
				component: IndicationReport
			},
			{
				path: "/indications/business",
				exact: true,
				name: ls.indicationsByBusiness,
				mini: ls.indicationsMini,
				roles: ["Admin"],
				component: IndicationByBusinessReport
			},
			// {
			//   path: "/Order/Product/Required",
			//   exact: true,
			//   name: ls.requiredItems,
			//   mini: ls.requiredItemsMini,
			//   roles: ["Dashboard"],
			//   component: RequiredItems,
			// },

			{
				path: "/lots",
				exact: true,
				name: ls.lots,
				mini: ls.lotsMini,
				icon: DashboardIcon,
				roles: ["Admin", "Attendant"],
				component: LotReport
			},
			{
				path: "/paymentMethod",
				exact: true,
				name: ls.paymentMethod,
				mini: ls.paymentMethodMini,
				roles: ["Admin"],
				component: PaymentMethodReport
			},
			// {
			//   path: "/chartOfAccounts",
			//   exact: true,
			//   name: ls.chartOfAccounts,
			//   mini: ls.chartOfAccountsMini,
			//   roles: ["Dashboard"],component: ChartOfAccountsReport
			// },
			{
				path: "/order/pending",
				exact: true,
				name: ls.pendingOrders,
				mini: ls.pendingOrdersMini,
				roles: ["Admin"],
				component: PendingOrdersReport
			},
			{
				path: "/LaundryOrders/Pending",
				exact: true,
				name: ls.pendingLaundry,
				mini: ls.pendingLaundryMini,
				roles: ["Admin"],
				component: PendingLaundry
			},
			{
				path: "/products",
				exact: true,
				name: ls.products,
				mini: ls.productsMini,
				roles: ["Dashboard"],
				component: ProductReports
			},
			{
				path: "/products/items",
				exact: true,
				name: "Itens de produto",
				mini: "I",
				roles: ["Dashboard"],
				component: ProductItemReports
			},
			{
				path: "/products/status",
				exact: true,
				name: ls.productsByStatus,
				mini: ls.productsMini,
				roles: ["Admin"],
				component: ProductByStatusReport
			},
			{
				path: "/products/order",
				exact: true,
				name: ls.productsByOrder,
				mini: ls.productMini,
				roles: ["Admin"],
				component: ProductsByOrderReports
			},
			{
				path: "/price/simulator",
				exact: true,
				name: ls.priceSimulator,
				mini: ls.priceSimulatorMini,
				roles: ["Admin"],
				component: PriceSimulatorReport
			},
			{
				path: "/state",
				exact: true,
				name: ls.stateReport,
				mini: ls.stateReportMini,
				roles: ["Admin"],
				component: StateReports
			},
			{
				path: "/users",
				exact: true,
				name: ls.users,
				mini: ls.usersMini,
				roles: ["Admin"],
				component: UserReports
			},
			{
				path: "/sale",
				exact: true,
				name: ls.sales,
				mini: ls.salesMini,
				roles: ["Admin", "Attendant"],
				component: SaleReports
			},
			{
				path: "/sale/pending",
				exact: true,
				name: "Vendas pendentes",
				mini: "VP",
				roles: ["Admin"],
				component: PendingSales
			},
			{
				path: "/sale/pending/category",
				exact: true,
				name: "Vendas pendentes p/ cat.",
				mini: "VP",
				roles: ["Admin"],
				component: PendingSalesCategory
			},
			{
				path: "/sale/client",
				exact: true,
				name: ls.salesByClient,
				mini: ls.salesMini,
				roles: ["Admin"],
				component: SaleByClientReports
			},
			{
				path: "/sale/product",
				exact: true,
				name: ls.salesByProduct,
				mini: ls.salesMini,
				roles: ["Admin"],
				component: SalesByProductReports
			}
		]
	},
	// {
	//   collapse: true,
	//   path: "/financial",
	//   name: ls.financial,
	//   state: "openFinancial",
	//   roles: ["Admin"],
	//   icon: Apps,
	//   views: [
	//     // {
	//     // 	path: "/financial/bill",
	//     // 	exact: true,
	//     // 	name: ls.bills,
	//     // 	mini: ls.billsMini,
	//     // 	roles: ["Admin"],
	//     // 	component: Bill
	//     // },
	//     // {
	//     // 	path: "/financial/AccountReceivable",
	//     // 	exact: true,
	//     // 	name: ls.accountReceivables,
	//     // 	mini: ls.accountReceivablesMini,
	//     // 	roles: ["Admin"],
	//     // 	component: AccountReceivable
	//     // },
	//     // {
	//     // 	path: "/financial/cashflow",
	//     // 	exact: true,
	//     // 	name: ls.cashFlow,
	//     // 	mini: ls.cashFlowMini,
	//     // 	roles: ["Admin"],
	//     // 	component: CashFlow
	//     // },
	//     {
	//       path: "/financial/daily",
	//       exact: true,
	//       name: ls.dailyRevenue,
	//       mini: ls.dailyRevenueMini,
	//       roles: ["Admin"],
	//       component: DailyRevenue,
	//     },
	//     {
	//       path: "/financial/month",
	//       exact: true,
	//       name: ls.monthRevenue,
	//       mini: ls.monthRevenueMini,
	//       roles: ["Admin"],
	//       component: MonthRevenue,
	//     },
	//   ],
	// },
	{
		path: "/inventory",
		exact: true,
		name: ls.inventory,
		mini: ls.inventoryMini,
		icon: DashboardIcon,
		roles: ["Admin"],
		component: InventoryReport
	},
	{
		path: "/orders/:id/edit",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: OrderEdit,
		hidden: true,
		name: ls.orderEdit
	},
	{
		path: "/clients/:id/orders/create",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: OrderRegistration,
		hidden: true,
		name: ls.newRental
	},
	{
		path: "/LaundryOrders/create",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: LaundryOrderRegistration,
		hidden: true,
		name: ls.addLaundryOrder
	},
	{
		path: "/orders/:id",
		exact: true,
		roles: ["Dashboard"],
		component: OrderDetail,
		hidden: true,
		name: ls.orderDetail
	},
	{
		path: "/lots/:id",
		exact: true,
		roles: ["Dashboard"],
		component: LotDetail,
		hidden: true,
		name: ls.lotDetail
	},

	{
		path: "/LaundryOrders/:id",
		exact: true,
		roles: ["Dashboard"],
		component: LaundryOrderDetail,
		hidden: true,
		name: ls.laundryOrderDetail
	},
	{
		path: "/LaundryOrders/:id/edit",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: LaundryOrderEdit,
		hidden: true,
		name: ls.laundryOrderEdit
	},
	{
		path: "/budgets/:id",
		exact: true,
		roles: ["Dashboard"],
		component: BudgetDetail,
		hidden: true,
		name: ls.budgetDetail
	},
	{
		path: "/budgets/:id/edit",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: BudgetEdit,
		hidden: true,
		name: ls.budgetEdit
	},
	{
		path: "/clients/:id",
		exact: true,
		roles: ["Dashboard"],
		component: ClientDetail,
		hidden: true,
		name: ls.clientDetail
	},
	{
		path: "/users/:id",
		exact: true,
		roles: ["Admin"],
		component: UserDetail,
		hidden: true,
		name: ls.userDetail
	},
	{
		path: "/products/:id",
		exact: true,
		roles: ["Dashboard"],
		component: ProductDetail,
		hidden: true,
		name: ls.productDetail
	},
	{
		path: "/cashier/:id",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: CashierDetail,
		hidden: true,
		name: ls.cashierDetail
	},
	{
		path: "/sale/:id/detail",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: SaleDetail,
		hidden: true,
		name: ls.saleDetail
	},
	{
		path: "/products/:id/edit",
		exact: true,
		roles: ["Admin"],
		component: ProductEdit,
		hidden: true,
		name: ls.productEdit
	},
	{
		path: "/products/categories/:id/edit",
		exact: true,
		roles: ["Admin"],
		component: ProductCategoryEdit,
		hidden: true,
		name: ls.productCategoryEdit
	},
	{
		path: "/clients/:id/edit",
		exact: true,
		roles: ["Admin", "Attendant"],
		component: ClientEdit,
		hidden: true,
		name: ls.clientEdit
	},
	{
		path: "/PaymentMethod/:id/edit",
		exact: true,
		roles: ["Admin"],
		component: PaymentMethodEdit,
		hidden: true,
		name: ls.paymentMethod
	},
	{
		path: "/FinancialAccount/:id/edit",
		exact: true,
		roles: ["Admin"],
		component: FinancialAccountEdit,
		hidden: true,
		name: ls.financialAccount
	},
	{
		path: "/ChartOfAccounts/:id/edit",
		exact: true,
		roles: ["Admin"],
		component: ChartOfAccountEdit,
		hidden: true,
		name: ls.chartOfAccounts
	},
	{
		path: "/ChartOfAccounts/:id/Sub/Create",
		exact: true,
		roles: ["Admin"],
		component: SubChartOfAccountRegistration,
		hidden: true,
		name: ls.subChartOfAccounts
	},
	{
		path: "/ChartOfAccounts/:id/Sub/:subId/Edit",
		exact: true,
		roles: ["Admin"],
		component: SubChartOfAccountEdit,
		hidden: true,
		name: ls.subChartOfAccounts
	},
	{
		exact: true,
		path: "/Sale/Create",
		name: ls.newSale,
		icon: DashboardIcon,
		roles: ["Admin", "Attendant"],
		component: SaleCreateOrEdit,
		hidden: true
	},
	{
		exact: true,
		path: "/Sale/Edit/:id",
		name: ls.editSale,
		icon: DashboardIcon,
		roles: ["Admin", "Attendant"],
		component: SaleCreateOrEdit,
		hidden: true
	},
	{ redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;
