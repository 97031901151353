import { sendGet } from "services/api";

export const GET_LAUNDRYORDERS = "[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS";
export const GET_LAUNDRYORDERS_SUCCESS =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_SUCCESS";
export const GET_LAUNDRYORDERS_FAILED =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_FAILED";
export const GET_LAUNDRYORDERS_PENDING =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_PENDING";
export const GET_LAUNDRYORDERS_PENDING_SUCCESS =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_PENDING_SUCCESS";
export const GET_LAUNDRYORDERS_PENDING_FAILED =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_PENDING_FAILED";
export const GET_LAUNDRYORDERS_PERIOD =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_PERIOD";
export const GET_LAUNDRYORDERS_PERIOD_SUCCESS =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_PERIOD_SUCCESS";
export const GET_LAUNDRYORDERS_PERIOD_FAILED =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_PERIOD_FAILED";
export const GET_LAUNDRYORDERS_LOGISTICS =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_LOGISTICS";
export const GET_LAUNDRYORDERS_LOGISTICS_SUCCESS =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_LOGISTICS_SUCCESS";
export const GET_LAUNDRYORDERS_LOGISTICS_FAILED =
	"[LAUNDRYORDER REPORT] GET_LAUNDRYORDERS_LOGISTICS_FAILED";
export const SET_SEARCH_TEXT = "[LAUNDRYORDER REPORT] SET_SEARCH_TEXT";

export function getLaundryOrders(term = "", callback, clientId, status) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAUNDRYORDERS
		});

		const { token } = getState().login;

		if (status) {
			status = status.split(",");

			status = status.reduce((a, b) => a + `status=${b}&`, "");
		}

		if (!term) term = "";

		let response = await sendGet(
			`/LaundryOrder?withIncludes=false&limit=500&term=${term}&clientId=${clientId}&${status}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_LAUNDRYORDERS_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_LAUNDRYORDERS_FAILED });
			callback && callback(true);
		}
	};
}

export function getOrdersPending(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAUNDRYORDERS_PENDING
		});

		const { token } = getState().login;

		let response = await sendGet("/LaundryOrder/pending", {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_LAUNDRYORDERS_PENDING_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_LAUNDRYORDERS_PENDING_FAILED });
			callback && callback(true);
		}
	};
}

export function getOrdersFromPeriod(from, to, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAUNDRYORDERS_PERIOD
		});

		const { token } = getState().login;

		let response = await sendGet("/LaundryOrder/Period/" + from + "/" + to, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_LAUNDRYORDERS_PERIOD_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_LAUNDRYORDERS_PERIOD_FAILED });
			callback && callback(true);
		}
	};
}

export function getLogistics(until, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAUNDRYORDERS_LOGISTICS
		});

		const { token } = getState().login;

		let response = await sendGet("/LaundryOrder/Logistics/" + until, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_LAUNDRYORDERS_LOGISTICS_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_LAUNDRYORDERS_LOGISTICS_FAILED });
			callback && callback(true);
		}
	};
}
