import _ from "lodash";

import * as api from "services/api";
import ls from "Localization";
import history from "../../../../history";

export const EDIT_PRODUCTCATEGORY =
  "[PRODUCTCATEGORY EDIT] EDIT_PRODUCTCATEGORY";
export const EDIT_PRODUCTCATEGORY_SUCCESS =
  "[PRODUCTCATEGORY EDIT] EDIT_PRODUCTCATEGORY_SUCCESS";
export const EDIT_PRODUCTCATEGORY_FAILED =
  "[PRODUCTCATEGORY EDIT] EDIT_PRODUCTCATEGORY_FAILED";

export const GET_PRODUCTCATEGORY = "[PRODUCTCATEGORY EDIT] GET_PRODUCTCATEGORY";
export const GET_PRODUCTCATEGORY_SUCCESS =
  "[PRODUCTCATEGORY EDIT] GET_PRODUCTCATEGORY_SUCCESS";
export const GET_PRODUCTCATEGORY_FAILED =
  "[PRODUCTCATEGORY EDIT] GET_PRODUCTCATEGORY_FAILED";

export const SET_VALUE = "[PRODUCTCATEGORY EDIT] SET_VALUE";
export const CLEAR_VALUE = "[PRODUCTCATEGORY EDIT] CLEAR_VALUE";

export function getProductCategory(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTCATEGORY
    });

    const { token } = getState().login;

    let response = await api.sendGet("/product/category/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let item = json.model;
      dispatch({
        type: GET_PRODUCTCATEGORY_SUCCESS,
        payload: item
      });
    } else {
      dispatch({ type: GET_PRODUCTCATEGORY_FAILED });
    }
  };
}

export function editProductCategory() {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_PRODUCTCATEGORY
    });

    const { login, productCategoryEdit } = getState();

    let { name, description, item } = productCategoryEdit;

    const errors = {};

    if (!name) errors.name = ls.required;

    dispatch(setValue({ errors }));

    if (_.keys(errors).length > 0)
      return dispatch({ type: EDIT_PRODUCTCATEGORY_FAILED });

    item.name = name;
    item.description = description;

    let response = await api.sendPut("/product/category/" + item.id, item, {
      Authorization: "Bearer " + login.token
    });

    if (response.ok) {
      dispatch({
        type: EDIT_PRODUCTCATEGORY_SUCCESS
      });

      dispatch(clearValue());

      history.push("/products/categories");
    } else {
      dispatch({ type: EDIT_PRODUCTCATEGORY_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
