const strings = {
	brand: "emKasa",
	rows: "Linhas",
	page: "Página",
	of: "de",
	nextA: "Próxima",
	previous: "Anterior",
	corporation: "Pessoa jurídica",
	noDataFoundMessage: "Nenhum registro encontrado",
	last24hours: "Últimas 24 horas",
	last7days: "Últimos 7 dias",
	sales: "Vendas",
	salesMini: "V",
	pendingSales: "Vendas pendentes",
	money: "Dinheiro",
	value: "Valor",
	creditCard: "Cartão de crédito",
	debitCard: "Cartão de débito",
	logout: "Sair",
	edit: "Editar",
	websiteUrl: "http://www.emkasa.com.br",
	websiteText: "emKasa",
	copyrightMessage: "Todos os direitos reservados",
	developedBy:
		"Desenvolvido por <a id='loop-link' target='_blank' href='https://www.loop3.com.br/?utm_source=EmkasaSystem&utm_medium=footer'>LOOP3</a>",
	login: "Entrar",
	loginShort: "Entrar",
	loginMini: "ET",
	inventory: "Inventário",
	inventoryMini: "IV",
	never: "Nunca",
	continue: "Continuar",
	email: "E-mail",
	sms: "SMS",
	whatsApp: "WhatsApp",
	emailRequired: "E-mail obrigatório",
	password: "Senha",
	passwordRequired: "Senha obrigatória",
	rents: "Aluguéis",
	revenue: "Receita",
	revenues: "Receitas",
	expense: "Despesa",
	expenses: "Despesas",
	registration: "Cadastros",
	products: "Produtos",
	createNewProduct: "Criar novo produto",
	newProduct: "Novo produto",
	productsMini: "P",
	productMini: "P",
	name: "Nome",
	addPhoto: "Adicionar foto",
	selectImage: "Selecionar imagem",
	change: "Trocar",
	remove: "Remover",
	submit: "Enviar",
	code: "Código",
	description: "Descrição",
	buyPrice: "Preço de compra",
	rentalPrice: "Preço de aluguel",
	status: "Status",
	active: "Ativo",
	activeForRental: "Ativo - aluguel",
	disabled: "Desativado",
	reports: "Relatórios",
	product: "Produto",
	productCategory: "Categoria de produto",
	newProductCategory: "Nova categoria de produto",
	productCategoryMini: "CP",
	clients: "Clientes",
	client: "Cliente",
	clientMini: "C",
	clientsMini: "C",
	document: "Documento",
	orders: "Pedidos",
	order: "Pedido",
	addOrder: "Adicionar pedido",
	ordersMini: "P",
	newOrder: "Novo pedido",
	newOrderMini: "NP",
	newLotMini: "LT",
	newClient: "Novo cliente",
	lots: "Lotes",
	lotsMini: "LT",
	type: "Tipo",
	street: "Rua",
	number: "Número",
	complement: "Complemento",
	neighborhood: "Bairro",
	city: "Cidade",
	state: "Estado",
	country: "País",
	zipCode: "CEP",
	addLot: "Adicionar lote",
	orderReceived: "Pedido recebido",
	orderReserved: "Pedido reservado",
	orderEdited: "Pedido editado",
	orderConfirmed: "Pedido confirmado",
	orderCancelled: "Pedido cancelado",
	orderDelivery: "Pedido entregue",
	orderDone: "Pedido finalizado",
	maxLocation: "Máx. locações",
	weight: "Peso",
	cashFlow: "Fluxo de caixa",
	cashFlowMini: "FC",
	financial: "Financeiro",
	dateFormat: "DD/MM/YYYY - HH:mm:ss",
	timeFormat: "HH:mm",
	dateFormatWithoutSeconds: "DD/MM/YYYY - HH:mm",
	dateFormatShort: "DD/MM/YYYY",
	dateFormatMonth: "MM/YYYY",
	received: "Recebido",
	confirmed: "Confirmado",
	delivered: "Entregue",
	resting: "Em repouso",
	canceled: "Cancelado",
	finished: "Finalizado",
	loading: "Carregando",
	budgetEdit: "Editar orçamento",
	inLot: "Em lote",
	selectOneOption: "Selecione uma opção",
	noClientRegistered: "Nenhum cliente cadastrado",
	noProductRegistered: "Nenhum produto cadastrado",
	noDriverRegistered: "Nenhum motorista cadastrado",
	withdrawal: "Retirada",
	withdrawals: "Retiradas",
	delivery: "Entrega",
	orderDetail: "Detalhes do pedido",
	lotInfos: "Informações do lote",
	lotDetail: "Detalhes do lote",
	budgetDetail: "Detalhes do orçamento",
	dateTime: "Data e Hora",
	totalValue: " Valor Total",
	deliveryType: "Tipo entrega",
	deliveryAddress: "Endereço de entrega",
	startDate: "Data de entrega",
	collectionDate: "Data de coleta",
	endDate: "Data de devolução",
	returnDate: "Data de devolução",
	phoneNumber: "Telefone",
	people: "Hóspedes",
	orderInfos: "Informações do pedido",
	budgetInfos: "Informações do orçamento",
	productInfos: "Informações do produto",
	productDetail: "Detalhes do produto",
	paid: "Pago",
	sellPrice: "Preço de venda",
	stockPrice: "Preço do Estoque",
	productItems: "Itens do produto",
	notAvailable: "Não disponível",
	available: "Disponível",
	inOrder: "Em pedido",
	rented: "Alugado",
	returned: "Retornado",
	cleaning: "Limpeza",
	damaged: "Danificado",
	done: "Concluído",
	replaced: "Substituído",
	budgetMini: "O",
	close: "Fechar",
	save: "Salvar",
	locationCounter: "Nº locações",
	"There is a product item with the same code":
		"Este produto já tem um item com este código",
	required: "Obrigatório",
	areYouSure: "Você tem certeza?",
	yesDeleteIt: "Sim, exclua este item!",
	yesDeleteProduct: "Sim, exclua este produto!",
	yesDeleteFinancialAccount: "Sim, exclua esta conta financeira!",
	cancel: "Cancelar",
	"You will not be able to recover this item!":
		"Você não poderá recuperar este item!",
	"You will not be able to recover this financial account!":
		"Você não poderá recuperar esta conta financeira!",
	"You will not be able to recover this chart of account!":
		"Você não poderá recuperar este plano de conta!",
	"You will not be able to recover this bill!":
		"Você não poderá recuperar esta conta a pagar!",
	"You will not be able to recover this product!":
		"Você não poderá recuperar este produto!",
	"You will not be able to recover this payment method!":
		"Você não poderá recuperar este método de pagamento!",
	"You will not be able to recover this product category!":
		"Você não poderá recuperar esta categoria!",
	"Deleted!": "Excluído!",
	"The product has been deleted.": "O produto foi excluído",
	"The bill has been deleted.": "A conta a pagar foi excluída",
	"The financial account has been deleted.": "A conta financeira foi excluída",
	"The chart of account has been deleted.": "O plano de conta foi excluído",
	"The product category has been deleted.": "A categoria foi excluída",
	"The payment method has been deleted.": "A payment method foi excluída",
	"Are you sure do you want to convert this budget in an order?":
		"Você tem certeza que deseja converter este orçamento em um pedido?",
	"Are you sure do you want to convert this order in an budget?":
		"Você tem certeza que deseja converter este pedido em um orçamento?",
	"Are you sure do you want to create a change order?":
		"Você tem certeza que deseja criar um pedido de troca?",
	"Are you sure do you want to cancel this sale?":
		"Você tem certeza que deseja cancelar essa venda?",
	"Are you sure do you want to cancel this order?":
		"Você tem certeza que deseja cancelar este pedido?",
	yesConvertIt: "Sim, converter",
	productCategories: "Categorias de produto",
	productCategoryEdit: "Editar categoria de produto",
	category: "Categoria",
	noCategoryRegistered: "Nenhuma categoria cadatrada",
	withoutCategory: "Sem categoria",
	sold: "Vendido",
	missing: "Faltando",
	itemNotFound: "Item não encontrado",
	note: "Informações",
	clientEdit: "Editar cliente",
	productEdit: "Editar produto",
	editProduct: "Editar produto",
	foreign: "Estrangeiro",
	company: "Pessoa jurídica",
	individual: "Pessoa física",
	companyName: "Razão Social",
	socialName: "Nome fantasia",
	schedule: "Agenda",
	scheduleMini: "🕑",
	CPF: "CPF",
	CNPJ: "CNPJ",
	lastName: "Sobrenome",
	select: "Selecione",
	male: "Masculino",
	female: "Feminino",
	gender: "Sexo",
	nationality: "Nacionalidade",
	phone: "Telefone",
	creationDate: "Criado em",
	ordersHistory: "Histórico de pedidos",
	rentalHistory: "Histórico de aluguéis",
	laundryHistory: "Histórico de lavações",
	saleHistory: "Histórico de vendas",
	clientDetail: "Detalhes do cliente",
	userDetail: "Detalhes do usuário",
	clear: "Limpar",
	budgetsMini: "O",
	budgets: "Orçamentos",
	budget: "Orçamento",
	yesConfirmIt: "Sim, confirme",
	yesCancelIt: "Sim, cancelar",
	continueAndCancelIt: "Continuar e cancelar",
	reasonRequired: "Informe um motivo",
	reason: "Motivo",
	confirmPayment: "Confirmar pagamento",
	paymentMethod: "Método de pagamento",
	paymentMethodMini: "MP",
	paymentType: "Tipo de pagamento",
	payment: "Pagamento",
	partialPayment: "Pagamento parcial",
	fullPayment: "Pagamento completo",
	orderPaid: "Pedido Pago",
	orderPaidPartially: "Pedido Pago Parcialmente",
	paidValue: "Valor Pago",
	remainingValue: "Valor Restante",
	yesReserveIt: "Sim, reserve",
	reserved: "Reservado",
	"Are you sure do you want to reserve this order?":
		"Você tem certeza que quer reservar este pedido?",
	"Are you sure do you want to confirm this order?":
		"Você tem certeza que quer confirmar este pedido?",
	"Are you sure do you want to open this lot?":
		"Você tem certeza que quer abrir este lote?",
	"Are you sure do you want to close this lot?":
		"Você tem certeza que quer fechar este lote?",
	"Are you sure do you want to finish this order?":
		"Você tem certeza que quer concluir este pedido?",
	"Are you sure do you want to confirm this order delivery?":
		"Você tem certeza que quer confirmar a entrega deste pedido?",
	"Are you sure do you want to delete the product":
		"Você tem certeza que quer excluir o produto",
	"Are you sure do you want to delete this payment":
		"Você tem certeza que quer excluir esse pagamento?",
	yesCloseIt: "Sim, feche",
	yesOpenIt: "Sim, abra",
	confirm: "Confirmar",
	addItems: "Adicionar itens",
	orderEdit: "Editar pedido",
	editOrder: "Editar pedido",
	editLaundryOrder: "Editar pedido lavanderia",
	editClient: "Editar cliente",
	mount: "Montar",
	mountBed: "Montar cama",
	discount: "Desconto",
	withDiscount: "Com desconto",
	moreInfo: "Mais informações",
	add: "Adicionar",
	itemNotAvailable: "Item não está disponível",
	incompatibleItem: "Item incompatível",
	noneProductForThisItem: "Nenhum produto para este item no pedido",
	maxProductItemExceeded: "Quantidade excedida",
	addToOrder: "Adicionado no pedido",
	outStore: "No cliente",
	inStore: "Na loja",
	changed: "Trocado",
	orderProductDetailTitle: "Detalhes do produto",
	newItem: "Novo item",
	"Are you sure you want to exchange item": "Confirmar a troca do item",
	for: "por",
	confirmNewItem: "Confirmar novo item",
	printContractMessage: "Imprimir contrato",
	summary: "Resumo",
	complete: "Completo",
	created: "Criado",
	alreadyAdded: "Já adicionado",
	orderProductItemHistoricTitle: "Histórico do item",
	referral: "Indicação",
	indication: "Indicação",
	indications: "Indicações",
	indicationsMini: "I",
	orderFastAccess: "Acesso Rápido - Pedido",
	clientFastAccess: "Acesso Rápido - Cliente",
	orderSearch: "Procurar pedido",
	orderSearch_placeholder: "Número do pedido ou nome do cliente",
	clientSearch: "Procurar cliente",
	clientSearch_placeholder: "Nome do cliente ou documento",
	payments: "Pagamentos",
	noPayment: "Nenhum pagamento",
	addPayment: "Adicionar pagamento",
	dailyRevenue: "Faturamento Diário",
	dailyRevenueMini: "FD",
	monthRevenue: "Faturamento Mensal",
	monthRevenueMini: "FM",
	bankSlip: "Boleto bancário",
	expirationDate: "Vencimento",
	sendEmail: "Enviar e-mail",
	pending: "Pendente",
	deliveryConfirmPendingItems:
		"Existem itens não entregues, tem certeza que deseja continuar?",
	indicationsByBusiness: "Indicações por empresa",
	productsByStatus: "Produtos por status",
	pendingOrders: "Pedidos com pendência",
	pendingOrdersMini: "PP",
	pendingLaundry: "Lavanderia com pendência",
	pendingLaundryMini: "LP",
	laundryMini: "LA",
	laundry: "Lavanderia",
	laundryOrderDetail: "Detalhes do pedido - Lavanderia",
	onTheWayToCollect: "Em coleta",
	collected: "Coletado",
	preparationForWashing: "Preparação lavagem",
	washing: "Lavando",
	preparingForDelivery: "Preparação entrega",
	onTheWayToDelivery: "A caminho",
	laundryOrderInfos: "Informações do pedido - Lavanderia",
	"Are you sure do you want to confirm this order is on the way to collect?":
		"Você tem certeza que quer marcar este pedido como a caminho da coleta?",
	"Are you sure do you want to confirm this order is on the way to delivery?":
		"Você tem certeza que quer marcar este pedido como a caminho para entrega?",
	"Are you sure do you want to confirm this order collect?":
		"Você tem certeza que quer marcar este pedido como coletado?",
	"Are you sure do you want to confirm this order as preparation for washing?":
		"Você tem certeza que quer marcar este pedido como em preparação para lavagem?",
	"Are you sure do you want to confirm this order as preparation for delivery?":
		"Você tem certeza que quer marcar este pedido como em preparação para entrega?",
	"Are you sure do you want to confirm this order is ready?":
		"Você tem certeza que quer marcar este pedido como pronto?",
	"Are you sure do you want to confirm this order is washing?":
		"Você tem certeza que quer marcar este pedido como em lavagem?",
	free: "Grátis",
	scheduled: "Agendado",
	expressScheduled: "Agendado Express",
	address: "Endereço",
	users: "Usuários",
	usersMini: "U",
	user: "Usuário",
	userMini: "U",
	userClient: "Cliente do usuário",
	acquistionDate: "Data de aquisição",
	creditHistory: "Histórico de voucher",
	credit: "Voucher",
	newSale: "Nova venda",
	newRental: "Novo aluguel",
	newLot: "Novo lot",
	openValue: "Valor inicial",
	closeValue: "Valor final",
	nameOrCompanyName: "Nome/Razão Social",
	lastNameOrSocialName: "Sobrenome/Nome Fantasia",
	CPFCNPJ: "CPF/CNPJ",
	"No print connected":
		"Nenhuma impressora conectada, por favor abra o programa ou verifique a internet",
	newPaymentMethod: "Novo método de pagamento",
	percentage: "Porcentagem",
	receiveDays: "Dias recebimento",
	fee: "Taxa",
	feeType: "Tipo Taxa",
	chartOfAccounts: "Plano de contas",
	chartOfAccountsMini: "PC",
	subChartOfAccounts: "Sub Plano de contas",
	feeChartOfAccounts: "Plano de contas - Taxa",
	feeSubChartOfAccounts: "Sub Plano de contas - Taxa",
	financialAccount: "Conta financeira",
	financialAccountMini: "CF",
	newFinancialAccount: "Nova conta financeira",
	newChartOfAccount: "Novo plano de contas",
	editChartOfAccount: "Editar plano de contas",
	newSubChartOfAccount: "Novo sub plano de contas",
	editSubChartOfAccount: "Editar sub plano de contas",
	editPaymentMethod: "Editar método de pagamento",
	editFinancialAccount: "Editar conta financeira",
	checking: "Conta corrente",
	savings: "Conta poupança",
	others: "Outros",
	other: "Outro",
	openingBalance: "Saldo abertura",
	bill: "Conta a pagar",
	bills: "Contas a pagar",
	billsMini: "CP",
	accountReceivable: "Conta a receber",
	accountReceivables: "Contas a receber",
	accountReceivablesMini: "CR",
	editSale: "Editar venda",
	pointOfSale: "Ponto de venda",
	pointOfSaleMini: "PDV",
	cashiers: "Caixas",
	cashier: "Caixa",
	cashiersMini: "C",
	subTotal: "Sub-Total",
	total: "Total",
	service: "Serviço",
	withInventory: "Com estoque",
	nonInventory: "Sem estoque",
	addCredit: "Adicionar voucher",
	withdrawValue: "Total retiradas",
	totalWithdrawn: "Total retirado",
	Withdraw: "Retirada",
	differenceNote: "Por que o valor está diferente do ultimo caixa?",
	productDailyRentals: "Aluguéis por produto",
	noOrderRegistered: "Nenhum pedido encontrado",
	priceSimulator: "Simulador de preços",
	priceSimulatorMini: "S",
	oneDay: "1 Dia",
	twoDays: "2 Dias",
	threeDays: "3 Dias",
	fourDays: "4 Dias",
	fiveDays: "5 Dias",
	sixDays: "6 Dias",
	sevenDays: "7 Dias",
	checkIn: "Check-in",
	checkOut: "Check-out",
	scheduledCollect: "Coleta programada",
	rentalFrequence: "Frequência de aluguel",
	rentalFrequenceMini: "FA",
	generateBankSplit: "Gerar boleto",
	cashierDetail: "Detalhes do caixa",
	closeAt: "Fechado em",
	openBy: "Aberto por",
	closed: "Fechado",
	opened: "Aberto",
	closedBy: "Fechado por",
	transactions: "Transações",
	saleDetail: "Detalhes da venda",
	stateReport: "Situação",
	stateReportMini: "S",
	salesByClient: "Vendas por cliente",
	selectTheNewStatus: "Selecione o novo status",
	newUser: "Novo usuário",
	role: "Função",
	Admin: "Administrador",
	Client: "Cliente",
	User: "Usuário",
	Driver: "Motorista",
	Dashboard: "Dashboard",
	Assistant: "Auxiliar",
	Attendant: "Atendente",
	confirmPassword: "Confirme a senha",
	payer: "Pagador",
	yes: "Sim",
	no: "Não",
	pendencies: "Pendências",
	pendenciesMini: "P",
	ready: "Pronto",
	availability: "Disponibilidade",
	availabilityMini: "D",
	addition: "Acréscimo",
	addLaundryOrder: "Adicionar pedido lavanderia",
	emkasa: "emKasa",
	attendances: "Atendimentos",
	noAttendance: "Nenhum atendimento",
	newAttendance: "Novo atendimento",
	info: "Informações",
	"Are you sure do you want to delete this attendance":
		"Você tem certeza que quer excluir este atendimento",
	driver: "Motorista",
	requiredItems: "Itens necessários",
	requiredItemsMini: "IN",
	fill: "Preencher",
	amount: "Quantidade",
	price: "Preço",
	agency: "Agencia",
	account: "Conta",
	installmentAmount: "Parcelas",
	salesByProduct: "Vendas por produto",
	productsByOrder: "Produtos por pedido",
	baseAmount: "QTD Base",
	baseYear: "Ano",
	notSent: "Não enviado",
	pendingSign: "Assinatura pendente",
	signed: "Assinado",
	newClientStock: "Novo Item - Estoque",
	clientStockInfo: "Informações do Estoque",
	clientStock: "Aluguel de Estoque",
	newStock: "Novo estoque",
	clientStockMini: "AE",
	stocks: "Estoques",
	stock: "Estoque",
	stockMini: "E",
	visitsByMonth: "Visitas Mensais",
	displacement: "Deslocamento",
	inactive: "Inativo",
	clientStockEdit: "Editar Estoque",
	franchise: "Franquia",
	"Are you sure do you want to cancel this stock":
		"Você tem certeza que deseja cancelar este estoque?",
	billing: "Faturamento",
	billingMini: "FT"
};

export default strings;
