import * as api from "services/api";

export const GET_PRODUCT = "[PRODUCT DETAIL] GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "[PRODUCT DETAIL] GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "[PRODUCT DETAIL] GET_PRODUCT_FAILED";

export function getProduct(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCT
    });

    const { token } = getState().login;

    let response = await api.sendGet("/product/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: json.model
      });
    } else {
      dispatch({ type: GET_PRODUCT_FAILED });
    }
  };
}
