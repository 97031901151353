import moment from "moment";
import * as actions from "../../../actions/laundry/create";

const initialOrderState = {
	clientId: "",
	city: "",
	note: "",
	phoneNumber: "",
	street: "",
	number: "",
	complement: "",
	neighborhood: "",
	deliveryType: 0,
	collectionDate: moment()
		.hour(8)
		.minute(0)
		.second(0)
		.millisecond(0),
	returnDate: moment()
		.add(1, "day")
		.hour(8)
		.minute(0)
		.second(0)
		.millisecond(0),
	errors: {}
};

const initialState = {
	loading: false,
	deliveryCities: [],
	...initialOrderState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...initialOrderState
			};
		}
		case actions.ADD_LAUNDRY_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ADD_LAUNDRY_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_LAUNDRY_ORDER_SUCCESS: {
			return {
				...state,
				...initialOrderState,
				loading: false
			};
		}
		case actions.GET_DELIVERY_CITIES: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_DELIVERY_CITIES_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_DELIVERY_CITIES_SUCCESS: {
			return {
				...state,
				deliveryCities: payload,
				loading: false
			};
		}

		default: {
			return state;
		}
	}
};

export default reducer;
