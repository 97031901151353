import firebase from "firebase/app";
import "firebase/messaging";

export const initializeFirebase = () => {
  firebase.initializeApp({
    messagingSenderId: "72683175467"
  });
};

export const askPermissionToReceiveMessage = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    return token;
  } catch (error) {
    console.error(error);
  }
};
