import * as api from "../../../../services/api";

export const GET_CASHFLOW = "[CASHFLOW] GET_CASHFLOW";
export const GET_CASHFLOW_ERROR = "[CASHFLOW] GET_CASHFLOW_ERROR";
export const GET_CASHFLOW_SUCCESS = "[CASHFLOW] GET_CASHFLOW_SUCCESS";
export const CLEAR_ITEMS = "[CASHFLOW] CLEAR_ITEMS";

export function getCashFlow(from, to) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CASHFLOW
    });

    const { login } = getState();

    let response = await api.sendGet("/Transaction/Period/" + from + "/" + to, {
      Authorization: "Bearer " + login.token
    });

    if (response.ok) {
      const result = await response.json();

      dispatch({
        type: GET_CASHFLOW_SUCCESS,
        payload: result.model
      });
    } else {
      dispatch({ type: GET_CASHFLOW_ERROR });
    }
  };
}
