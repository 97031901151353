import * as api from "services/api";

import _ from "lodash";

import history from "../../../../history";

export const ADD_LOT = "[LOT ADD] ADD_LOT";
export const ADD_LOT_SUCCESS = "[LOT ADD] ADD_LOT_SUCCESS";
export const ADD_LOT_FAILED = "[LOT ADD] ADD_LOT_FAILED";

export const SET_VALUE = "[LOT ADD] SET_VALUE";
export const CLEAR_VALUE = "[LOT ADD] CLEAR_VALUE";

export function addLot() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_LOT,
    });

    const { login, lotCreate } = getState();

    let { productId, moreInfo } = lotCreate;

    let response = await api.sendPost(
      "/lot",
      {
        productId,
        moreInfo,
      },
      {
        Authorization: "Bearer " + login.token,
      }
    );

    if (response.ok) {
      const result = await response.json();

      dispatch({
        type: ADD_LOT_SUCCESS,
      });

      dispatch(clearValue());

      history.push("/lots/" + result.model.id);
    } else {
      dispatch({ type: ADD_LOT_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload,
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE,
  };
}
