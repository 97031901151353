import React, { Component } from "react";
import { matchRoutes } from "react-router-config";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import firebase from "firebase/app";
import "firebase/messaging";
import { notify } from "reapop";
import Sound from "react-sound";
import soundfile from "../../assets/alert.mp3";

import { askPermissionToReceiveMessage } from "../../push-notification";

import * as actions from "../../store/actions/login";

let redirect = false;

class Authorization extends Component {
  state = { playSound: false };
  constructor(props) {
    super(props);
    this.checkAuth();
  }

  async componentDidMount() {
    var token = await askPermissionToReceiveMessage();

    if (!token) return;

    this.props.setPushNotification(token);

    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
      const { notify, history } = this.props;

      notify({
        title: payload.notification.title,
        message: payload.notification.body,
        status: "info",
        position: "br",
        dismissAfter: 0,
        closeButton: true,
        buttons: [
          {
            name: "Abrir",
            primary: true,
            onClick: () =>
              history.push(new URL(payload.notification.click_action).pathname),
          },
        ],
      });

      this.setState({ playSound: true });
    });
  }

  componentDidUpdate(prevProps) {
    /**
     * If route is changed
     * Update auths
     */
    if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
      this.checkAuth();
    }
  }

  checkAuth() {
    const { routes, history, location, login } = this.props;
    const { token, expiration } = login;
    const matched = matchRoutes(routes, location.pathname)[0];

    if (matched && matched.route.auth) {
      if (matched.route.auth && !(token && expiration)) {
        redirect = true;

        setTimeout(() => {
          history.push({
            pathname: "/login",
            state: { redirectUrl: location.pathname },
          });
        }, 1000);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (redirect) {
      redirect = false;
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { playSound } = this.state;
    const { children } = this.props;

    return (
      <React.Fragment>
        {children}
        {playSound && (
          <Sound
            url={soundfile}
            playStatus={Sound.status.PLAYING}
            playFromPosition={0}
            onFinishedPlaying={() => {
              this.setState({ playSound: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...actions, notify }, dispatch);
}

function mapStateToProps({ login }) {
  return {
    login,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Authorization)
);
