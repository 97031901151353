import * as actions from "../../../actions/productCategory/edit";

const initialProdCategoryState = {
  name: "",
  description: ""
};

const initialState = {
  loading: false,
  item: null,
  errors: {},
  ...initialProdCategoryState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialProdCategoryState,
        item: null
      };
    }
    case actions.GET_PRODUCTCATEGORY: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.EDIT_PRODUCTCATEGORY: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_PRODUCTCATEGORY_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_PRODUCTCATEGORY_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_PRODUCTCATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_PRODUCTCATEGORY_SUCCESS: {
      return {
        ...state,
        item: payload,
        name: payload.name,
        description: payload.description,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
