import * as api from "services/api";

import _ from "lodash";

import history from "../../../../history";

export const EDIT_LAUNDRY_ORDER = "[LAUNDRY_ORDER EDIT] EDIT_LAUNDRY_ORDER";
export const EDIT_LAUNDRY_ORDER_SUCCESS =
  "[LAUNDRY_ORDER EDIT] EDIT_LAUNDRY_ORDER_SUCCESS";
export const EDIT_LAUNDRY_ORDER_FAILED =
  "[LAUNDRY_ORDER EDIT] EDIT_LAUNDRY_ORDER_FAILED";

export const GET_LAUNDRY_ORDER = "[LAUNDRY_ORDER EDIT] GET_LAUNDRY_ORDER";
export const GET_LAUNDRY_ORDER_SUCCESS =
  "[LAUNDRY_ORDER EDIT] GET_LAUNDRY_ORDER_SUCCESS";
export const GET_LAUNDRY_ORDER_FAILED =
  "[LAUNDRY_ORDER EDIT] GET_LAUNDRY_ORDER_FAILED";

export const SET_VALUE = "[LAUNDRY_ORDER EDIT] SET_VALUE";
export const CLEAR_VALUE = "[LAUNDRY_ORDER EDIT] CLEAR_VALUE";

export function getLaundryOrder(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAUNDRY_ORDER
    });

    const { token } = getState().login;

    let response = await api.sendGet("/laundryOrder/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let laundryOrder = json.model;
      dispatch({
        type: GET_LAUNDRY_ORDER_SUCCESS,
        payload: laundryOrder
      });
    } else {
      dispatch({ type: GET_LAUNDRY_ORDER_FAILED });
    }
  };
}

export function editLaundryOrder() {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_LAUNDRY_ORDER
    });

    const { login, laundryOrderEdit } = getState();

    let {
      item,
      clientId,
      collectionDate,
      returnDate,
      deliveryType,
      address,
      note
    } = laundryOrderEdit;

    const errors = {};

    if (!address.street) {
      errors.street = "Campo obrigatório";
    }

    if (!address.number) {
      errors.number = "Campo obrigatório";
    }

    if (!address.city) {
      errors.city = "Campo obrigatório";
    }
    if (!collectionDate) {
      errors.collectionDate = "Campo obrigatório";
    }
    if (deliveryType !== 0 && !returnDate) {
      errors.returnDate = "Campo obrigatório";
    }

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0)
      return dispatch({ type: EDIT_LAUNDRY_ORDER_FAILED });

    let response = await api.sendPut(
      "/laundryOrder/" + item.id,
      {
        ...item,
        clientId,
        deliveryType,
        collectionDate,
        returnDate,
        address,
        note
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      const result = await response.json();

      dispatch({
        type: EDIT_LAUNDRY_ORDER_SUCCESS
      });

      dispatch(clearValue());

      history.push("/laundryOrders/" + result.model.id);
    } else {
      dispatch({ type: EDIT_LAUNDRY_ORDER_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
