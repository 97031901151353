import { sendGet } from "services/api";

export const GET_ORDERS = "[PENDENCIES REPORT] GET_ORDERS";
export const GET_ORDERS_SUCCESS = "[PENDENCIES REPORT] GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILED = "[PENDENCIES REPORT] GET_ORDERS_FAILED";

export const GET_LAUNDRY_ORDERS = "[PENDENCIES REPORT] GET_LAUNDRY_ORDERS";
export const GET_LAUNDRY_ORDERS_SUCCESS =
	"[PENDENCIES REPORT] GET_LAUNDRY_ORDERS_SUCCESS";
export const GET_LAUNDRY_ORDERS_FAILED =
	"[PENDENCIES REPORT] GET_LAUNDRY_ORDERS_FAILED";

export function getOrders(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ORDERS
		});

		const { token } = getState().login;

		let response = await sendGet(`/order/Open`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_ORDERS_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_ORDERS_FAILED });
			callback && callback(true);
		}
	};
}

export function getLaundryOrders(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAUNDRY_ORDERS
		});

		const { token } = getState().login;

		let response = await sendGet(`/LaundryOrder/Open`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let orders = json.model;
			dispatch({
				type: GET_LAUNDRY_ORDERS_SUCCESS,
				payload: orders
			});

			callback && callback(null, orders);
		} else {
			dispatch({ type: GET_LAUNDRY_ORDERS_FAILED });
			callback && callback(true);
		}
	};
}
