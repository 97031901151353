import * as actions from "../../../actions/billing/report";

const initialState = {
	loading: false,
	items: [],
	total: 0,
	count: 0
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.GET_BILLING: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_BILLING_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_BILLING_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: false
			};
		}
		case actions.CLEAR_ITEMS: {
			return {
				...state,
				items: []
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
