import * as api from "services/api";

import _ from "lodash";
import { isValidNumber } from "libphonenumber-js";

import history from "../../../../history";
import moment from "moment";

export const ADD_ORDER = "[ORDER ADD] ADD_ORDER";
export const ADD_ORDER_SUCCESS = "[ORDER ADD] ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILED = "[ORDER ADD] ADD_ORDER_FAILED";

export const GET_DELIVERY_CITIES = "[ORDER ADD] GET_DELIVERY_CITIES";
export const GET_DELIVERY_CITIES_SUCCESS =
	"[ORDER ADD] GET_DELIVERY_CITIES_SUCCESS";
export const GET_DELIVERY_CITIES_FAILED =
	"[ORDER ADD] GET_DELIVERY_CITIES_FAILED";

export const GET_INDICATION_NAMES = "[ORDER ADD] GET_INDICATION_NAMES";
export const GET_INDICATION_NAMES_FAILED =
	"[ORDER ADD] GET_INDICATION_NAMES_FAILED";
export const GET_INDICATION_NAMES_SUCCESS =
	"[ORDER ADD] GET_INDICATION_NAMES_SUCCESS";

export const SET_VALUE = "[ORDER ADD] SET_VALUE";
export const CLEAR_VALUE = "[ORDER ADD] CLEAR_VALUE";

export function addOrder() {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_ORDER
		});

		const { login, orderCreate } = getState();

		let {
			clientId,
			userId,
			driverId,
			phoneNumber,
			people,
			mount,
			discount,
			startDate,
			endDate,
			referral,
			deliveryType,
			deliveryAddress,
			checkIn,
			checkOut,
			city,
			deliveryCity,
			deliveryCityId,
			moreInfo
		} = orderCreate;

		const errors = {};

		if (!clientId) {
			errors.clientId = "Campo obrigatório";
		}

		if (phoneNumber && !isValidNumber(phoneNumber)) {
			errors.phoneNumber = "Telefone inválido";
		}

		if (people < 1) {
			errors.people = "Campo inválido";
		}

		if (deliveryType === 1 && !deliveryAddress) {
			errors.deliveryAddress = "Campo obrigatório";
		}

		if (deliveryType === 1 && !deliveryCity) {
			errors.city = "Campo obrigatório";
		}

		if (moment.parseZone(startDate).isAfter(moment.parseZone(endDate))) {
			errors.startDate = "Data inválida";
			errors.endDate = "Data inválida";
		}

		dispatch(
			setValue({
				errors
			})
		);

		if (_.keys(errors).length > 0) return dispatch({ type: ADD_ORDER_FAILED });

		let response = await api.sendPost(
			"/order/inner",
			{
				phoneNumber,
				people,
				clientId,
				userId,
				driverId,
				mount,
				discount,
				startDate,
				endDate,
				checkIn,
				checkOut,
				deliveryCityId,
				referral,
				deliveryType,
				deliveryAddress,
				city,
				moreInfo
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: ADD_ORDER_SUCCESS
			});

			dispatch(clearValue());

			history.push("/orders/" + result.model.id);
		} else {
			dispatch({ type: ADD_ORDER_FAILED });
		}
	};
}

export function getDeliveryCities(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_DELIVERY_CITIES
		});

		const { login } = getState();

		let response = await api.sendGet("/order/city", {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: GET_DELIVERY_CITIES_SUCCESS,
				payload: result.model
			});

			callback && callback(null, result.model);
		} else {
			dispatch({ type: GET_DELIVERY_CITIES_FAILED });
			callback && callback(true);
		}
	};
}

export function getIndicationNames(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_INDICATION_NAMES
		});

		const { login } = getState();

		let response = await api.sendGet("/order/indication/names", {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: GET_INDICATION_NAMES_SUCCESS
			});

			callback(null, result.model);
		} else {
			dispatch({ type: GET_INDICATION_NAMES_FAILED });
			callback(true);
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
