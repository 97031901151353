import * as api from "services/api";

export const GET_CHART_OF_ACCOUNTS = "[CHART OF ACCOUNT] GET_CHART_OF_ACCOUNTS";
export const GET_CHART_OF_ACCOUNTS_FAILED =
  "[CHART OF ACCOUNT] GET_CHART_OF_ACCOUNTS_FAILED";
export const GET_CHART_OF_ACCOUNTS_SUCCESS =
  "[CHART OF ACCOUNT] GET_CHART_OF_ACCOUNTS_SUCCESS";

export const SET_VALUE = "[CHART OF ACCOUNT] SET_VALUE";
export const CLEAR_VALUE = "[CHART OF ACCOUNT] CLEAR_VALUE";

export function getChartOfAccounts() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CHART_OF_ACCOUNTS
    });

    const { token } = getState().login;

    let response = await api.sendGet("/ChartOfAccounts", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_CHART_OF_ACCOUNTS_SUCCESS,
        payload: json.model
      });
    } else {
      dispatch({ type: GET_CHART_OF_ACCOUNTS_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
