import * as actions from "../../../actions/availability/report";

const initialState = {
	loading: false,
	items: []
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_REQUIRED:
		case actions.GET_AVAILABILITY: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_REQUIRED_FAILED:
		case actions.GET_AVAILABILITY_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_REQUIRED_SUCCESS:
		case actions.GET_AVAILABILITY_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: false
			};
		}
		case actions.CLEAR_ITEMS: {
			return {
				...state,
				items: []
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
