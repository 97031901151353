import * as api from "services/api";

export const GET_BILLS = "[BILL] GET_BILLS";
export const GET_BILLS_FAILED = "[BILL] GET_BILLS_FAILED";
export const GET_BILLS_SUCCESS = "[BILL] GET_BILLS_SUCCESS";

export const SET_VALUE = "[BILL] SET_VALUE";
export const CLEAR_VALUE = "[BILL] CLEAR_VALUE";

export function getBills() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_BILLS
    });

    const { token } = getState().login;

    let response = await api.sendGet("/Bill", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_BILLS_SUCCESS,
        payload: json.model
      });
    } else {
      dispatch({ type: GET_BILLS_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
