import * as api from "services/api";

import _ from "lodash";
import { isValidNumber } from "libphonenumber-js";

import history from "../../../../history";

export const ADD_BUDGET = "[BUDGET EDIT] EDIT_BUDGET";
export const ADD_BUDGET_SUCCESS = "[BUDGET EDIT] EDIT_BUDGET_SUCCESS";
export const ADD_BUDGET_FAILED = "[BUDGET EDIT] EDIT_BUDGET_FAILED";

export const SET_VALUE = "[BUDGET EDIT] SET_VALUE";
export const CLEAR_VALUE = "[BUDGET EDIT] CLEAR_VALUE";

export function addBudget() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_BUDGET
    });

    const { login, budgetCreate } = getState();

    let {
      clientId,
      phoneNumber,
      people,
      mount,
      discount,
      startDate,
      endDate,
      deliveryType,
      deliveryAddress,
      city,
      moreInfo
    } = budgetCreate;

    const errors = {};

    if (!clientId) {
      errors.clientId = "Campo obrigatório";
    }

    if (phoneNumber && !isValidNumber(phoneNumber)) {
      errors.phoneNumber = "Telefone inválido";
    }

    if (people < 1) {
      errors.people = "Campo inválido";
    }

    if (deliveryType === 1 && !deliveryAddress) {
      errors.deliveryAddress = "Campo obrigatório";
    }

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0) return dispatch({ type: ADD_BUDGET_FAILED });

    let response = await api.sendPost(
      "/budget/inner",
      {
        phoneNumber,
        people,
        clientId,
        mount,
        discount,
        startDate,
        endDate,
        deliveryType,
        deliveryAddress,
        city,
        moreInfo
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      const result = await response.json();

      dispatch({
        type: ADD_BUDGET_SUCCESS
      });

      dispatch(clearValue());

      history.push("/budgets/" + result.model.id);
    } else {
      dispatch({ type: ADD_BUDGET_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
