import * as actions from "../../actions/schedule";

const initialState = {
	items: [],
	loading: {}
};
const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return { ...initialState };
		}
		case actions.GET_SCHEDULE: {
			return {
				...state,
				loading: {
					...state.loading,
					getAll: true
				}
			};
		}
		case actions.GET_SCHEDULE_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getAll: false
				},
				errors: payload
			};
		}
		case actions.GET_SCHEDULE_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					getAll: false
				},
				items: payload || []
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
