import * as actions from "../../../actions/budget/detail";

const initialState = {
	loading: false,
	item: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_BUDGET: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_BUDGET_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_BUDGET_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		default: {
			return state;
		}
		case actions.ADD_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.ADD_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.CANCEL_BUDGET: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CANCEL_BUDGET_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CANCEL_BUDGET_SUCCESS: {
			return {
				...state,
				item: null,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_COURTESY: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CHANGE_CHARGE_COURTESY_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_COURTESY_SUCCESS: {
			return {
				...state,
				item: { ...state.item, chargeCourtesy: payload },
				loading: false
			};
		}
	}
};

export default reducer;
