import * as api from "services/api";

import history from "../../../../history";

import _ from "lodash";

export const ADD_PRODUCT = "[PRODUCT CREATE] ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "[PRODUCT CREATE] ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILED = "[PRODUCT CREATE] ADD_PRODUCT_FAILED";

export const SET_VALUE = "[PRODUCT CREATE] SET_VALUE";
export const CLEAR_VALUE = "[PRODUCT CREATE] CLEAR_VALUE";

export function addProduct() {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_PRODUCT
		});

		const { login, productCreate } = getState();

		let {
			code,
			name,
			description,
			buyPrice,
			rentalPrice,
			rentalPrices,
			sellPrice,
			stockPrice,
			status,
			productCategoryId,
			maxLocation,
			weight,
			baseAmount,
			baseYear,
			type,
			file,
			lotLimit,
			image
		} = productCreate;

		const errors = {};

		if (!name) {
			errors.name = "Informe um nome";
		}
		if (!code) {
			errors.code = "Informe um código";
		}

		if (!weight || weight < 1) {
			errors.weight = "Informe um peso";
		}

		dispatch(
			setValue({
				errors
			})
		);

		if (_.keys(errors).length > 0)
			return dispatch({ type: ADD_PRODUCT_FAILED });

		if (file) {
			let response = await api.sendPostFile("/product/upload", file, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				const result = await response.json();
				image = result.model;
			}
		}

		buyPrice = (buyPrice * 100).toFixed(0);
		rentalPrice = (rentalPrice * 100).toFixed(0);
		sellPrice = (sellPrice * 100).toFixed(0);
		stockPrice = (stockPrice * 100).toFixed(0);

		let response = await api.sendPost(
			"/product",
			{
				code,
				name,
				description,
				buyPrice,
				rentalPrice,
				rentalPrices,
				sellPrice,
				stockPrice,
				status,
				baseAmount,
				baseYear,
				productCategoryId,
				maxLocation,
				weight,
				type,
				lotLimit,
				image
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: ADD_PRODUCT_SUCCESS
			});

			dispatch(clearValue());

			history.push("/products/" + result.model.id);
		} else {
			dispatch({ type: ADD_PRODUCT_FAILED });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
