import moment from "moment";

import ls from "Localization";
import * as api from "../../../../services/api";

export const GET_DAILYREVENUE = "[DAILYREVENUE] GET_DAILYREVENUE";
export const GET_DAILYREVENUE_ERROR = "[DAILYREVENUE] GET_DAILYREVENUE_ERROR";
export const GET_DAILYREVENUE_SUCCESS =
	"[DAILYREVENUE] GET_DAILYREVENUE_SUCCESS";
export const CLEAR_ITEMS = "[DAILYREVENUE] CLEAR_ITEMS";

export function getDailyRevenue(from, to) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_DAILYREVENUE
		});

		const { login } = getState();

		let response = await api.sendGet("/Transaction/Period/" + from + "/" + to, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			const result = await response.json();

			var dates = [];
			from = moment.parseZone(from);
			to = moment.parseZone(to);
			while (from <= to) {
				dates.push({
					date: from.format(ls.dateFormatShort),
					money: 0,
					creditCard: 0,
					debitCard: 0
				});
				from.add(1, "day");
			}

			for (const m of result.model) {
				var date = dates.find(
					c =>
						c.date ===
						moment
							.parseZone(m.creationDate)
							.local()
							.format(ls.dateFormatShort)
				);

				if (date) {
					if (m.paymentType === 0) date.money += m.value;
					if (m.paymentType === 1) date.creditCard += m.value;
					if (m.paymentType === 2) date.debitCard += m.value;
				}
			}

			dispatch({
				type: GET_DAILYREVENUE_SUCCESS,
				payload: dates
			});
		} else {
			dispatch({ type: GET_DAILYREVENUE_ERROR });
		}
	};
}
