import * as api from "services/api";

export const GET_CASHIERS = "[CASHIER REPORT] GET_CASHIERS";
export const GET_CASHIERS_FAILED = "[CASHIER REPORT] GET_CASHIERS_FAILED";
export const GET_CASHIERS_SUCCESS = "[CASHIER REPORT] GET_CASHIERS_SUCCESS";

export const SET_VALUE = "[CASHIER REPORT] SET_VALUE";
export const CLEAR_VALUE = "[CASHIER REPORT] CLEAR_VALUE";

export function getCashiers() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CASHIERS
    });

    const { token } = getState().login;

    let response = await api.sendGet("/cashier", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_CASHIERS_SUCCESS,
        payload: json.model
      });
    } else {
      dispatch({ type: GET_CASHIERS_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
