import * as api from "../../../../services/api";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_FAILED = "GET_DRIVERS_FAILED";

export function getUsers(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_USERS
		});

		const { token } = getState().login;

		let response = await api.sendGet("/user", {
			Authorization: "Bearer " + token
		});
		if (response.ok) {
			let json = await response.json();
			let users = json.model;

			dispatch({
				type: GET_USERS_SUCCESS,
				payload: users
			});

			callback && callback(null, users);
		} else {
			dispatch({
				type: GET_USERS_FAILED
			});
			callback && callback(true);
		}
	};
}

export function getDrivers(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_DRIVERS
		});

		const { token } = getState().login;

		let response = await api.sendGet("/user/driver", {
			Authorization: "Bearer " + token
		});
		if (response.ok) {
			let json = await response.json();
			let users = json.model;

			dispatch({
				type: GET_DRIVERS_SUCCESS,
				payload: users
			});

			callback && callback(null, users);
		} else {
			dispatch({
				type: GET_DRIVERS_FAILED
			});
			callback && callback(true);
		}
	};
}
