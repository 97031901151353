import * as api from "services/api";

export const GET_INDICATIONS =
  "[INDICATION BY BUSINESS REPORT] GET_INDICATIONS";
export const GET_INDICATIONS_FAILED =
  "[INDICATION BY BUSINESS REPORT] GET_INDICATIONS_FAILED";
export const GET_INDICATIONS_SUCCESS =
  "[INDICATION BY BUSINESS REPORT] GET_INDICATIONS_SUCCESS";
export const SET_SEARCH_TEXT =
  "[INDICATION BY BUSINESS REPORT] SET_SEARCH_TEXT";
export const CLEAR_ITEMS = "[INDICATION BY BUSINESS REPORT] CLEAR_ITEMS";

export function clearItems() {
  return {
    type: CLEAR_ITEMS
  };
}

export function getIndications(from, to) {
  if (!from || !to) return { type: "nothing" };
  return async (dispatch, getState) => {
    dispatch({
      type: GET_INDICATIONS
    });

    const { token } = getState().login;

    let response = await api.sendGet(
      "/order/indication/period/" + from.format() + "/" + to.format(),
      {
        Authorization: "Bearer " + token
      }
    );

    if (response.ok) {
      let json = await response.json();

      let products = json.model;
      dispatch({
        type: GET_INDICATIONS_SUCCESS,
        payload: products
      });
    } else {
      dispatch({ type: GET_INDICATIONS_FAILED });
    }
  };
}
