import * as api from "services/api";

export const GET_CLIENT = "[CLIENT DETAIL] GET_CLIENT";
export const GET_CLIENT_SUCCESS = "[CLIENT DETAIL] GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILED = "[CLIENT DETAIL] GET_CLIENT_FAILED";

export const GET_CLIENT_CREDIT = "[CLIENT DETAIL] GET_CLIENT_CREDIT";
export const GET_CLIENT_CREDIT_SUCCESS =
	"[CLIENT DETAIL] GET_CLIENT_CREDIT_SUCCESS";
export const GET_CLIENT_CREDIT_FAILED =
	"[CLIENT DETAIL] GET_CLIENT_CREDIT_FAILED";

export function getClient(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENT
		});

		const { token } = getState().login;

		let response = await api.sendGet("/client/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let client = json.model;
			dispatch({
				type: GET_CLIENT_SUCCESS,
				payload: client
			});
		} else {
			dispatch({ type: GET_CLIENT_FAILED });
		}
	};
}

export function getClientCredit(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENT_CREDIT
		});

		const { token } = getState().login;

		let response = await api.sendGet("/client/" + id + "/credit", {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let client = json.model;
			dispatch({
				type: GET_CLIENT_CREDIT_SUCCESS,
				payload: client
			});
		} else {
			dispatch({ type: GET_CLIENT_CREDIT_FAILED });
		}
	};
}
