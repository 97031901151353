import * as actions from "../../actions/budget";

const canClearState = {
	loading: true,
	items: [],
	errors: {}
};

const initialState = {
	...canClearState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			if (payload)
				return {
					...initialState
				};

			return {
				...state,
				...canClearState
			};
		}

		case actions.GET_BUDGETS: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_BUDGETS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.GET_BUDGETS_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload || []
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
