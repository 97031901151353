import * as api from "services/api";

export const GET_PAYMENT_METHODS = "[PAYMENT METHOD] GET_PAYMENT_METHODS";
export const GET_PAYMENT_METHODS_FAILED =
  "[PAYMENT METHOD] GET_PAYMENT_METHODS_FAILED";
export const GET_PAYMENT_METHODS_SUCCESS =
  "[PAYMENT METHOD] GET_PAYMENT_METHODS_SUCCESS";

export const SET_VALUE = "[PAYMENT METHOD] SET_VALUE";
export const CLEAR_VALUE = "[PAYMENT METHOD] CLEAR_VALUE";

export function getPaymentMethods() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PAYMENT_METHODS
    });

    const { token } = getState().login;

    let response = await api.sendGet("/PaymentMethod", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_PAYMENT_METHODS_SUCCESS,
        payload: json.model
      });
    } else {
      dispatch({ type: GET_PAYMENT_METHODS_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
