import * as actions from "../../../actions/order/report";

const canClearState = {
	loading: false,
	items: [],
	filter: [],
	pages: 0,
	pendingItems: []
};

const initialState = {
	...canClearState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			if (payload)
				return {
					...initialState
				};

			return {
				...state,
				...canClearState
			};
		}
		case actions.GET_ORDERS_PERIOD:
		case actions.GET_ORDERS: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_ORDERS_PERIOD_FAILED:
		case actions.GET_ORDERS_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_ORDERS_PERIOD_SUCCESS:
		case actions.GET_ORDERS_SUCCESS: {
			return {
				...state,
				items: payload ? payload : [],
				loading: false
			};
		}
		case actions.GET_ORDERS_PENDING: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_ORDERS_PENDING_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_ORDERS_PENDING_SUCCESS: {
			return {
				...state,
				pendingItems: payload ? payload : [],
				loading: false
			};
		}
		case actions.RESOLVE_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.RESOLVE_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.RESOLVE_ORDER_SUCCESS: {
			return {
				...state,
				pendingItems: (state.pendingItems || []).filter(c => c.id !== payload),
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
