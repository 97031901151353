import moment from "moment";

import { refreshToken } from "../store/actions/login";

export function jwt({ dispatch, getState }) {
  return next => action => {
    // only worry about expiring token for async actions
    if (typeof action === "function") {
      const { login } = getState();

      const { expiration, token } = login;

      if (
        token &&
        expiration &&
        moment(expiration) - moment(Date.now()) < 5000
      ) {
        // make sure we are not already refreshing the token
        if (!getState().login.freshTokenPromise) {
          return refreshToken(dispatch, getState).then(() => next(action));
        } else {
          return getState().login.freshTokenPromise.then(() => next(action));
        }
      }
    }
    return next(action);
  };
}
