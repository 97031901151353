import * as api from "../../../services/api";
import history from "history.js";

import LogRocket from "logrocket";

export const LOGIN = "[LOGIN] LOGIN";
export const LOGIN_ERROR = "[LOGIN] LOGIN_ERROR";
export const LOGIN_SUCCESS = "[LOGIN] LOGIN_SUCCESS";
export const USER_LOGGED_OUT = "[LOGIN] LOGGED OUT";
export const SET_ERROR = "[LOGIN] SET_ERROR";
export const SET_USERNAME_ERROR = "[LOGIN] SET_USERNAME_ERROR";
export const SET_PASSWORD_ERROR = "[LOGIN] SET_PASSWORD_ERROR";
export const REFRESH_TOKEN = "[LOGIN] REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "[LOGIN] REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "[LOGIN] REFRESH_TOKEN_ERROR";
export const SET_USER_DATA = "[LOGIN] SET DATA";
export const GET_USER_DATA_ERROR = "[LOGIN] GET DATA ERROR";
export const SET_PUSH_TOKEN = "[LOGIN] SET_PUSH_TOKEN";
export const SET_PUSH_TOKEN_ERROR = "[LOGIN] SET_PUSH_TOKEN_ERROR";

export function submitLogin({ username, password }) {
	return async dispatch => {
		dispatch({
			type: LOGIN
		});

		try {
			let response = await api.sendPost("/token?dashboard=true", {
				username,
				password
			});

			let json = await response.json();

			if (json.isValid && json.model) {
				let token = json.model;

				dispatch({
					type: LOGIN_SUCCESS,
					payload: token
				});

				return dispatch(getUserData());
			} else {
				const error = {
					username: json.errors.includes("Invalid email.")
						? "E-mail não cadastrado"
						: null,
					password: json.errors.includes("Invalid password.")
						? "Senha incorreta"
						: null,
					default: json.errors.includes("Not allowed") ? "Não autorizado" : null
				};

				return dispatch({
					type: LOGIN_ERROR,
					payload: error
				});
			}
		} catch (error) {
			return dispatch({
				type: LOGIN_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getUserData() {
	return async (dispatch, getState) => {
		let { login } = getState();

		let response = await api.sendGet("/user/me", {
			Authorization: "Bearer " + login.token
		});

		let result = await response.json();
		if (result.isValid && result.model) {
			if (process.env.REACT_APP_LOG_ROCKET)
				LogRocket.identify(result.model.id, {
					name: result.model.fullName,
					email: result.model.email
				});

			return dispatch({
				type: SET_USER_DATA,
				payload: result.model
			});
		}

		return dispatch({
			type: GET_USER_DATA_ERROR
		});
	};
}

export function setPushNotification(token) {
	return async (dispatch, getState) => {
		let { login } = getState();

		let response = await api.sendPost("/user/push/" + token, null, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			return dispatch({
				type: SET_PUSH_TOKEN
			});
		}

		return dispatch({
			type: SET_PUSH_TOKEN_ERROR
		});
	};
}

export function sendRefreshToken() {
	return (dispatch, getState) => {
		refreshToken(dispatch, getState);
	};
}

export function refreshToken(dispatch, getState) {
	let { login } = getState();

	try {
		let refreshTokenPromise = api
			.sendPost(
				"/refreshToken?token=" + login.token + "&userId=" + login.user.id
			)
			.then(res => {
				if (res.ok) {
					return res.json();
				} else {
					console.log(res);
				}
			})
			.then(json => {
				if (json && json.isValid && json.model) {
					let token = json.model;

					dispatch({
						type: LOGIN_SUCCESS,
						payload: token
					});

					dispatch(getUserData());

					dispatch({
						type: REFRESH_TOKEN_SUCCESS
					});
					Promise.resolve(token);
				} else {
					const error = {
						username: json.errors.includes("Invalid email.")
							? "Invalid email."
							: null,
						password: json.errors.includes("Invalid password.")
							? "Invalid password."
							: null
					};

					dispatch({
						type: REFRESH_TOKEN_ERROR,
						payload: error
					});

					Promise.reject(error);
				}
			})
			.catch(err => {
				console.log(err);
			});

		dispatch({
			type: REFRESH_TOKEN,
			payload: refreshTokenPromise
		});

		return refreshTokenPromise;
	} catch (error) {
		dispatch({
			type: REFRESH_TOKEN_ERROR,
			payload: {
				error: "Connection error"
			}
		});
	}
}

export function logoutUser() {
	history.push({
		pathname: "/login"
	});

	return dispatch => {
		dispatch({
			type: USER_LOGGED_OUT
		});
	};
}

export function setUsernameError(payload) {
	return {
		type: SET_USERNAME_ERROR,
		payload
	};
}

export function setPasswordError(payload) {
	return {
		type: SET_PASSWORD_ERROR,
		payload
	};
}

export function setError(payload) {
	return {
		type: SET_ERROR,
		payload
	};
}
