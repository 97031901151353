import * as api from "services/api";

export const ADD_CLIENT_CREDIT = "[CLIENT CREATE] ADD_CLIENT_CREDIT";
export const ADD_CLIENT_CREDIT_SUCCESS =
  "[CLIENT CREATE] ADD_CLIENT_CREDIT_SUCCESS";
export const ADD_CLIENT_CREDIT_FAILED =
  "[CLIENT CREATE] ADD_CLIENT_CREDIT_FAILED";

export const SET_VALUE = "[CLIENT CREATE] SET_VALUE";
export const CLEAR_VALUE = "[CLIENT CREATE] CLEAR_VALUE";

export function addClientCredit(id, model, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_CLIENT_CREDIT
    });

    const { login } = getState();

    let response = await api.sendPost(`/client/${id}/credit`, model, {
      Authorization: "Bearer " + login.token
    });

    if (response.ok) {
      dispatch({
        type: ADD_CLIENT_CREDIT_SUCCESS
      });

      callback && callback(true);
    } else {
      dispatch({ type: ADD_CLIENT_CREDIT_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
