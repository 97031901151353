import * as api from "services/api";

import history from "../../../../history";

import _ from "lodash";

export const ADD_CHART_OF_ACCOUNT =
  "[CHART OF ACCOUNT CREATE] ADD_CHART_OF_ACCOUNT";
export const ADD_CHART_OF_ACCOUNT_SUCCESS =
  "[CHART OF ACCOUNT CREATE] ADD_CHART_OF_ACCOUNT_SUCCESS";
export const ADD_CHART_OF_ACCOUNT_FAILED =
  "[CHART OF ACCOUNT CREATE] ADD_CHART_OF_ACCOUNT_FAILED";

export const SET_VALUE = "[CHART OF ACCOUNT CREATE] SET_VALUE";
export const CLEAR_VALUE = "[CHART OF ACCOUNT CREATE] CLEAR_VALUE";

export function addChartOfAccount() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_CHART_OF_ACCOUNT
    });

    const { login, chartOfAccountCreate } = getState();

    let { name, code, description, chartOfAccountsType } = chartOfAccountCreate;

    const errors = {};

    if (!name) errors.name = "Informe um nome";
    if (!code) errors.code = "Informe um código";

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0)
      return dispatch({ type: ADD_CHART_OF_ACCOUNT_FAILED });

    let response = await api.sendPost(
      "/ChartOfAccounts",
      {
        name,
        code,
        description,
        chartOfAccountsType
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      dispatch({
        type: ADD_CHART_OF_ACCOUNT_SUCCESS
      });

      dispatch(clearValue());

      history.push("/ChartOfAccounts");
    } else {
      dispatch({ type: ADD_CHART_OF_ACCOUNT_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
