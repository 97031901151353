import { combineReducers } from "redux";
import { reducer as notificationsReducer } from "reapop";

import schedule from "./schedule";
import productEdit from "./product/edit";
import productDetailItem from "./product/detail/item";
import productDetail from "./product/detail";
import productCreate from "./product/create";
import productCategoryCreate from "./productCategory/create";
import productCategoryReport from "./productCategory/report";
import productCategoryEdit from "./productCategory/edit";
import clientCreate from "./client/create";
import clientCredit from "./client/credit";
import clientEdit from "./client/edit";
import orderCreate from "./order/create";
import budgetCreate from "./budget/create";
import orderEdit from "./order/edit";
import budgetEdit from "./budget/edit";
import clientDetail from "./client/detail";
import orderDetail from "./order/detail";
import laundryOrderDetail from "./laundry/detail";
import laundryOrderEdit from "./laundry/edit";
import budgetDetail from "./budget/detail";
import productReport from "./product/report";
import clientReport from "./client/report";
import orderReport from "./order/report";
import order from "./order";
import budget from "./budget";
import laundryOrderReport from "./laundry/report";
import budgetReport from "./budget/report";
import inventoryReport from "./inventory/report";
import indicationReport from "./indication/report";
import indicationByBusinessReport from "./indicationByBusiness/report";
import cashFlow from "./financial/cashFlow";
import dailyRevenue from "./financial/dailyRevenue";
import monthRevenue from "./financial/monthRevenue";
import login from "./login";
import userReport from "./user/report";
import userCreate from "./user/create";
import userDetail from "./user/detail";
import cashier from "./cashier";
import cashierDetail from "./cashier/detail";
import sale from "./sale";
import pendencies from "./pendencies/report";
import paymentMethod from "./paymentMethod";
import paymentMethodCreate from "./paymentMethod/create";
import paymentMethodEdit from "./paymentMethod/edit";
import financialAccount from "./financialAccount";
import financialAccountCreate from "./financialAccount/create";
import financialAccountEdit from "./financialAccount/edit";
import chartOfAccount from "./chartOfAccount";
import chartOfAccountCreate from "./chartOfAccount/create";
import chartOfAccountEdit from "./chartOfAccount/edit";
import subChartOfAccountCreate from "./chartOfAccount/sub/create";
import subChartOfAccountEdit from "./chartOfAccount/sub/edit";
import bill from "./bill";
import accountReceivable from "./accountReceivable";
import cashierReport from "./cashier/report";
import availabilityReport from "./availability/report";
import laundryOrderCreate from "./laundry/create";
import transaction from "./transaction";

import stockReport from "./clientStock/report";
import stockCreate from "./clientStock/create";
import stockDetail from "./clientStock/detail";
import stockEdit from "./clientStock/edit";

import lotCreate from "./lot/create";
import lotReport from "./lot/report";
import lotDetail from "./lot/detail";

import billing from "./billing/report";

const rootReducer = combineReducers({
	billing,
	lotCreate,
	lotReport,
	lotDetail,
	schedule,
	availabilityReport,
	clientEdit,
	budget,
	order,
	orderEdit,
	orderCreate,
	productCreate,
	clientCreate,
	clientCredit,
	productEdit,
	budgetEdit,
	productDetailItem,
	productDetail,
	orderDetail,
	clientDetail,
	productReport,
	clientReport,
	orderReport,
	budgetReport,
	userCreate,
	userReport,
	userDetail,
	budgetDetail,
	budgetCreate,
	inventoryReport,
	cashFlow,
	dailyRevenue,
	monthRevenue,
	productCategoryCreate,
	productCategoryEdit,
	productCategoryReport,
	indicationReport,
	indicationByBusinessReport,
	login,
	laundryOrderReport,
	laundryOrderDetail,
	laundryOrderEdit,
	cashier,
	sale,
	pendencies,
	paymentMethod,
	paymentMethodCreate,
	paymentMethodEdit,
	financialAccount,
	financialAccountCreate,
	financialAccountEdit,
	chartOfAccount,
	chartOfAccountCreate,
	chartOfAccountEdit,
	subChartOfAccountCreate,
	subChartOfAccountEdit,
	bill,
	accountReceivable,
	cashierReport,
	cashierDetail,
	laundryOrderCreate,
	transaction,
	stockReport,
	stockCreate,
	stockDetail,
	stockEdit,
	notifications: notificationsReducer()
});

export default rootReducer;
