import * as actions from "../../../actions/order/edit";

const initialOrderState = {
  status: "",
  clientId: "",
  userId: "",
  city: "",
  code: "",
  people: "",
  moreInfo: "",
  phoneNumber: "",
  startDate: "",
  endDate: "",
  deliveryCityId: "",
  deliveryCity: null,
  deliveryType: "",
  deliveryAddress: "",
  discountValue: 0,
  mount: false,
  discount: false,
  manualDeliveryFee: false,
  deliveryFee: 0,
  item: null,
  errors: {}
};

const initialState = {
  loading: false,
  ...initialOrderState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      if (state.item && !payload) {
        return {
          ...state,
          ...initialOrderState,
          ...state.item,
          item: state.item
        };
      } else {
        return {
          ...state,
          ...initialOrderState
        };
      }
    }
    case actions.GET_ORDER: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.EDIT_ORDER: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_ORDER_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_ORDER_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_ORDER_SUCCESS: {
      return {
        ...state,
        ...initialOrderState,
        loading: false
      };
    }
    case actions.GET_ORDER_SUCCESS: {
      return {
        ...state,
        item: payload,
        ...payload,
        moreInfo: payload.moreInfo || "",
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
