import moment from "moment";

import ls from "Localization";
import * as api from "../../../../services/api";

export const GET_MONTHREVENUE = "[MONTHREVENUE] GET_MONTHREVENUE";
export const GET_MONTHREVENUE_ERROR = "[MONTHREVENUE] GET_MONTHREVENUE_ERROR";
export const GET_MONTHREVENUE_SUCCESS =
	"[MONTHREVENUE] GET_MONTHREVENUE_SUCCESS";
export const CLEAR_ITEMS = "[MONTHREVENUE] CLEAR_ITEMS";

export function getMonthRevenue(from, to) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_MONTHREVENUE
		});

		const { login } = getState();

		let response = await api.sendGet("/Transaction/Period/" + from + "/" + to, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			const result = await response.json();

			var months = [];
			from = moment.parseZone(from);
			to = moment.parseZone(to);
			while (from <= to) {
				months.push({
					month: from.format(ls.dateFormatMonth),
					money: 0,
					creditCard: 0,
					debitCard: 0
				});
				from.add(1, "month");
			}

			for (const m of result.model) {
				var month = months.find(
					c =>
						c.month ===
						moment
							.parseZone(m.creationDate)
							.local()
							.format(ls.dateFormatMonth)
				);

				if (month) {
					if (m.paymentType === 0) month.money += m.value;
					if (m.paymentType === 1) month.creditCard += m.value;
					if (m.paymentType === 2) month.debitCard += m.value;
				}
			}

			dispatch({
				type: GET_MONTHREVENUE_SUCCESS,
				payload: months
			});
		} else {
			dispatch({ type: GET_MONTHREVENUE_ERROR });
		}
	};
}
