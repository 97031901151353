import _ from "lodash";
import * as api from "services/api";
import history from "../../../../history";

export const EDIT_FINANCIAL_ACCOUNT =
	"[FINANCIAL_ACCOUNT EDIT] EDIT_FINANCIAL_ACCOUNT";
export const EDIT_FINANCIAL_ACCOUNT_SUCCESS =
	"[FINANCIAL_ACCOUNT EDIT] EDIT_FINANCIAL_ACCOUNT_SUCCESS";
export const EDIT_FINANCIAL_ACCOUNT_FAILED =
	"[FINANCIAL_ACCOUNT EDIT] EDIT_FINANCIAL_ACCOUNT_FAILED";

export const GET_FINANCIAL_ACCOUNT =
	"[FINANCIAL_ACCOUNT EDIT] GET_FINANCIAL_ACCOUNT";
export const GET_FINANCIAL_ACCOUNT_SUCCESS =
	"[FINANCIAL_ACCOUNT EDIT] GET_FINANCIAL_ACCOUNT_SUCCESS";
export const GET_FINANCIAL_ACCOUNT_FAILED =
	"[FINANCIAL_ACCOUNT EDIT] GET_FINANCIAL_ACCOUNT_FAILED";

export const SET_VALUE = "[FINANCIAL_ACCOUNT EDIT] SET_VALUE";
export const CLEAR_VALUE = "[FINANCIAL_ACCOUNT EDIT] CLEAR_VALUE";

export function getFinancialAccount(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_FINANCIAL_ACCOUNT
		});

		const { token } = getState().login;

		let response = await api.sendGet("/FinancialAccount/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let item = json.model;
			dispatch({
				type: GET_FINANCIAL_ACCOUNT_SUCCESS,
				payload: item
			});
		} else {
			dispatch({ type: GET_FINANCIAL_ACCOUNT_FAILED });
		}
	};
}

export function editFinancialAccount() {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_FINANCIAL_ACCOUNT
		});

		const { login, financialAccountEdit } = getState();

		let {
			name,
			type,
			agency,
			account,
			openingBalance,
			note,
			item
		} = financialAccountEdit;

		const errors = {};

		if (!name) errors.name = "Informe um nome";

		dispatch(setValue({ errors }));

		if (_.keys(errors).length > 0)
			return dispatch({ type: EDIT_FINANCIAL_ACCOUNT_FAILED });

		let response = await api.sendPut(
			"/FinancialAccount/" + item.id,
			{
				...item,
				name,
				type,
				agency,
				account,
				openingBalance: openingBalance * 100,
				note
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			dispatch({
				type: EDIT_FINANCIAL_ACCOUNT_SUCCESS
			});

			dispatch(clearValue());

			history.push("/FinancialAccount");
		} else {
			dispatch({ type: EDIT_FINANCIAL_ACCOUNT_FAILED });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
