import * as api from "services/api";

import _ from "lodash";

export const CREATE_SALE = "[PRODUCT SALE] CREATE_SALE";
export const CREATE_SALE_FAILED = "[PRODUCT SALE] CREATE_SALE_FAILED";
export const CREATE_SALE_SUCCESS = "[PRODUCT SALE] CREATE_SALE_SUCCESS";

export const EDIT_SALE = "[PRODUCT SALE] EDIT_SALE";
export const EDIT_SALE_FAILED = "[PRODUCT SALE] EDIT_SALE_FAILED";
export const EDIT_SALE_SUCCESS = "[PRODUCT SALE] EDIT_SALE_SUCCESS";

export const GET_SALES = "[PRODUCT SALE] GET_SALES";
export const GET_SALES_FAILED = "[PRODUCT SALE] GET_SALES_FAILED";
export const GET_SALES_SUCCESS = "[PRODUCT SALE] GET_SALES_SUCCESS";

export const GET_SALE = "[PRODUCT SALE] GET_SALE";
export const GET_SALE_FAILED = "[PRODUCT SALE] GET_SALE_FAILED";
export const GET_SALE_SUCCESS = "[PRODUCT SALE] GET_SALE_SUCCESS";

export const GET_SALES_PRODUCT = "[PRODUCT SALE] GET_SALES_PRODUCT";
export const GET_SALES_PRODUCT_FAILED =
	"[PRODUCT SALE] GET_SALES_PRODUCT_FAILED";
export const GET_SALES_PRODUCT_SUCCESS =
	"[PRODUCT SALE] GET_SALES_PRODUCT_SUCCESS";

export const GET_AVAILABLE_CREDIT = "[PRODUCT SALE] GET_AVAILABLE_CREDIT";
export const GET_AVAILABLE_CREDIT_FAILED =
	"[PRODUCT SALE] GET_AVAILABLE_CREDIT_FAILED";
export const GET_AVAILABLE_CREDIT_SUCCESS =
	"[PRODUCT SALE] GET_AVAILABLE_CREDIT_SUCCESS";

export const GET_PENDING_SALES = "[PRODUCT SALE] GET_PENDING_SALES";
export const GET_PENDING_SALES_FAILED =
	"[PRODUCT SALE] GET_PENDING_SALES_FAILED";
export const GET_PENDING_SALES_SUCCESS =
	"[PRODUCT SALE] GET_PENDING_SALES_SUCCESS";

export const PRINT_SALE = "[PRODUCT SALE] PRINT_SALE";
export const PRINT_SALE_FAILED = "[PRODUCT SALE] PRINT_SALE_FAILED";
export const PRINT_SALE_SUCCESS = "[PRODUCT SALE] PRINT_SALE_SUCCESS";

export const CANCEL_SALE = "[PRODUCT SALE] CANCEL_SALE";
export const CANCEL_SALE_FAILED = "[PRODUCT SALE] CANCEL_SALE_FAILED";
export const CANCEL_SALE_SUCCESS = "[PRODUCT SALE] CANCEL_SALE_SUCCESS";

export const UPDATE_QUICKBOOKS = "[PRODUCT SALE] UPDATE_QUICKBOOKS";
export const UPDATE_QUICKBOOKS_FAILED =
	"[PRODUCT SALE] UPDATE_QUICKBOOKS_FAILED";
export const UPDATE_QUICKBOOKS_SUCCESS =
	"[PRODUCT SALE] UPDATE_QUICKBOOKS_SUCCESS";

export const EDIT_PRODUCT = "[PRODUCT SALE] EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "[PRODUCT SALE] EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "[PRODUCT SALE] EDIT_PRODUCT_FAILED";

export const SET_VALUE = "[PRODUCT SALE] SET_VALUE";
export const CLEAR_VALUE = "[PRODUCT SALE] CLEAR_VALUE";

export function create(callback, finish) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_SALE
		});

		const { login, sale } = getState();

		const { token } = login;

		let {
			item,
			cart: products,
			clientId,
			name,
			lastName,
			document,
			email,
			phoneNumber,
			note,
			transactions
		} = sale;

		const errors = {};

		if (!clientId && !name && !document) {
			alert(
				"Você precisa selecionar ou cadastrar um cliente antes de continuar"
			);

			dispatch(
				setValue({ showSelectClient: true, loading: { create: false } })
			);

			return;
		}

		dispatch(setValue({ errors }));

		if (_.keys(errors).length > 0) {
			return;
		}

		if (phoneNumber) {
			phoneNumber = phoneNumber.match(/\d+/g).join("");

			phoneNumber = { number: phoneNumber };
		}

		if (!clientId && name && document) {
			let response = await api.sendPost(
				`/client`,
				{
					name,
					lastName,
					document,
					email,
					phoneNumber,
					type: document.match(/\d+/g).join("").Length > 11 ? 1 : 0
				},
				{
					Authorization: "Bearer " + token
				}
			);

			if (response.ok) {
				let result = await response.json();

				clientId = result.model.id;
			} else {
				let result = await response.json();

				dispatch({ type: CREATE_SALE_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);

				return;
			}
		}

		let response = null;

		if (!item) {
			response = await api.sendPost(
				`/sale`,
				{
					clientId,
					products,
					note
				},
				{
					Authorization: "Bearer " + token
				}
			);
		} else {
			response = await api.sendPut(
				`/sale/${item.id}`,
				{
					...item,
					clientId,
					products,
					note
				},
				{
					Authorization: "Bearer " + token
				}
			);
		}

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				for (const { transaction } of transactions) {
					if (transaction.id) continue;
					transaction.paymentMethod = null;

					var resTransaction = await api.sendPost(
						`/sale/${result.model.id}/transaction`,
						transaction,
						{
							Authorization: "Bearer " + token
						}
					);

					console.log(resTransaction);

					if (!resTransaction.ok) {
						var resultT = await resTransaction.json();

						console.log(resultT);
						dispatch({ type: CREATE_SALE_FAILED, payload: resultT.errors });
						callback && callback(resultT.errors, resultT.model);
						return;
					}
				}

				if (finish) {
					if (response.ok) {
						response = await api.sendPut(
							`/sale/${result.model.id}/Finish`,
							null,
							{
								Authorization: "Bearer " + token
							}
						);

						if (response.ok) {
							dispatch({
								type: CREATE_SALE_SUCCESS,
								payload: result.model
							});

							callback && callback(null, result.model);
						}
					}
				} else {
					dispatch({
						type: CREATE_SALE_SUCCESS,
						payload: result.model
					});

					callback && callback(null, result.model);
				}
			} else {
				dispatch({ type: CREATE_SALE_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: CREATE_SALE_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getSales(startDate, endDate, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_SALES
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/sale?startDate=${startDate}&endDate=${endDate}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SALES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_SALES_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_SALES_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getSalesByProduct(startDate, endDate, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_SALES_PRODUCT
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/sale/info/product?startDate=${startDate}&endDate=${endDate}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SALES_PRODUCT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_SALES_PRODUCT_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_SALES_PRODUCT_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getState(startDate, endDate, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_SALES
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/sale/state?startDate=${startDate}&endDate=${endDate}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SALES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_SALES_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_SALES_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getSalesV2(
	startDate,
	endDate,
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_SALES
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/sale/v2?startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
				"&filters=null"}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_SALES_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_SALES_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_SALES_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getPendingSales(startDate, endDate, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PENDING_SALES
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/Sale/Pending?startDate=${startDate}&endDate=${endDate}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PENDING_SALES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_PENDING_SALES_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_PENDING_SALES_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getSale(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_SALE
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(`/sale/${id}`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SALE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_SALE_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_SALE_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getAvailableCredit(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_AVAILABLE_CREDIT
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(`/client/${id}/credit/Available`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_AVAILABLE_CREDIT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_AVAILABLE_CREDIT_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_AVAILABLE_CREDIT_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export const GET_PENDING_SALE_STATUS = "[PRODUCT SALE] GET_PENDING_SALE_STATUS";
export const GET_PENDING_SALE_STATUS_FAILED =
	"[PRODUCT SALE] GET_PENDING_SALE_STATUS_FAILED";
export const GET_PENDING_SALE_STATUS_SUCCESS =
	"[PRODUCT SALE] GET_PENDING_SALE_STATUS_SUCCESS";

export function getHasPendingSales(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PENDING_SALE_STATUS
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(`/client/${id}/pending/status`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PENDING_SALE_STATUS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_PENDING_SALE_STATUS_FAILED,
					payload: result.errors
				});
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_PENDING_SALE_STATUS_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function printReceipt(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: PRINT_SALE
		});

		const { login, sale, cashier } = getState();

		const { token } = login;
		const { printSale } = sale;

		let response = await api.sendPost(
			`/sale/${printSale.id}/print?local=${cashier.local}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			callback && callback();

			dispatch({
				type: PRINT_SALE_SUCCESS
			});
		} else {
			var json = await response.json();

			callback && callback(json.errors);

			dispatch({ type: PRINT_SALE_FAILED, payload: json.errors });
		}
	};
}

export function updateQuickBooks(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: PRINT_SALE
		});

		const {
			login: { token }
		} = getState();

		let response = await api.sendPost(`/sale/${id}/QuickBooks`, null, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			callback && callback();

			dispatch({
				type: PRINT_SALE_SUCCESS
			});
		} else {
			var json = await response.json();

			callback && callback(json.errors);

			dispatch({ type: PRINT_SALE_FAILED, payload: json.errors });
		}
	};
}

export function cancelSale(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CANCEL_SALE
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendPut(`/sale/${id}/Cancel`, null, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CANCEL_SALE_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: CANCEL_SALE_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: CANCEL_SALE_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function editProduct(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT
		});

		const { login } = getState();

		let response = await api.sendPut("/sale/product/" + id, model, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			let result = await response.json();

			dispatch({
				type: EDIT_PRODUCT_SUCCESS,
				payload: result
			});

			callback && callback(null, result.model);
		} else {
			dispatch({ type: EDIT_PRODUCT_FAILED });
			callback && callback("Error");
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(payload) {
	return {
		type: CLEAR_VALUE,
		payload
	};
}
