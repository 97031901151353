import * as actions from "../../../actions/client/edit";

const initialClientState = {
  status: "",
  type: 0,
  name: "",
  nameError: "",
  lastName: "",
  email: "",
  document: "",
  vip: false,
  zipCode: "",
  street: "",
  number: "",
  phone: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  country: "",
  nationality: "",
  gender: 0,
  isOwner: false,
  item: null,
  errors: {}
};

const initialState = {
  loading: false,
  ...initialClientState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      if (state.item) {
        return {
          ...state,
          ...initialClientState,
          item: state.item,
          status: state.item.status,
          type: state.item.type,
          vip: state.item.vip,
          name: state.item.name ? state.item.name : "",
          lastName: state.item.lastName ? state.item.lastName : "",
          email: state.item.email ? state.item.email : "",
          document: state.item.document ? state.item.document : "",
          phone: state.item.phoneNumber
            ? state.item.phoneNumber.PhoneNumberFormatted
            : "",
          nationality: state.item.nationality ? state.item.nationality : "",
          gender: state.item.gender,
          street: state.item.address ? state.item.address.street : "",
          number: state.item.address ? state.item.address.number : "",
          complement: state.item.address ? state.item.address.complement : "",
          neighborhood: state.item.address
            ? state.item.address.neighborhood
            : "",
          city: state.item.address ? state.item.address.city : "",
          state: state.item.address ? state.item.address.state : "",
          country: state.item.address ? state.item.address.country : "",
          zipCode: state.item.address ? state.item.address.zipCode : ""
        };
      } else {
        return {
          ...state,
          ...initialClientState
        };
      }
    }
    case actions.GET_CLIENT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.EDIT_CLIENT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_CLIENT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_CLIENT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_CLIENT_SUCCESS: {
      return {
        ...state,
        ...initialClientState,
        loading: false
      };
    }
    case actions.GET_CLIENT_SUCCESS: {
      return {
        ...state,
        item: payload,
        status: payload.status,
        type: payload.type,
        vip: payload.vip,
        name: payload.name ? payload.name : "",
        lastName: payload.lastName ? payload.lastName : "",
        email: payload.email ? payload.email : "",
        document: payload.document ? payload.document : "",
        phone: payload.phoneNumber
          ? payload.phoneNumber.phoneNumberFormatted
          : "",
        nationality: payload.nationality ? payload.nationality : "",
        gender: payload.gender,
        street: payload.address ? payload.address.street : "",
        number: payload.address ? payload.address.number : "",
        complement: payload.address ? payload.address.complement : "",
        neighborhood: payload.address ? payload.address.neighborhood : "",
        city: payload.address ? payload.address.city : "",
        state: payload.address ? payload.address.state : "",
        country: payload.address ? payload.address.country : "",
        zipCode: payload.address ? payload.address.zipCode : "",
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
