import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import "react-dates/initialize";

import NotificationsSystem from "reapop";
import theme from "reapop-theme-wybo";

import { initializeFirebase } from "./push-notification";

import Authorization from "./components/Authorization";

import storeCreator from "store";

import routes from "routes/index.jsx";

import "assets/scss/material-dashboard-pro-react.css?v=1.3.0";

import { PersistGate } from "redux-persist/integration/react";

import { unregister as unregisterServiceWorker } from "./registerServiceWorker";

import Spinner from "./components/Spinner";

import LogRocket from "logrocket";

import setupLogRocketReact from "logrocket-react";

if (process.env.REACT_APP_LOG_ROCKET) {
  setupLogRocketReact(LogRocket);

  LogRocket.init(process.env.REACT_APP_LOG_ROCKET, {
    network: {
      requestSanitizer: request => {
        if (request.body && request.body.indexOf("password") > -1) {
          let body = JSON.parse(request.body);

          if (body.password) body.password = null;

          request.body = JSON.stringify(body);
        }

        // request.headers["Authorization"] = null;

        return request;
      }
    }
  });
}

const { store, persistor } = storeCreator();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Authorization routes={routes}>
          <Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Spinner />
              </div>
            }
          >
            <Switch>
              {routes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </Suspense>
          <NotificationsSystem theme={theme} />
        </Authorization>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

unregisterServiceWorker();
initializeFirebase();
