import * as actions from "../../../actions/lot/detail";

const initialState = {
  loading: false,
  item: null,
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialState,
      };
    }
    case actions.GET_LOT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_LOT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_LOT_SUCCESS: {
      return {
        ...state,
        item: payload,
        loading: false,
      };
    }
    case actions.ADD_PRODUCT_ITEM_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.ADD_PRODUCT_ITEM_SUCCESS: {
      return {
        ...state,
        item: payload,
        loading: false,
      };
    }
    case actions.REMOVE_PRODUCT_ITEM_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.REMOVE_PRODUCT_ITEM_SUCCESS: {
      return {
        ...state,
        item: payload,
        loading: false,
      };
    }
    case actions.OPEN_LOT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.OPEN_LOT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.OPEN_LOT_SUCCESS: {
      return {
        ...state,
        item: payload,
        loading: false,
      };
    }
    case actions.CLOSE_LOT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CLOSE_LOT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.CLOSE_LOT_SUCCESS: {
      return {
        ...state,
        item: payload,
        loading: false,
      };
    }
    case actions.CANCEL_LOT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CANCEL_LOT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.CANCEL_LOT_SUCCESS: {
      return {
        ...state,
        item: payload,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
