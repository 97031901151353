import * as actions from "../../actions/order";

const canClearState = {
  loading: true,
  items: [],
  itemsPerOrder: [],
  errors: {},
};

const initialState = {
  ...canClearState,
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actions.CLEAR_VALUE: {
      if (payload)
        return {
          ...initialState,
        };

      return {
        ...state,
        ...canClearState,
      };
    }
    case actions.GET_ORDERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_ORDERS_FAILED: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }
    case actions.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: payload || [],
      };
    }
    case actions.GET_ITEMS_PER_ORDER: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_ITEMS_PER_ORDER_FAILED: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }
    case actions.GET_ITEMS_PER_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        itemsPerOrder: payload || [],
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
