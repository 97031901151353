import * as api from "services/api";
import _ from "lodash";
import history from "../../../../history";

export const ADD_STOCK = "[STOCK ADD] ADD_STOCK";
export const ADD_STOCK_SUCCESS = "[STOCK ADD] ADD_STOCK_SUCCESS";
export const ADD_STOCK_FAILED = "[STOCK ADD] ADD_STOCK_FAILED";

export const SET_VALUE = "[STOCK ADD] SET_VALUE";
export const CLEAR_VALUE = "[STOCK ADD] CLEAR_VALUE";

export function addClientStock() {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_STOCK
		});

		const { login, stockCreate } = getState();

		let { clientId, userId, visitsByMonth, status, displacement } = stockCreate;

		const errors = {};

		if (!clientId) {
			errors.clientId = "Campo obrigatório";
		}

		dispatch(
			setValue({
				errors
			})
		);

		if (_.keys(errors).length > 0) return dispatch({ type: ADD_STOCK_FAILED });

		let response = await api.sendPost(
			"/Stock",
			{
				clientId,
				userId,
				visitsByMonth,
				status,
				displacement
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		const result = await response.json();

		if (result.isValid) {
			dispatch({
				type: ADD_STOCK_SUCCESS
			});

			dispatch(clearValue());

			await new Promise(res =>
				setTimeout(() => {
					history.push(`/stocks/${result.model.id}`);
					res();
				}, 1000)
			);
		} else {
			dispatch({ type: ADD_STOCK_FAILED, payload: result.errors });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(payload) {
	return {
		type: CLEAR_VALUE,
		payload
	};
}
