import * as actions from "../../../actions/financialAccount/edit";

const initialFinancialAccountState = {
  name: "",
  agency: "",
  account: "",
  type: 0,
  openingBalance: 0,
  note: ""
};

const initialState = {
  loading: false,
  item: null,
  errors: {},
  ...initialFinancialAccountState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      const { item } = state;

      return {
        ...state,
        ...initialFinancialAccountState,
        item,
        name: item.name,
        agency: item.agency,
        account: item.account,
        type: item.type,
        openingBalance: item.openingBalance / 100 || 0,
        note: item.note
      };
    }
    case actions.GET_FINANCIAL_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.EDIT_FINANCIAL_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_FINANCIAL_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_FINANCIAL_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_FINANCIAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_FINANCIAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        item: payload,
        name: payload.name || "",
        agency: payload.agency || "",
        account: payload.account || "",
        type: payload.type,
        openingBalance: payload.openingBalance / 100 || 0,
        note: payload.note,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
