import * as api from "services/api";

export const GET_USER = "[USER_DETAIL] GET_USER";
export const GET_USER_SUCCESS = "[USER_DETAIL] GET_USER_SUCCESS";
export const GET_USER_FAILED = "[USER_DETAIL] GET_USER_FAILED";

export function getUser(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_USER
    });

    const { token } = getState().login;

    let response = await api.sendGet("/user/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let user = json.model;
      dispatch({
        type: GET_USER_SUCCESS,
        payload: user
      });
    } else {
      dispatch({
        type: GET_USER_FAILED
      });
    }
  };
}
