import * as api from "services/api";
import ls from "Localization";
import moment from "moment";

export const GET_PRODUCTS = "[PRODUCT REPORT] GET_PRODUCTS";
export const GET_PRODUCTS_FAILED = "[PRODUCT REPORT] GET_PRODUCTS_FAILED";
export const GET_PRODUCTS_SUCCESS = "[PRODUCT REPORT] GET_PRODUCTS_SUCCESS";

export const GET_PRODUCTS_DAILY_COUNT =
  "[PRODUCT REPORT] GET_PRODUCTS_DAILY_COUNT";
export const GET_PRODUCTS_DAILY_COUNT_FAILED =
  "[PRODUCT REPORT] GET_PRODUCTS_DAILY_COUNT_FAILED";
export const GET_PRODUCTS_DAILY_COUNT_SUCCESS =
  "[PRODUCT REPORT] GET_PRODUCTS_DAILY_COUNT_SUCCESS";

export const DELETE_PRODUCT = "[PRODUCT REPORT] DELETE_PRODUCT";
export const DELETE_PRODUCT_FAILED = "[PRODUCT REPORT] DELETE_PRODUCT_FAILED";
export const DELETE_PRODUCT_SUCCESS = "[PRODUCT REPORT] DELETE_PRODUCT_SUCCESS";
export const SET_SEARCH_TEXT = "[PRODUCT REPORT] SET_SEARCH_TEXT";

export function getProducts(category = "") {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS,
    });

    const { token } = getState().login;

    let response = await api.sendGet("/product?category=" + category, {
      Authorization: "Bearer " + token,
    });

    if (response.ok) {
      let json = await response.json();

      let products = json.model;
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: products,
      });
    } else {
      dispatch({ type: GET_PRODUCTS_FAILED });
    }
  };
}

export const GET_PRODUCTS_ITEMS = "[PRODUCT REPORT] GET_PRODUCTS_ITEMS";
export const GET_PRODUCTS_ITEMS_FAILED =
  "[PRODUCT REPORT] GET_PRODUCTS_ITEMS_FAILED";
export const GET_PRODUCTS_ITEMS_SUCCESS =
  "[PRODUCT REPORT] GET_PRODUCTS_ITEMS_SUCCESS";

export function getProductItems() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS_ITEMS,
    });

    const { token } = getState().login;

    let response = await api.sendGet("/product/item", {
      Authorization: "Bearer " + token,
    });

    if (response.ok) {
      let json = await response.json();

      let products = json.model;
      dispatch({
        type: GET_PRODUCTS_ITEMS_SUCCESS,
        payload: products,
      });
    } else {
      dispatch({ type: GET_PRODUCTS_ITEMS_FAILED });
    }
  };
}

export function getProductsDailyCount(from, to) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS_DAILY_COUNT,
    });

    const { token } = getState().login;

    if (!from) from = moment().subtract(1, "month");
    if (!to) to = moment();

    let response = await api.sendGet(
      `/product/RentalCount/Report/period/${from.format(
        "YYYY-MM-DD"
      )}/${to.format()}`,
      {
        Authorization: "Bearer " + token,
      }
    );

    if (response.ok) {
      let json = await response.json();

      let products = json.model;

      dispatch({
        type: GET_PRODUCTS_DAILY_COUNT_SUCCESS,
        payload: products,
      });
    } else {
      dispatch({ type: GET_PRODUCTS_DAILY_COUNT_FAILED });
    }
  };
}

export function deleteProduct(id, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_PRODUCT,
    });

    const { token } = getState().login;
    const { pItems } = getState().productReport;

    api
      .sendDelete("/product/" + id, {
        Authorization: "Bearer " + token,
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 409) {
          return res.json();
        }
      })
      .then((result) => {
        if (result && result.isValid) {
          dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: pItems.filter((i) => i.id !== id),
          });

          callback && callback();
        } else if (result && result.errors) {
          let errors = "";

          for (const error of result.errors) {
            const err = ls[error];

            if (err) errors += err + ",";
            else errors += error + ",";
          }

          errors = errors.substr(0, errors.length - 1);

          dispatch({ type: DELETE_PRODUCT_FAILED, payload: errors });

          callback && callback(errors);
        }
      });
  };
}
