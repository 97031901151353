import * as api from "services/api";
import ls from "Localization";

export const GET_PRODUCTCATEGORIES =
  "[PRODUCTCATEGORY REPORT] GET_PRODUCTCATEGORIES";
export const GET_PRODUCTCATEGORIES_FAILED =
  "[PRODUCTCATEGORY REPORT] GET_PRODUCTCATEGORIES_FAILED";
export const GET_PRODUCTCATEGORIES_SUCCESS =
  "[PRODUCTCATEGORY REPORT] GET_PRODUCTCATEGORIES_SUCCESS";
export const DELETE_PRODUCTCATEGORY =
  "[PRODUCTCATEGORY REPORT] DELETE_PRODUCTCATEGORY";
export const DELETE_PRODUCTCATEGORY_FAILED =
  "[PRODUCTCATEGORY REPORT] DELETE_PRODUCTCATEGORY_FAILED";
export const DELETE_PRODUCTCATEGORY_SUCCESS =
  "[PRODUCTCATEGORY REPORT] DELETE_PRODUCTCATEGORY_SUCCESS";
export const SET_SEARCH_TEXT = "[PRODUCTCATEGORY REPORT] SET_SEARCH_TEXT";
export const CLEAR_ITEMS = "[PRODUCTCATEGORY REPORT] CLEAR_ITEMS";

export function clearItems() {
  return {
    type: CLEAR_ITEMS
  };
}

export function getProductCategories() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTCATEGORIES
    });

    const { token } = getState().login;

    let response = await api.sendGet("/product/category", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let products = json.model;
      dispatch({
        type: GET_PRODUCTCATEGORIES_SUCCESS,
        payload: products
      });
    } else {
      dispatch({ type: GET_PRODUCTCATEGORIES_FAILED });
    }
  };
}

export function deleteProductCategory(id, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_PRODUCTCATEGORY
    });

    const { token } = getState().login;
    const { items } = getState().productReport;

    api
      .sendDelete("/product/category/" + id, {
        Authorization: "Bearer " + token
      })
      .then(res => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 409) {
          return res.json();
        }
      })
      .then(result => {
        if (result && result.isValid) {
          dispatch({
            type: DELETE_PRODUCTCATEGORY_SUCCESS,
            payload: items.filter(i => i.id !== id)
          });

          callback && callback();
        } else if (result && result.errors) {
          let errors = "";

          for (const error of result.errors) {
            const err = ls[error];

            if (err) errors += err + ",";
            else errors += error + ",";
          }

          errors = errors.substr(0, errors.length - 1);

          dispatch({ type: DELETE_PRODUCTCATEGORY_FAILED, payload: errors });

          callback && callback(errors);
        }
      });
  };
}