import * as actions from "../../../actions/order/detail";

const initialState = {
	loading: false,
	item: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...initialState
			};
		}
		case actions.GET_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_ORDER_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_COURTESY: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CHANGE_CHARGE_COURTESY_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_COURTESY_SUCCESS: {
			return {
				...state,
				item: { ...state.item, chargeCourtesy: payload },
				loading: false
			};
		}
		case actions.CREATE_CHANGE_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CREATE_CHANGE_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CREATE_CHANGE_ORDER_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.ADD_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.CHANGE_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.REMOVE_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.REMOVE_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.RESERVE_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.RESERVE_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.RESERVE_ORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.CONFIRM_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CONFIRM_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CONFIRM_ORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DELIVERY_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DELIVERY_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELIVERY_ORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DONE_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DONE_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DONE_ORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.CANCEL_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CANCEL_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CANCEL_ORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.PAY_ORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.PAY_ORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.PAY_ORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.ADD_ORDER_ATTENDANCE: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ADD_ORDER_ATTENDANCE_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_ORDER_ATTENDANCE_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DELETE_ATTENDANCE: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DELETE_ATTENDANCE_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELETE_ATTENDANCE_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DELETE_PAYMENT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DELETE_PAYMENT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELETE_PAYMENT_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
