import * as actions from "../../actions/sale";
import ls from "../../../Localization";

const canClearState = {
	loading: {
		create: false,
		get: false,
		getAll: false,
		print: false,
		pending: false,
		cancel: false
	},
	selectedCategory: "",
	showFinishModal: false,
	cart: [],
	clientId: "",
	showAddClient: false,
	name: "",
	lastName: "",
	document: "",
	email: "",
	phoneNumber: "",
	paymentMethodId: null,
	discount: 0,
	discountType: -1,
	value: 0,
	showPrintSale: false,
	hasPendingSales: false,
	note: "",
	printSale: null,
	items: [],
	pages: null,
	transactions: [],
	pendingItems: [],
	showSelectClient: false,
	showSelectOrder: false,
	showPaymentModal: false,
	item: null,
	availableCredit: 0,
	products: [],
	errors: {}
};

const initialState = {
	filter: [],
	...canClearState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			state = {
				...state,
				...canClearState,
				item: payload ? null : state.item,
				cart:
					state.item && !payload
						? state.item.products.map(c => ({ ...c }))
						: [],
				transactions:
					state.item && !payload
						? state.item.transactions
						: canClearState.transactions,
				clientId:
					state.item && !payload ? state.item.clientId : canClearState.clientId
			};

			return state;
		}
		case actions.PRINT_SALE: {
			return {
				...state,
				loading: {
					...state.loading,
					print: true
				},
				errors: initialState.errors
			};
		}
		case actions.PRINT_SALE_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					print: false
				},
				showPrintSale: false,
				errors: initialState.errors
			};
		}
		case actions.PRINT_SALE_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					print: false
				},
				errors: { print: ls[payload[0]] }
			};
		}
		case actions.CANCEL_SALE: {
			return {
				...state,
				loading: {
					...state.loading,
					cancel: true
				},
				errors: initialState.errors
			};
		}
		case actions.CANCEL_SALE_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					cancel: false
				},
				errors: initialState.errors
			};
		}
		case actions.CANCEL_SALE_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					cancel: false
				},
				errors: { cancel: ls[payload[0]] }
			};
		}
		case actions.CREATE_SALE: {
			return {
				...state,
				loading: {
					...state.loading,
					create: true
				}
			};
		}
		case actions.CREATE_SALE_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					create: false
				}
			};
		}
		case actions.CREATE_SALE_SUCCESS: {
			return {
				...state,
				...canClearState,
				showPrintSale: true,
				printSale: payload,
				loading: {
					...state.loading,
					create: false
				}
			};
		}
		case actions.GET_SALE: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				}
			};
		}
		case actions.GET_SALE_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.GET_SALE_SUCCESS: {
			return {
				...state,
				...canClearState,
				item: payload,
				cart: payload.products.map(c => ({ ...c })),
				transactions: payload.transactions,
				clientId: payload.clientId,
				note: payload.note
			};
		}
		case actions.GET_SALES_PRODUCT: {
			return {
				...state,
				loading: {
					...state.loading,
					getProducts: true
				}
			};
		}
		case actions.GET_SALES_PRODUCT_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getProducts: false
				},
				errors: payload
			};
		}
		case actions.GET_SALES_PRODUCT_SUCCESS: {
			return {
				...state,
				products: payload,
				loading: {
					...state.loading,
					getProducts: false
				}
			};
		}
		case actions.GET_AVAILABLE_CREDIT: {
			return {
				...state,
				availableCredit: 0,
				loading: {
					...state.loading,
					getAvailableCredit: true
				}
			};
		}
		case actions.GET_AVAILABLE_CREDIT_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getAvailableCredit: false
				},
				errors: payload
			};
		}
		case actions.GET_AVAILABLE_CREDIT_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					getAvailableCredit: false
				},
				availableCredit: payload
			};
		}

		case actions.GET_PENDING_SALE_STATUS: {
			return {
				...state,
				hasPendingSales: false,
				loading: {
					...state.loading,
					getPendingSalesStatus: true
				}
			};
		}
		case actions.GET_PENDING_SALE_STATUS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getPendingSalesStatus: false
				},
				errors: payload
			};
		}
		case actions.GET_PENDING_SALE_STATUS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					getPendingSalesStatus: false
				},
				hasPendingSales: payload
			};
		}
		case actions.GET_SALES: {
			return {
				...state,
				loading: {
					...state.loading,
					getAll: true
				}
			};
		}
		case actions.GET_SALES_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getAll: false
				},
				errors: payload
			};
		}
		case actions.GET_SALES_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					getAll: false
				},
				items: payload || []
			};
		}
		case actions.GET_PENDING_SALES: {
			return {
				...state,
				loading: {
					...state.loading,
					pending: true
				}
			};
		}
		case actions.GET_PENDING_SALES_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					pending: false
				},
				errors: payload
			};
		}
		case actions.GET_PENDING_SALES_SUCCESS: {
			return {
				...state,
				...canClearState,
				pendingItems: payload || []
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
