import * as actions from "../../../actions/laundry/edit";

const initialOrderState = {
  code: "",
  note: "",
  clientId: "",
  addressId: "",
  deliveryType: 0,
  collectionDate: "",
  returnDate: "",
  transactionId: "",
  status: 0,
  item: null,
  errors: {}
};

const initialState = {
  loading: false,
  ...initialOrderState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      if (state.item) {
        return {
          ...state,
          ...initialOrderState,
          ...state.item,
          item: state.item
        };
      } else {
        return {
          ...state,
          ...initialOrderState
        };
      }
    }
    case actions.GET_LAUNDRY_ORDER: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.EDIT_LAUNDRY_ORDER: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_LAUNDRY_ORDER_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_LAUNDRY_ORDER_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_LAUNDRY_ORDER_SUCCESS: {
      return {
        ...state,
        ...initialOrderState,
        loading: false
      };
    }
    case actions.GET_LAUNDRY_ORDER_SUCCESS: {
      return {
        ...state,
        item: payload,
        ...payload,
        note: payload.note || "",
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
