import * as actions from "../../actions/cashier";

import ls from "Localization";

const canClearState = {
	showWithdrawModal: false,
	showAddCreditModal: false,
	showPrintCredit: false,
	printCredit: null,
	loading: {
		get: false,
		last: false,
		create: false, //open
		close: false,
		withdraw: false,
		credit: false,
		printCredit: false
	},
	showCloseCashierModal: false,
	openValue: 0,
	differenceNote: "",
	closeValue: 0,
	errors: {}
};

const initialState = {
	...canClearState,
	local: "matriz",
	item: null,
	last: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...(payload ? {} : canClearState)
			};
		}
		case actions.CREATE_CASHIER: {
			return {
				...state,
				loading: {
					...state.loading,
					create: true
				}
			};
		}
		case actions.GET_LAST_CASHIER: {
			return {
				...state,
				loading: {
					...state.loading,
					last: true
				}
			};
		}
		case actions.GET_CASHIER: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				}
			};
		}
		case actions.CREATE_CASHIER_FAILED: {
			return {
				...state,
				errors: payload,
				loading: {
					...state.loading,
					create: false
				}
			};
		}
		case actions.GET_LAST_CASHIER_FAILED: {
			return {
				...state,
				errors: payload,
				loading: {
					...state.loading,
					last: false
				}
			};
		}
		case actions.GET_CASHIER_FAILED: {
			return {
				...state,
				errors: payload,
				loading: {
					...state.loading,
					get: false
				}
			};
		}
		case actions.CREATE_CASHIER_SUCCESS: {
			return {
				...state,
				item: payload,
				openValue: 0,
				loading: {
					...state.loading,
					create: false
				}
			};
		}
		case actions.GET_CASHIER_SUCCESS: {
			return {
				...state,
				item: payload,
				openValue: 0,
				loading: {
					...state.loading,
					get: false
				}
			};
		}
		case actions.GET_LAST_CASHIER_SUCCESS: {
			return {
				...state,
				last: payload,
				loading: {
					...state.loading,
					last: false
				}
			};
		}
		case actions.CLOSE_CASHIER: {
			return {
				...state,
				loading: {
					...state.loading,
					close: true
				}
			};
		}
		case actions.CLOSE_CASHIER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					close: false
				}
			};
		}
		case actions.CLOSE_CASHIER_SUCCESS: {
			return {
				...state,
				...initialState
			};
		}
		case actions.CASHIER_WITHDRAW: {
			return {
				...state,
				loading: {
					...state.loading,
					withdraw: true
				}
			};
		}
		case actions.CASHIER_WITHDRAW_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					withdraw: false
				}
			};
		}
		case actions.CASHIER_WITHDRAW_SUCCESS: {
			return {
				...state,
				showWithdrawModal: false,
				item: { ...state.item, withdraws: [...state.item.withdraws, payload] },
				loading: {
					...state.loading,
					withdraw: false
				}
			};
		}
		case actions.ADD_CREDIT: {
			return {
				...state,
				loading: {
					...state.loading,
					credit: true
				}
			};
		}
		case actions.ADD_CREDIT_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					credit: false
				}
			};
		}
		case actions.ADD_CREDIT_SUCCESS: {
			return {
				...state,
				showAddCreditModal: false,
				printCredit: payload,
				showPrintCredit: true,
				loading: {
					...state.loading,
					credit: false
				}
			};
		}
		case actions.PRINT_CREDIT: {
			return {
				...state,
				loading: {
					...state.loading,
					printCredit: true
				}
			};
		}
		case actions.PRINT_CREDIT_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					printCredit: false
				},
				errors: { print: ls[payload[0]] }
			};
		}
		case actions.PRINT_CREDIT_SUCCESS: {
			return {
				...state,
				printCredit: null,
				showPrintCredit: false,
				loading: {
					...state.loading,
					printCredit: false
				}
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
