import * as actions from "../../../actions/product/detail";

const initialState = {
	loading: false,
	item: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_PRODUCT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_PRODUCT_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
