import * as actions from "../../../actions/product/edit";

const initialProdState = {
	status: "1",
	code: "",
	name: "",
	description: "",
	buyPrice: 0,
	rentalPrice: 0,
	rentalPrices: {},
	sellPrice: 0,
	stockPrice: 0,
	maxLocation: 0,
	productCategoryId: "",
	baseAmount: "",
	baseYear: "",
	weight: 1,
	lotLimit: 0,
	type: "0",
	image: ""
};

const initialState = {
	loading: false,
	item: null,
	errors: {},
	...initialProdState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...initialProdState,
				item: null
			};
		}
		case actions.GET_PRODUCT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.EDIT_PRODUCT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_PRODUCT_SUCCESS: {
			return {
				...state,
				item: payload,
				code: payload.code,
				name: payload.name,
				status: payload.status,
				description: payload.description,
				buyPrice: payload.buyPrice / 100,
				rentalPrice: payload.rentalPrice / 100,
				rentalPrices: payload.rentalPrices || {},
				sellPrice: payload.sellPrice / 100,
				stockPrice: payload.stockPrice / 100,
				maxLocation: payload.maxLocation,
				productCategoryId: payload.productCategoryId || "",
				lotLimit: payload.lotLimit || 0,
				image: payload.image,
				weight: payload.weight,
				type: payload.type + "",
				baseAmount: payload.baseAmount || "",
				baseYear: payload.baseYear || "",
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
