import * as actions from "../../../actions/clientStock/detail";

const initialState = {
	loading: false,
	item: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...initialState
			};
		}
		case actions.GET_STOCK: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CANCEL_STOCK: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CANCEL_STOCK_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CANCEL_STOCK_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.GET_STOCK_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_STOCK_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.ADD_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.ADD_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.CHANGE_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.REMOVE_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.REMOVE_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_ITEM_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_ITEM_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_SUCCESS: {
			return {
				...state,
				item: { ...payload, products: payload.products.filter(c => c.product) },
				loading: false
			};
		}
		case actions.DONE_STOCK: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DONE_STOCK_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DONE_STOCK_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
