import _ from "lodash";
import * as api from "services/api";
import history from "../../../../../history";

export const EDIT_SUB_CHART_OF_ACCOUNT =
  "[SUB_CHART_OF_ACCOUNT EDIT] EDIT_SUB_CHART_OF_ACCOUNT";
export const EDIT_SUB_CHART_OF_ACCOUNT_SUCCESS =
  "[SUB_CHART_OF_ACCOUNT EDIT] EDIT_SUB_CHART_OF_ACCOUNT_SUCCESS";
export const EDIT_SUB_CHART_OF_ACCOUNT_FAILED =
  "[SUB_CHART_OF_ACCOUNT EDIT] EDIT_SUB_CHART_OF_ACCOUNT_FAILED";

export const GET_SUB_CHART_OF_ACCOUNT =
  "[SUB_CHART_OF_ACCOUNT EDIT] GET_SUB_CHART_OF_ACCOUNT";
export const GET_SUB_CHART_OF_ACCOUNT_SUCCESS =
  "[SUB_CHART_OF_ACCOUNT EDIT] GET_SUB_CHART_OF_ACCOUNT_SUCCESS";
export const GET_SUB_CHART_OF_ACCOUNT_FAILED =
  "[SUB_CHART_OF_ACCOUNT EDIT] GET_SUB_CHART_OF_ACCOUNT_FAILED";

export const SET_VALUE = "[SUB_CHART_OF_ACCOUNT EDIT] SET_VALUE";
export const CLEAR_VALUE = "[SUB_CHART_OF_ACCOUNT EDIT] CLEAR_VALUE";

export function getSubChartOfAccount(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SUB_CHART_OF_ACCOUNT
    });

    const { token } = getState().login;

    let response = await api.sendGet("/ChartOfAccounts/Sub/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let item = json.model;
      dispatch({
        type: GET_SUB_CHART_OF_ACCOUNT_SUCCESS,
        payload: item
      });
    } else {
      dispatch({ type: GET_SUB_CHART_OF_ACCOUNT_FAILED });
    }
  };
}

export function editSubChartOfAccount() {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_SUB_CHART_OF_ACCOUNT
    });

    const { login, subChartOfAccountEdit } = getState();

    let {
      name,
      code,
      description,
      chartOfAccountsId,
      item
    } = subChartOfAccountEdit;

    const errors = {};

    if (!name) errors.name = "Informe um nome";
    if (!code) errors.code = "Informe um código";

    dispatch(setValue({ errors }));

    if (_.keys(errors).length > 0)
      return dispatch({ type: EDIT_SUB_CHART_OF_ACCOUNT_FAILED });

    let response = await api.sendPut(
      "/ChartOfAccounts/" + chartOfAccountsId + "/Sub/" + item.id,
      {
        ...item,
        name,
        code,
        description,
        chartOfAccountsId
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      dispatch({
        type: EDIT_SUB_CHART_OF_ACCOUNT_SUCCESS
      });

      dispatch(clearValue());

      history.push("/ChartOfAccounts");
    } else {
      dispatch({ type: EDIT_SUB_CHART_OF_ACCOUNT_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
