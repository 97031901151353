import * as actions from "../../../actions/clientStock/report";

const canClearState = {
  loading: false,
  items: [],
  filter: [],
  pages: 0,
  pendingItems: []
};

const initialState = {
  ...canClearState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      if (payload)
        return {
          ...initialState
        };

      return {
        ...state,
        ...canClearState
      };
    }
    case actions.GET_STOCK_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_STOCK_SUCCESS: {
      return {
        ...state,
        items: payload ? payload : [],
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
