import * as actions from "../../../actions/cashier/report";

const canClearState = {
  loading: false,
  items: [],
  errors: {}
};

const initialState = {
  ...canClearState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...canClearState
      };
    }
    case actions.GET_CASHIERS: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_CASHIERS_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_CASHIERS_SUCCESS: {
      return {
        ...state,
        items: payload,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
