import * as api from "services/api";

export const GET_LOT = "[LOT DETAIL] GET_LOT";
export const GET_LOT_SUCCESS = "[LOT DETAIL] GET_LOT_SUCCESS";
export const GET_LOT_FAILED = "[LOT DETAIL] GET_LOT_FAILED";

export const CLOSE_LOT = "[LOT DETAIL] CLOSE_LOT";
export const CLOSE_LOT_SUCCESS = "[LOT DETAIL] CLOSE_LOT_SUCCESS";
export const CLOSE_LOT_FAILED = "[LOT DETAIL] CLOSE_LOT_FAILED";

export const OPEN_LOT = "[LOT DETAIL] OPEN_LOT";
export const OPEN_LOT_SUCCESS = "[LOT DETAIL] OPEN_LOT_SUCCESS";
export const OPEN_LOT_FAILED = "[LOT DETAIL] OPEN_LOT_FAILED";

export const CANCEL_LOT = "[LOT DETAIL] CANCEL_LOT";
export const CANCEL_LOT_SUCCESS = "[LOT DETAIL] CANCEL_LOT_SUCCESS";
export const CANCEL_LOT_FAILED = "[LOT DETAIL] CANCEL_LOT_FAILED";

export const GET_PRODUCT = "[LOT DETAIL] GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "[LOT DETAIL] GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "[LOT DETAIL] GET_PRODUCT_FAILED";

export const ADD_PRODUCT_ITEM = "[LOT DETAIL] ADD_PRODUCT_ITEM";
export const ADD_PRODUCT_ITEM_SUCCESS = "[LOT DETAIL] ADD_PRODUCT_ITEM_SUCCESS";
export const ADD_PRODUCT_ITEM_FAILED = "[LOT DETAIL] ADD_PRODUCT_ITEM_FAILED";

export const REMOVE_PRODUCT_ITEM = "[LOT DETAIL] REMOVE_PRODUCT_ITEM";
export const REMOVE_PRODUCT_ITEM_SUCCESS =
  "[LOT DETAIL] REMOVE_PRODUCT_ITEM_SUCCESS";
export const REMOVE_PRODUCT_ITEM_FAILED =
  "[LOT DETAIL] REMOVE_PRODUCT_ITEM_FAILED";

export const SET_VALUE = "[LOT DETAIL] SET_VALUE";
export const CLEAR_VALUE = "[LOT DETAIL] CLEAR_VALUE";

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload,
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE,
  };
}

export function getLot(id, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LOT,
    });

    const { token } = getState().login;

    let response = await api.sendGet("/lot/" + id, {
      Authorization: "Bearer " + token,
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_LOT_SUCCESS,
        payload: json.model,
      });

      callback && callback(null, json.model);
    } else {
      dispatch({ type: GET_LOT_FAILED });
    }
  };
}

export const PRINT_LOT = "[LOT DETAIL] PRINT_LOT";
export const PRINT_LOT_SUCCESS = "[LOT DETAIL] PRINT_LOT_SUCCESS";
export const PRINT_LOT_FAILED = "[LOT DETAIL] PRINT_LOT_FAILED";

export function printLot(id, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: PRINT_LOT,
    });

    const { token } = getState().login;

    let response = await api.sendPost("/lot/" + id + "/Print", null, {
      Authorization: "Bearer " + token,
    });

    if (response.ok) {
      dispatch({
        type: PRINT_LOT_SUCCESS,
      });

      callback && callback();
    } else {
      let json = await response.json();
      dispatch({ type: PRINT_LOT_FAILED, payload: json.errors });
    }
  };
}

export function reload() {
  return (dispatch, getState) => {
    const { lotDetail } = getState();
    return dispatch(getLot(lotDetail.item.id));
  };
}

export function openLot(callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: OPEN_LOT,
    });

    const { login, lotDetail } = getState();

    let response = await api.sendPut(
      "/lot/" + lotDetail.item.id + "/Open",
      null,
      {
        Authorization: "Bearer " + login.token,
      }
    );

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: OPEN_LOT_SUCCESS,
        payload: json.model,
      });

      callback();
    } else {
      dispatch({ type: OPEN_LOT_FAILED });
      callback(
        "Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
      );
    }
  };
}

export function closeLot(callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: CLOSE_LOT,
    });

    const { login, lotDetail } = getState();

    let response = await api.sendPut(
      "/lot/" + lotDetail.item.id + "/close",
      null,
      {
        Authorization: "Bearer " + login.token,
      }
    );

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: CLOSE_LOT_SUCCESS,
        payload: json.model,
      });

      callback();
    } else {
      dispatch({ type: CLOSE_LOT_FAILED });
      callback(
        "Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
      );
    }
  };
}

export function cancelLot(callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: CANCEL_LOT,
    });

    const { login, lotDetail } = getState();

    let response = await api.sendPut(
      "/lot/" + lotDetail.item.id + "/Cancel",
      null,
      {
        Authorization: "Bearer " + login.token,
      }
    );

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: CANCEL_LOT_SUCCESS,
        payload: json.model,
      });

      callback();
    } else {
      dispatch({ type: CANCEL_LOT_FAILED });
      callback(
        "Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
      );
    }
  };
}

export function searchProduct(code, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCT,
    });

    const { login } = getState();

    let response = await api.sendGet("/product/code/" + code, {
      Authorization: "Bearer " + login.token,
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: json.model,
      });

      callback(null, json.model);
    } else {
      dispatch({ type: GET_PRODUCT_FAILED });
      callback(
        "Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
      );
    }
  };
}

export function addProductItems(model, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_PRODUCT_ITEM,
    });

    const { login, lotDetail } = getState();

    let response = await api.sendPost(
      "/lot/" + lotDetail.item.id + "/Items",
      model,
      {
        Authorization: "Bearer " + login.token,
      }
    );

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: ADD_PRODUCT_ITEM_SUCCESS,
        payload: json.model,
      });

      callback();
    } else {
      dispatch({ type: ADD_PRODUCT_ITEM_FAILED });
      callback(
        "Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
      );
    }
  };
}

export function removeItem(item, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_PRODUCT_ITEM,
    });

    const { login, lotDetail } = getState();

    let response = await api.sendDelete(
      "/lot/" + lotDetail.item.id + "/Item/" + item.id,
      {
        Authorization: "Bearer " + login.token,
      }
    );

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: REMOVE_PRODUCT_ITEM_SUCCESS,
        payload: json.model,
      });

      callback();
    } else {
      dispatch({ type: REMOVE_PRODUCT_ITEM_FAILED });
      callback(
        "Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
      );
    }
  };
}
