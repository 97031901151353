import * as actions from "../../../actions/product/report";

const initialState = {
  loading: false,
  items: [],
  productItems: [],
  dailyItems: [],
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.GET_PRODUCTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_PRODUCTS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        items: payload ? payload : [],
        loading: false,
      };
    }
    case actions.GET_PRODUCTS_ITEMS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_PRODUCTS_ITEMS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_PRODUCTS_ITEMS_SUCCESS: {
      return {
        ...state,
        productItems: payload ? payload : [],
        loading: false,
      };
    }
    case actions.GET_PRODUCTS_DAILY_COUNT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_PRODUCTS_DAILY_COUNT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_PRODUCTS_DAILY_COUNT_SUCCESS: {
      return {
        ...state,
        dailyItems: payload ? payload : [],
        loading: false,
      };
    }
    case actions.DELETE_PRODUCT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.DELETE_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        items: payload ? payload : [],
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
