import * as actions from "../../../actions/lot/report";

const canClearState = {
  loading: false,
  items: [],
  filter: [],
  pages: 0,
};

const initialState = {
  ...canClearState,
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actions.CLEAR_VALUE: {
      if (payload)
        return {
          ...initialState,
        };

      return {
        ...state,
        ...canClearState,
      };
    }
    case actions.GET_LOTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_LOTS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_LOTS_SUCCESS: {
      return {
        ...state,
        items: payload ? payload : [],
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
