import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Spinner(props) {
	const [completed, setCompleted] = useState(0);

	const { classes, ...rest } = props;

	useEffect(() => {
		const progress = () => {
			setCompleted(prev => (prev >= 100 ? 0 : prev + 1));
		};

		let timer = setInterval(progress, 20);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return <CircularProgress {...rest} variant="determinate" value={completed} />;
}
