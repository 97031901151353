import * as actions from "../../../actions/client/detail";

const initialState = {
  loading: false,
  item: null,
  credits : []
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.GET_CLIENT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_CLIENT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_CLIENT_SUCCESS: {
      return {
        ...state,
        item: payload,
        loading: false
      };
    }
    case actions.GET_CLIENT_CREDIT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_CLIENT_CREDIT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_CLIENT_CREDIT_SUCCESS: {
      return {
        ...state,
        credits: payload,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
