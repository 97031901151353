import * as actions from "../../../actions/client/create";

const initialClientState = {
  status: "",
  type: 0,
  name: "",
  nameError: "",
  lastName: "",
  email: "",
  vip: false,
  document: "",
  zipCode: "",
  street: "",
  number: "",
  phone: "",
  complement: "",
  neighborhood: "",
  city: "",
  state: "",
  country: "",
  nationality: "",
  gender: 0,
  isOwner: false,
  errors: {}
};

const initialState = {
  loading: false,
  ...initialClientState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialClientState
      };
    }
    case actions.ADD_CLIENT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_CLIENT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_CLIENT_SUCCESS: {
      return {
        ...state,
        ...initialClientState,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
