import * as api from "services/api";


export const GET_BUDGETS = "[BUDGET] GET_BUDGETS";
export const GET_BUDGETS_FAILED = "[BUDGET] GET_BUDGETS_FAILED";
export const GET_BUDGETS_SUCCESS = "[BUDGET] GET_BUDGETS_SUCCESS";

export const SET_VALUE = "[BUDGET] SET_VALUE";
export const CLEAR_VALUE = "[BUDGET] CLEAR_VALUE";

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(payload) {
	return {
		type: CLEAR_VALUE,
		payload
	};
}

export function getBudgets(
	startDate,
	endDate,
	skip,
	take,
	filter,
	sort,
	desc,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BUDGETS
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/Budget/v2?startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
				"&filters=null"}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUDGETS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_BUDGETS_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_BUDGETS_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}
