import * as api from "services/api";

export const GET_ORDERS = "[ORDER] GET_ORDERS";
export const GET_ORDERS_FAILED = "[ORDER] GET_ORDERS_FAILED";
export const GET_ORDERS_SUCCESS = "[ORDER] GET_ORDERS_SUCCESS";

export const GET_ITEMS_PER_ORDER = "[ORDER] GET_ITEMS_PER_ORDER";
export const GET_ITEMS_PER_ORDER_FAILED = "[ORDER] GET_ITEMS_PER_ORDER_FAILED";
export const GET_ITEMS_PER_ORDER_SUCCESS =
  "[ORDER] GET_ITEMS_PER_ORDER_SUCCESS";

export const SET_VALUE = "[ORDER] SET_VALUE";
export const CLEAR_VALUE = "[ORDER] CLEAR_VALUE";

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload,
  };
}

export function clearValue(payload) {
  return {
    type: CLEAR_VALUE,
    payload,
  };
}

export function getOrders(
  startDate,
  endDate,
  skip,
  take,
  filter,
  sort,
  desc,
  callback
) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ORDERS,
    });

    const { login } = getState();

    const { token } = login;

    let response = await api.sendGet(
      `/Order/v2?startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
        "&filters=null"}`,
      {
        Authorization: "Bearer " + token,
      }
    );

    if (response.ok) {
      let result = await response.json();

      if (result.isValid) {
        dispatch({
          type: GET_ORDERS_SUCCESS,
          payload: result.model,
        });

        if (result.params)
          dispatch(
            setValue({
              pages: Math.ceil(result.params.count / take),
              ...result.params,
            })
          );

        callback && callback(null, result.model);
      } else {
        dispatch({ type: GET_ORDERS_FAILED, payload: result.errors });
        callback && callback(result.errors, result.model);
      }
    } else {
      dispatch({ type: GET_ORDERS_FAILED });

      callback && callback("Ocorreu um erro ao tentar criar a venda");
    }
  };
}

export function getItemsPerOrder(startDate, endDate, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ITEMS_PER_ORDER,
    });

    const { login } = getState();

    const { token } = login;

    let response = await api.sendGet(
      `/Order/Info/Items?startDate=${startDate}&endDate=${endDate}`,
      {
        Authorization: "Bearer " + token,
      }
    );

    if (response.ok) {
      let result = await response.json();

      if (result.isValid) {
        dispatch({
          type: GET_ITEMS_PER_ORDER_SUCCESS,
          payload: result.model,
        });

        callback && callback(null, result.model);
      } else {
        dispatch({ type: GET_ITEMS_PER_ORDER_FAILED, payload: result.errors });
        callback && callback(result.errors, result.model);
      }
    } else {
      dispatch({ type: GET_ITEMS_PER_ORDER_FAILED });

      callback && callback("Ocorreu um erro ao tentar criar a venda");
    }
  };
}
