import moment from "moment";
import * as actions from "../../../actions/budget/create";

const initialBudgetState = {
  status: "",
  clientId: "",
  city: "",
  people: 1,
  moreInfo: "",
  phoneNumber: "",
  startDate: moment.utc(),
  endDate: moment.utc().add(1, "day"),
  deliveryType: 0,
  deliveryAddress: "",
  mount: false,
  discount: false,
  errors: {}
};

const initialState = {
  loading: false,
  ...initialBudgetState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialBudgetState
      };
    }
    case actions.ADD_BUDGET: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_BUDGET_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_BUDGET_SUCCESS: {
      return {
        ...state,
        ...initialBudgetState,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
