import * as api from "services/api";

export const GET_ORDER = "[ORDER DETAIL] GET_ORDER";
export const GET_ORDER_SUCCESS = "[ORDER DETAIL] GET_ORDER_SUCCESS";
export const GET_ORDER_FAILED = "[ORDER DETAIL] GET_ORDER_FAILED";

export const CONFIRM_ORDER = "[ORDER DETAIL] CONFIRM_ORDER";
export const CONFIRM_ORDER_SUCCESS = "[ORDER DETAIL] CONFIRM_ORDER_SUCCESS";
export const CONFIRM_ORDER_FAILED = "[ORDER DETAIL] CONFIRM_ORDER_FAILED";

export const RESERVE_ORDER = "[ORDER DETAIL] RESERVE_ORDER";
export const RESERVE_ORDER_SUCCESS = "[ORDER DETAIL] RESERVE_ORDER_SUCCESS";
export const RESERVE_ORDER_FAILED = "[ORDER DETAIL] RESERVE_ORDER_FAILED";

export const CONVERT_ORDER = "[ORDER DETAIL] CONVERT_ORDER";
export const CONVERT_ORDER_SUCCESS = "[ORDER DETAIL] CONVERT_ORDER_SUCCESS";
export const CONVERT_ORDER_FAILED = "[ORDER DETAIL] CONVERT_ORDER_FAILED";

export const PAY_ORDER = "[ORDER DETAIL] PAY_ORDER";
export const PAY_ORDER_SUCCESS = "[ORDER DETAIL] PAY_ORDER_SUCCESS";
export const PAY_ORDER_FAILED = "[ORDER DETAIL] PAY_ORDER_FAILED";

export const DELETE_PAYMENT = "[ORDER DETAIL] DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS = "[ORDER DETAIL] DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAILED = "[ORDER DETAIL] DELETE_PAYMENT_FAILED";

export const CHANGE_CHARGE = "[ORDER DETAIL] CHANGE_CHARGE";
export const CHANGE_CHARGE_SUCCESS = "[ORDER DETAIL] CHANGE_CHARGE_SUCCESS";
export const CHANGE_CHARGE_FAILED = "[ORDER DETAIL] CHANGE_CHARGE_FAILED";

export const CANCEL_ORDER = "[ORDER DETAIL] CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "[ORDER DETAIL] CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILED = "[ORDER DETAIL] CANCEL_ORDER_FAILED";

export const ADD_ORDER_ATTENDANCE = "[ORDER DETAIL] ADD_ORDER_ATTENDANCE";
export const ADD_ORDER_ATTENDANCE_SUCCESS =
	"[ORDER DETAIL] ADD_ORDER_ATTENDANCE_SUCCESS";
export const ADD_ORDER_ATTENDANCE_FAILED =
	"[ORDER DETAIL] ADD_ORDER_ATTENDANCE_FAILED";

export const GET_PRODUCT = "[ORDER DETAIL] GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "[ORDER DETAIL] GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "[ORDER DETAIL] GET_PRODUCT_FAILED";

export const CHANGE_PRODUCT_ITEM_STATUS =
	"[ORDER DETAIL] CHANGE_PRODUCT_ITEM_STATUS";
export const CHANGE_PRODUCT_ITEM_STATUS_SUCCESS =
	"[ORDER DETAIL] CHANGE_PRODUCT_ITEM_STATUS_SUCCESS";
export const CHANGE_PRODUCT_ITEM_STATUS_FAILED =
	"[ORDER DETAIL] CHANGE_PRODUCT_ITEM_STATUS_FAILED";

export const ADD_PRODUCT = "[ORDER DETAIL] ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "[ORDER DETAIL] ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILED = "[ORDER DETAIL] ADD_PRODUCT_FAILED";

export const ADD_PRODUCT_ITEM = "[ORDER DETAIL] ADD_PRODUCT_ITEM";
export const ADD_PRODUCT_ITEM_SUCCESS =
	"[ORDER DETAIL] ADD_PRODUCT_ITEM_SUCCESS";
export const ADD_PRODUCT_ITEM_FAILED = "[ORDER DETAIL] ADD_PRODUCT_ITEM_FAILED";

export const REMOVE_PRODUCT_ITEM = "[ORDER DETAIL] REMOVE_PRODUCT_ITEM";
export const REMOVE_PRODUCT_ITEM_SUCCESS =
	"[ORDER DETAIL] REMOVE_PRODUCT_ITEM_SUCCESS";
export const REMOVE_PRODUCT_ITEM_FAILED =
	"[ORDER DETAIL] REMOVE_PRODUCT_ITEM_FAILED";

export const CHANGE_PRODUCT_ITEM = "[ORDER DETAIL] CHANGE_PRODUCT_ITEM";
export const CHANGE_PRODUCT_ITEM_SUCCESS =
	"[ORDER DETAIL] CHANGE_PRODUCT_ITEM_SUCCESS";
export const CHANGE_PRODUCT_ITEM_FAILED =
	"[ORDER DETAIL] CHANGE_PRODUCT_ITEM_FAILED";

export const EDIT_PRODUCT = "[ORDER DETAIL] EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "[ORDER DETAIL] EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "[ORDER DETAIL] EDIT_PRODUCT_FAILED";

export const CHANGE_CHARGE_COURTESY = "[ORDER DETAIL] CHANGE_CHARGE_COURTESY";
export const CHANGE_CHARGE_COURTESY_SUCCESS =
	"[ORDER DETAIL] CHANGE_CHARGE_COURTESY_SUCCESS";
export const CHANGE_CHARGE_COURTESY_FAILED =
	"[ORDER DETAIL] CHANGE_CHARGE_COURTESY_FAILED";

export const CREATE_CHANGE_ORDER = "[ORDER DETAIL] CREATE_CHANGE_ORDER";
export const CREATE_CHANGE_ORDER_SUCCESS =
	"[ORDER DETAIL] CREATE_CHANGE_ORDER_SUCCESS";
export const CREATE_CHANGE_ORDER_FAILED =
	"[ORDER DETAIL] CREATE_CHANGE_ORDER_FAILED";

export const EDIT_PRODUCT_ITEM = "[ORDER DETAIL] EDIT_PRODUCT_ITEM";
export const EDIT_PRODUCT_ITEM_SUCCESS =
	"[ORDER DETAIL] EDIT_PRODUCT_ITEM_SUCCESS";
export const EDIT_PRODUCT_ITEM_FAILED =
	"[ORDER DETAIL] EDIT_PRODUCT_ITEM_FAILED";

export const DELETE_PRODUCT = "[ORDER DETAIL] DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "[ORDER DETAIL] DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "[ORDER DETAIL] DELETE_PRODUCT_FAILED";

export const DELETE_ATTENDANCE = "[ORDER DETAIL] DELETE_ATTENDANCE";
export const DELETE_ATTENDANCE_SUCCESS =
	"[ORDER DETAIL] DELETE_ATTENDANCE_SUCCESS";
export const DELETE_ATTENDANCE_FAILED =
	"[ORDER DETAIL] DELETE_ATTENDANCE_FAILED";

export const DELIVERY_ORDER = "[ORDER DETAIL] DELIVERY_ORDER";
export const DELIVERY_ORDER_SUCCESS = "[ORDER DETAIL] DELIVERY_ORDER_SUCCESS";
export const DELIVERY_ORDER_FAILED = "[ORDER DETAIL] DELIVERY_ORDER_FAILED";

export const DONE_ORDER = "[ORDER DETAIL] DONE_ORDER";
export const DONE_ORDER_SUCCESS = "[ORDER DETAIL] DONE_ORDER_SUCCESS";
export const DONE_ORDER_FAILED = "[ORDER DETAIL] DONE_ORDER_FAILED";

export const SET_VALUE = "[ORDER DETAIL] SET_VALUE";
export const CLEAR_VALUE = "[ORDER DETAIL] CLEAR_VALUE";

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}

export function getOrder(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ORDER
		});

		const { token } = getState().login;

		let response = await api.sendGet("/order/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_ORDER_SUCCESS,
				payload: json.model
			});

			callback && callback(null, json.model);
		} else {
			dispatch({ type: GET_ORDER_FAILED });
		}
	};
}

export function reload() {
	return (dispatch, getState) => {
		const { orderDetail } = getState();
		return dispatch(getOrder(orderDetail.item.id));
	};
}

export function reserveOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: RESERVE_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Reserve",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: RESERVE_ORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: RESERVE_ORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function confirmOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CONFIRM_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Confirm",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CONFIRM_ORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: CONFIRM_ORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function convertOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CONVERT_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Convert",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let result = await response.json();

			dispatch({
				type: CONVERT_ORDER_SUCCESS,
				payload: result.model
			});

			callback && callback(null, result.model);
		} else {
			dispatch({ type: CONVERT_ORDER_FAILED });
			callback &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}

export function deliveryOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELIVERY_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Delivery",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DELIVERY_ORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DELIVERY_ORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function doneOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DONE_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Finished",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DONE_ORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DONE_ORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function createChangeOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CHANGE_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPost(
			"/order/" + orderDetail.item.id + "/Change/Create",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CREATE_CHANGE_ORDER_SUCCESS,
				payload: json.model
			});

			callback && callback(null, json.model);
		} else {
			dispatch({ type: CREATE_CHANGE_ORDER_FAILED });
			callback &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}

export function changeChargeCourtesy(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_CHARGE_COURTESY
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Courtesy/Toggle",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CHANGE_CHARGE_COURTESY_SUCCESS,
				payload: json.model
			});

			callback && callback(null, json.model);
		} else {
			dispatch({ type: CHANGE_CHARGE_COURTESY_FAILED });
			callback &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}

export function cancelOrder(reason, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CANCEL_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Cancel?reason=" + reason,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CANCEL_ORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: CANCEL_ORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function payOrder(model, complete, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: PAY_ORDER
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/paid?complete=" + complete,
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: PAY_ORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: PAY_ORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function addAttendance(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_ORDER_ATTENDANCE
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPost(
			"/order/attendance/" + orderDetail.item.id,
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_ORDER_ATTENDANCE_SUCCESS,
				payload: json.model
			});

			dispatch && callback();
		} else {
			dispatch && dispatch({ type: ADD_ORDER_ATTENDANCE_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function removeAttendance(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_ATTENDANCE
		});

		const { login } = getState();

		let response = await api.sendDelete("/order/Attendance/" + id, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch &&
				dispatch({
					type: DELETE_ATTENDANCE_SUCCESS,
					payload: json.model
				});

			callback();
		} else {
			dispatch({ type: DELETE_ATTENDANCE_FAILED });
			dispatch &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}

export function searchProduct(code, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PRODUCT
		});

		const { login } = getState();

		let response = await api.sendGet("/product/code/" + code, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback(null, json.model);
		} else {
			dispatch({ type: GET_PRODUCT_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function addProduct(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_PRODUCT
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPost(
			"/order/" + orderDetail.item.id + "/Add",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			try {
				var json = await response.json();

				if (json.errors) {
					dispatch({ type: ADD_PRODUCT_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: ADD_PRODUCT_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: ADD_PRODUCT_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function addProductItems(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_PRODUCT_ITEM
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPost(
			"/order/" + orderDetail.item.id + "/Items",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_PRODUCT_ITEM_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: ADD_PRODUCT_ITEM_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function removeItem(item, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: REMOVE_PRODUCT_ITEM
		});

		const { login, orderDetail } = getState();

		let response = await api.sendDelete(
			"/order/" + orderDetail.item.id + "/Item/" + item.id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: REMOVE_PRODUCT_ITEM_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: REMOVE_PRODUCT_ITEM_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function changeItem(item, newItem, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_PRODUCT_ITEM
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" +
				orderDetail.item.id +
				"/item/" +
				item.id +
				"/change/" +
				newItem.id,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CHANGE_PRODUCT_ITEM_SUCCESS,
				payload: json.model
			});

			for (const p of json.model.products) {
				for (const i of p.items) {
					if (i.productItemId === newItem.id) {
						callback(null, i);
					}
				}
			}
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: CHANGE_PRODUCT_ITEM_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: CHANGE_PRODUCT_ITEM_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: CHANGE_PRODUCT_ITEM_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function changeOrderProductItemState(item, status, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_PRODUCT_ITEM_STATUS
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" +
				orderDetail.item.id +
				"/item/" +
				item.id +
				"/Status/" +
				status,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CHANGE_PRODUCT_ITEM_STATUS_SUCCESS,
				payload: json.model
			});

			callback(null, json.model);
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: CHANGE_PRODUCT_ITEM_STATUS_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: CHANGE_PRODUCT_ITEM_STATUS_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: CHANGE_PRODUCT_ITEM_STATUS_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function editProduct(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/Edit",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: EDIT_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: EDIT_PRODUCT_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function editItem(item, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT_ITEM
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/item/" + item.id,
			item,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;

			dispatch({
				type: EDIT_PRODUCT_ITEM_SUCCESS,
				payload
			});

			callback();
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: EDIT_PRODUCT_ITEM_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: EDIT_PRODUCT_ITEM_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: EDIT_PRODUCT_ITEM_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function deleteProduct(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PRODUCT
		});

		const { login, orderDetail } = getState();

		let response = await api.sendDelete(
			"/order/" + orderDetail.item.id + "/" + id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DELETE_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DELETE_PRODUCT_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function removePayment(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PAYMENT
		});

		const { login, orderDetail } = getState();

		let response = await api.sendDelete(
			"/order/" + orderDetail.item.id + "/payment/" + id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;

			payload.transactions = payload.transactions.filter(c => c.id !== id);

			dispatch({
				type: DELETE_PAYMENT_SUCCESS,
				payload
			});

			callback();
		} else {
			dispatch({ type: DELETE_PAYMENT_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function changeCharge(item, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_CHARGE
		});

		const { login, orderDetail } = getState();

		let response = await api.sendPut(
			"/order/" + orderDetail.item.id + "/item/" + item.id + "/Charge",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;

			let prod = payload.products.find(c =>
				c.items.find(i => i.id === item.id)
			);

			if (prod) {
				let prodItem = prod.items.find(i => i.id === item.id);
				if (prodItem) prodItem = !item.charge;
			}

			dispatch({
				type: CHANGE_CHARGE_SUCCESS,
				payload
			});

			callback();
		} else {
			dispatch({ type: CHANGE_CHARGE_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}
