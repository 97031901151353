import * as actions from "../../../actions/financial/monthRevenue";

const initialState = {
  loading: false,
  items: []
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.GET_MONTHREVENUE: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_MONTHREVENUE_SUCCESS: {
      return {
        ...state,
        items: payload ? payload : [],
        loading: false
      };
    }
    case actions.GET_MONTHREVENUE_ERROR: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.CLEAR_ITEMS: {
      return {
        ...state,
        items: []
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
