import * as api from "services/api";

export const GET_LAUNDRYORDER = "[LAUNDRYORDER DETAIL] GET_LAUNDRYORDER";
export const GET_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] GET_LAUNDRYORDER_SUCCESS";
export const GET_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] GET_LAUNDRYORDER_FAILED";

export const CONFIRM_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] CONFIRM_LAUNDRYORDER";
export const CONFIRM_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] CONFIRM_LAUNDRYORDER_SUCCESS";
export const CONFIRM_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] CONFIRM_LAUNDRYORDER_FAILED";

export const MARK_AS_PAID_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] MARK_AS_PAID_LAUNDRYORDER";
export const MARK_AS_PAID_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] MARK_AS_PAID_LAUNDRYORDER_SUCCESS";
export const MARK_AS_PAID_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] MARK_AS_PAID_LAUNDRYORDER_FAILED";

export const DELETE_PAYMENT = "[LAUNDRYORDER DETAIL] DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS =
	"[LAUNDRYORDER DETAIL] DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAILED =
	"[LAUNDRYORDER DETAIL] DELETE_PAYMENT_FAILED";

export const CHANGE_CHARGE = "[LAUNDRYORDER DETAIL] CHANGE_CHARGE";
export const CHANGE_CHARGE_SUCCESS =
	"[LAUNDRYORDER DETAIL] CHANGE_CHARGE_SUCCESS";
export const CHANGE_CHARGE_FAILED =
	"[LAUNDRYORDER DETAIL] CHANGE_CHARGE_FAILED";

export const CANCEL_LAUNDRYORDER = "[LAUNDRYORDER DETAIL] CANCEL_LAUNDRYORDER";
export const CANCEL_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] CANCEL_LAUNDRYORDER_SUCCESS";
export const CANCEL_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] CANCEL_LAUNDRYORDER_FAILED";

export const GET_PRODUCT = "[LAUNDRYORDER DETAIL] GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "[LAUNDRYORDER DETAIL] GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "[LAUNDRYORDER DETAIL] GET_PRODUCT_FAILED";

export const ADD_PRODUCT = "[LAUNDRYORDER DETAIL] ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "[LAUNDRYORDER DETAIL] ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILED = "[LAUNDRYORDER DETAIL] ADD_PRODUCT_FAILED";

export const CHANGE_PRODUCT_ITEM = "[LAUNDRYORDER DETAIL] CHANGE_PRODUCT_ITEM";
export const CHANGE_PRODUCT_ITEM_SUCCESS =
	"[LAUNDRYORDER DETAIL] CHANGE_PRODUCT_ITEM_SUCCESS";
export const CHANGE_PRODUCT_ITEM_FAILED =
	"[LAUNDRYORDER DETAIL] CHANGE_PRODUCT_ITEM_FAILED";

export const EDIT_PRODUCT = "[LAUNDRYORDER DETAIL] EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS =
	"[LAUNDRYORDER DETAIL] EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "[LAUNDRYORDER DETAIL] EDIT_PRODUCT_FAILED";

export const DELETE_PRODUCT = "[LAUNDRYORDER DETAIL] DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS =
	"[LAUNDRYORDER DETAIL] DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED =
	"[LAUNDRYORDER DETAIL] DELETE_PRODUCT_FAILED";

export const ONTHEWAYCOLLECTION_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] ONTHEWAYCOLLECTION_LAUNDRYORDER";
export const ONTHEWAYCOLLECTION_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] ONTHEWAYCOLLECTION_LAUNDRYORDER_SUCCESS";
export const ONTHEWAYCOLLECTION_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] ONTHEWAYCOLLECTION_LAUNDRYORDER_FAILED";

export const COLLECTED_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] COLLECTED_LAUNDRYORDER";
export const COLLECTED_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] COLLECTED_LAUNDRYORDER_SUCCESS";
export const COLLECTED_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] COLLECTED_LAUNDRYORDER_FAILED";

export const WASHINGPREPARATION_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] WASHINGPREPARATION_LAUNDRYORDER";
export const WASHINGPREPARATION_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] WASHINGPREPARATION_LAUNDRYORDER_SUCCESS";
export const WASHINGPREPARATION_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] WASHINGPREPARATION_LAUNDRYORDER_FAILED";

export const WASHING_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] WASHING_LAUNDRYORDER";
export const WASHING_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] WASHING_LAUNDRYORDER_SUCCESS";
export const WASHING_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] WASHING_LAUNDRYORDER_FAILED";

export const DELIVERYPREPARATION_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] DELIVERYPREPARATION_LAUNDRYORDER";
export const DELIVERYPREPARATION_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] DELIVERYPREPARATION_LAUNDRYORDER_SUCCESS";
export const DELIVERYPREPARATION_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] DELIVERYPREPARATION_LAUNDRYORDER_FAILED";

export const ONTHEWAYDELIVERY_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] ONTHEWAYDELIVERY_LAUNDRYORDER";
export const ONTHEWAYDELIVERY_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] ONTHEWAYDELIVERY_LAUNDRYORDER_SUCCESS";
export const ONTHEWAYDELIVERY_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] ONTHEWAYDELIVERY_LAUNDRYORDER_FAILED";

export const DELIVERED_LAUNDRYORDER =
	"[LAUNDRYORDER DETAIL] DELIVERED_LAUNDRYORDER";
export const DELIVERED_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] DELIVERED_LAUNDRYORDER_SUCCESS";
export const DELIVERED_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] DELIVERED_LAUNDRYORDER_FAILED";

export const DONE_LAUNDRYORDER = "[LAUNDRYORDER DETAIL] DONE_LAUNDRYORDER";
export const DONE_LAUNDRYORDER_SUCCESS =
	"[LAUNDRYORDER DETAIL] DONE_LAUNDRYORDER_SUCCESS";
export const DONE_LAUNDRYORDER_FAILED =
	"[LAUNDRYORDER DETAIL] DONE_LAUNDRYORDER_FAILED";

export function getLaundryOrder(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAUNDRYORDER
		});

		const { token } = getState().login;

		let response = await api.sendGet("/LaundryOrder/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback && callback(null, json.model);
		} else {
			dispatch({ type: GET_LAUNDRYORDER_FAILED });

			callback && callback("Error");
		}
	};
}

export function reload() {
	return (dispatch, getState) => {
		const { laundryOrderDetail } = getState();
		return dispatch(getLaundryOrder(laundryOrderDetail.item.id));
	};
}

export function confirmOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CONFIRM_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/1",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CONFIRM_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: CONFIRM_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function onTheWayCollection(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ONTHEWAYCOLLECTION_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/2",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ONTHEWAYCOLLECTION_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: ONTHEWAYCOLLECTION_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function collectedOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: COLLECTED_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/3",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: COLLECTED_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: COLLECTED_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function preparationForWashing(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: WASHINGPREPARATION_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/4",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: WASHINGPREPARATION_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: WASHINGPREPARATION_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function washing(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: WASHING_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/5",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: WASHING_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: WASHING_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function deliveryPreparation(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELIVERYPREPARATION_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/6",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DELIVERYPREPARATION_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DELIVERYPREPARATION_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function onTheWayDelivery(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ONTHEWAYDELIVERY_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/7",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ONTHEWAYDELIVERY_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: ONTHEWAYDELIVERY_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function delivery(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELIVERED_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/8",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DELIVERED_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DELIVERED_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function doneOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DONE_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/9",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DONE_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DONE_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function cancelOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CANCEL_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Status/99",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CANCEL_LAUNDRYORDER_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: CANCEL_LAUNDRYORDER_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function addEditProduct(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/Edit",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: EDIT_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: EDIT_PRODUCT_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function deleteProduct(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PRODUCT
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendDelete(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/" + id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DELETE_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DELETE_PRODUCT_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function markAsPaid(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: MARK_AS_PAID_LAUNDRYORDER
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendPut(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/payment/" + id,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;

			payload.transactions = payload.transactions.filter(c => c.id !== id);

			dispatch({
				type: MARK_AS_PAID_LAUNDRYORDER_SUCCESS,
				payload
			});

			callback && callback();
		} else {
			dispatch({ type: MARK_AS_PAID_LAUNDRYORDER_FAILED });
			callback &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}

export function removePayment(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PAYMENT
		});

		const { login, laundryOrderDetail } = getState();

		let response = await api.sendDelete(
			"/LaundryOrder/" + laundryOrderDetail.item.id + "/payment/" + id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;

			payload.transactions = payload.transactions.filter(c => c.id !== id);

			dispatch({
				type: DELETE_PAYMENT_SUCCESS,
				payload
			});

			callback && callback();
		} else {
			dispatch({ type: DELETE_PAYMENT_FAILED });
			callback &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}
