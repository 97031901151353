import * as actions from "../../../actions/cashier/detail";

const canClearState = {
	loading: false,
	item: null,
	errors: {}
};

const initialState = {
	...canClearState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...canClearState
			};
		}
		case actions.GET_CASHIER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_CASHIER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_CASHIER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
