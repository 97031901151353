import * as actions from "../../../../actions/chartOfAccount/sub/edit";

const initialSubChartOfAccountState = {
  name: "",
  code: "",
  chartOfAccountsId: 0,
  description: ""
};

const initialState = {
  loading: false,
  item: null,
  errors: {},
  ...initialSubChartOfAccountState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      const { item } = state;

      return {
        ...state,
        ...initialSubChartOfAccountState,
        item,
        name: item.name,
        code: item.code,
        description: item.description,
        chartOfAccountsId: item.chartOfAccountsId
      };
    }
    case actions.GET_SUB_CHART_OF_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.EDIT_SUB_CHART_OF_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_SUB_CHART_OF_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_SUB_CHART_OF_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_SUB_CHART_OF_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_SUB_CHART_OF_ACCOUNT_SUCCESS: {
      return {
        ...state,
        item: payload,
        name: payload.name,
        code: payload.code,
        description: payload.description,
        chartOfAccountsId: payload.chartOfAccountsId,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
