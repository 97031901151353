import * as api from "services/api";

import _ from "lodash";
import { emailValidation } from "../../../../helpers/validators";

import history from "../../../../history";

export const ADD_USER = "[USER CREATE] ADD_USER";
export const ADD_USER_SUCCESS = "[USER CREATE] ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "[USER CREATE] ADD_USER_FAILED";

export const GET_ROLES = "[USER CREATE] GET_ROLES";
export const GET_ROLES_SUCCESS = "[USER CREATE] GET_ROLES_SUCCESS";
export const GET_ROLES_FAILED = "[USER CREATE] GET_ROLES_FAILED";

export const SET_VALUE = "[USER CREATE] SET_VALUE";
export const CLEAR_VALUE = "[USER CREATE] CLEAR_VALUE";

export function addUser() {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_USER
		});

		const { login, userCreate } = getState();

		let { name, lastName, email, password, confirmPassword, role } = userCreate;

		const errors = {};

		if (email && !emailValidation(email)) {
			errors.email = "E-mail inválido";
		}

		if (!name) {
			errors.name = "Informe um nome";
		}

		if (!email) {
			errors.email = "Informe um email";
		} else if (!emailValidation(email)) errors.email = "E-mail inválido";

		if (!lastName) {
			errors.lastName = "Informe um sobrenome";
		}

		if (!password) {
			errors.password = "Informe uma senha";
		}

		if (password !== confirmPassword) {
			errors.confirmPassword = "Senhas não conferem";
		}

		if (!role) {
			errors.role = "Selecione uma função";
		}

		dispatch(
			setValue({
				errors
			})
		);

		if (_.keys(errors).length > 0) return dispatch({ type: ADD_USER_FAILED });

		let response = await api.sendPost(
			"/user",
			{
				name,
				lastName,
				email,
				password,
				confirmPassword,
				roleId: role
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: ADD_USER_SUCCESS
			});

			dispatch(clearValue());

			history.push("/users/" + result.model.id);
		} else {
			dispatch({ type: ADD_USER_FAILED });
		}
	};
}

export function getRoles() {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_ROLES
		});

		const { login } = getState();

		let response = await api.sendGet("/user/Roles", {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: GET_ROLES_SUCCESS,
				payload: result.model
			});
		} else {
			dispatch({ type: GET_ROLES_FAILED });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
