import * as Actions from "../../actions/login";

const initialState = {
  success: false,
  loading: false,
  token: "",
  expiration: "",
  refreshTokenPromise: null,
  message: null,
  user: null,
  error: {
    passwordRecovery: null,
    username: null,
    password: null,
    error: null
  }
};

const login = function(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.LOGIN: {
      return {
        ...state,
        message: null,
        error: initialState.error,
        loading: true
      };
    }
    case Actions.USER_LOGGED_OUT: {
      return {
        ...initialState
      };
    }
    case Actions.LOGIN_SUCCESS: {
      return {
        message: null,
        error: initialState.error,
        success: true,
        loading: false,
        ...payload
      };
    }
    case Actions.LOGIN_ERROR: {
      return {
        ...state,
        success: false,
        error: payload,
        loading: false
      };
    }
    case Actions.SET_ERROR: {
      return {
        ...state,
        success: false,
        error: payload,
        loading: false
      };
    }
    case Actions.REFRESH_TOKEN: {
      return {
        ...state,
        loading: true,
        refreshTokenPromise: payload
      };
    }
    case Actions.REFRESH_TOKEN_ERROR: {
      return {
        ...state,
        success: false,
        error: payload,
        loading: false,
        refreshTokenPromise: null
      };
    }
    case Actions.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        refreshTokenPromise: null
      };
    }
    case Actions.SET_USER_DATA: {
      return {
        ...state,
        user: payload
      };
    }
    case Actions.GET_USER_DATA_ERROR: {
      return {
        ...initialState,
        error: {
          ...state.error,
          error: "Can't load user data"
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default login;
