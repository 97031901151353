import _ from "lodash";
import * as api from "services/api";
import history from "../../../../history";

export const EDIT_PAYMENT_METHOD = "[PAYMENT_METHOD EDIT] EDIT_PAYMENT_METHOD";
export const EDIT_PAYMENT_METHOD_SUCCESS =
  "[PAYMENT_METHOD EDIT] EDIT_PAYMENT_METHOD_SUCCESS";
export const EDIT_PAYMENT_METHOD_FAILED =
  "[PAYMENT_METHOD EDIT] EDIT_PAYMENT_METHOD_FAILED";

export const GET_PAYMENT_METHOD = "[PAYMENT_METHOD EDIT] GET_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD_SUCCESS =
  "[PAYMENT_METHOD EDIT] GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_FAILED =
  "[PAYMENT_METHOD EDIT] GET_PAYMENT_METHOD_FAILED";

export const SET_VALUE = "[PAYMENT_METHOD EDIT] SET_VALUE";
export const CLEAR_VALUE = "[PAYMENT_METHOD EDIT] CLEAR_VALUE";

export function getPaymentMethod(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PAYMENT_METHOD
    });

    const { token } = getState().login;

    let response = await api.sendGet("/PaymentMethod/" + id, {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let item = json.model;
      dispatch({
        type: GET_PAYMENT_METHOD_SUCCESS,
        payload: item
      });
    } else {
      dispatch({ type: GET_PAYMENT_METHOD_FAILED });
    }
  };
}

export function editPaymentMethod() {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_PAYMENT_METHOD
    });

    const { login, paymentMethodEdit } = getState();

    let {
      name,
      receiveDays,
      fee,
      feeType,
      // chartOfAccountsId,
      // subChartOfAccountsId,
      // feeChartOfAccountsId,
      // feeSubChartOfAccountsId,
      financialAccountId,
      item
    } = paymentMethodEdit;

    const errors = {};

    if (!name) errors.name = "Informe um nome";
    if (receiveDays < 0) errors.receiveDays = "Dia inválido";
    // if (!chartOfAccountsId)
    //   errors.chartOfAccountsId = "Informe um plano de contas";
    // if (!subChartOfAccountsId)
    //   errors.subChartOfAccountsId = "Informe um sub plano de contas";

    if (fee < 0) {
      if (!fee) errors.fee = "Taxa inválida";
    }

    // if (fee > 0) {
    //   if (!feeChartOfAccountsId)
    //     errors.feeChartOfAccountsId = "Informe um plano de contas";
    //   if (!feeSubChartOfAccountsId)
    //     errors.feeSubChartOfAccountsId = "Informe um sub plano de contas";
    // }

    if (!financialAccountId)
      errors.financialAccountId = "Informe um conta financeira";

    dispatch(setValue({ errors }));

    if (_.keys(errors).length > 0)
      return dispatch({ type: EDIT_PAYMENT_METHOD_FAILED });

    let response = await api.sendPut(
      "/PaymentMethod/" + item.id,
      {
        ...item,
        name,
        receiveDays,
        fee: Math.round(fee * 100),
        feeType,
        // subChartOfAccountsId,
        // feeSubChartOfAccountsId,
        financialAccountId
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      dispatch({
        type: EDIT_PAYMENT_METHOD_SUCCESS
      });

      dispatch(clearValue());

      history.push("/PaymentMethod");
    } else {
      dispatch({ type: EDIT_PAYMENT_METHOD_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
