import * as api from "services/api";

import _ from "lodash";

import history from "../../../../history";
import moment from "moment";

export const ADD_LAUNDRY_ORDER = "[LAUNDRY_ORDER ADD] ADD_LAUNDRY_ORDER";
export const ADD_LAUNDRY_ORDER_SUCCESS =
	"[LAUNDRY_ORDER ADD] ADD_LAUNDRY_ORDER_SUCCESS";
export const ADD_LAUNDRY_ORDER_FAILED =
	"[LAUNDRY_ORDER ADD] ADD_LAUNDRY_ORDER_FAILED";

export const GET_DELIVERY_CITIES = "[LAUNDRY_ORDER ADD] GET_DELIVERY_CITIES";
export const GET_DELIVERY_CITIES_SUCCESS =
	"[LAUNDRY_ORDER ADD] GET_DELIVERY_CITIES_SUCCESS";
export const GET_DELIVERY_CITIES_FAILED =
	"[LAUNDRY_ORDER ADD] GET_DELIVERY_CITIES_FAILED";

export const GET_INDICATION_NAMES = "[LAUNDRY_ORDER ADD] GET_INDICATION_NAMES";
export const GET_INDICATION_NAMES_FAILED =
	"[LAUNDRY_ORDER ADD] GET_INDICATION_NAMES_FAILED";
export const GET_INDICATION_NAMES_SUCCESS =
	"[LAUNDRY_ORDER ADD] GET_INDICATION_NAMES_SUCCESS";

export const SET_VALUE = "[LAUNDRY_ORDER ADD] SET_VALUE";
export const CLEAR_VALUE = "[LAUNDRY_ORDER ADD] CLEAR_VALUE";

export function addLaundryOrder() {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_LAUNDRY_ORDER
		});

		const { login, laundryOrderCreate } = getState();

		let {
			clientId,
			collectionDate,
			returnDate,
			deliveryType,
			street,
			number,
			complement,
			neighborhood,
			city,
			note
		} = laundryOrderCreate;

		const errors = {};

		if (!clientId) {
			errors.clientId = "Campo obrigatório";
		}

		if (
			moment.parseZone(collectionDate).isAfter(moment.parseZone(returnDate))
		) {
			errors.startDate = "Data inválida";
			errors.endDate = "Data inválida";
		}

		dispatch(
			setValue({
				errors
			})
		);

		if (_.keys(errors).length > 0)
			return dispatch({ type: ADD_LAUNDRY_ORDER_FAILED });

		let response = await api.sendPost(
			"/laundryOrder/inner",
			{
				clientId,
				collectionDate,
				returnDate,
				deliveryType,
				address: {
					street,
					number,
					complement,
					neighborhood,
					city,
					state: "SC",
					country: "BR"
				},
				note
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: ADD_LAUNDRY_ORDER_SUCCESS
			});

			dispatch(clearValue());

			history.push("/laundryOrders/" + result.model.id);
		} else {
			dispatch({ type: ADD_LAUNDRY_ORDER_FAILED });
		}
	};
}

export function getDeliveryCities(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_DELIVERY_CITIES
		});

		const { login } = getState();

		let response = await api.sendGet("/order/city", {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: GET_DELIVERY_CITIES_SUCCESS,
				payload: result.model
			});

			callback && callback(null, result.model);
		} else {
			dispatch({ type: GET_DELIVERY_CITIES_FAILED });
			callback && callback(true);
		}
	};
}

export function getIndicationNames(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_INDICATION_NAMES
		});

		const { login } = getState();

		let response = await api.sendGet("/laundryOrder/indication/names", {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: GET_INDICATION_NAMES_SUCCESS
			});

			callback(null, result.model);
		} else {
			dispatch({ type: GET_INDICATION_NAMES_FAILED });
			callback(true);
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
