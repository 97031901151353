import * as api from "services/api";

import history from "../../../../history";

import _ from "lodash";

export const ADD_FINANCIAL_ACCOUNT =
	"[FINANCIAL ACCOUNT CREATE] ADD_FINANCIAL_ACCOUNT";
export const ADD_FINANCIAL_ACCOUNT_SUCCESS =
	"[FINANCIAL ACCOUNT CREATE] ADD_FINANCIAL_ACCOUNT_SUCCESS";
export const ADD_FINANCIAL_ACCOUNT_FAILED =
	"[FINANCIAL ACCOUNT CREATE] ADD_FINANCIAL_ACCOUNT_FAILED";

export const SET_VALUE = "[FINANCIAL ACCOUNT CREATE] SET_VALUE";
export const CLEAR_VALUE = "[FINANCIAL ACCOUNT CREATE] CLEAR_VALUE";

export function addFinancialAccount() {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_FINANCIAL_ACCOUNT
		});

		const { login, financialAccountCreate } = getState();

		let {
			name,
			type,
			agency,
			account,
			openingBalance,
			note
		} = financialAccountCreate;

		const errors = {};

		if (!name) {
			errors.name = "Informe um nome";
		}

		dispatch(
			setValue({
				errors
			})
		);

		if (_.keys(errors).length > 0)
			return dispatch({ type: ADD_FINANCIAL_ACCOUNT_FAILED });

		let response = await api.sendPost(
			"/FinancialAccount",
			{
				name,
				type,
				agency,
				account,
				openingBalance: openingBalance * 100,
				note
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			dispatch({
				type: ADD_FINANCIAL_ACCOUNT_SUCCESS
			});

			dispatch(clearValue());

			history.push("/FinancialAccount");
		} else {
			dispatch({ type: ADD_FINANCIAL_ACCOUNT_FAILED });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
