import * as actions from "../../../actions/pendencies/report";

const initialState = {
	loading: false,
	orders: [],
	laundryOrders: []
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_LAUNDRY_ORDERS:
		case actions.GET_ORDERS: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_LAUNDRY_ORDERS_FAILED:
		case actions.GET_ORDERS_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_ORDERS_SUCCESS: {
			return {
				...state,
				orders: payload ? payload : [],
				loading: false
			};
		}
		case actions.GET_LAUNDRY_ORDERS_SUCCESS: {
			return {
				...state,
				laundryOrders: payload ? payload : [],
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
