import { lazy } from "react";
import Fingerprint from "@material-ui/icons/Fingerprint";

import ls from "Localization";

const LoginPage = lazy(() => import("views/Pages/LoginPage.jsx"));

const pagesRoutes = [
	{
		path: "/login",
		name: ls.login,
		short: ls.loginShort,
		icon: Fingerprint,
		component: LoginPage
	}
];

export default pagesRoutes;
