import * as api from "services/api";

export const GET_INVENTORY = "[PRODUCT REPORT] GET_INVENTORY";
export const CLEAR_ITEMS = "[PRODUCT REPORT] CLEAR_ITEMS";
export const GET_INVENTORY_FAILED = "[PRODUCT REPORT] GET_INVENTORY_FAILED";
export const GET_INVENTORY_SUCCESS = "[PRODUCT REPORT] GET_INVENTORY_SUCCESS";
export const SET_SEARCH_TEXT = "[PRODUCT REPORT] SET_SEARCH_TEXT";

export function getInventory() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_INVENTORY
    });

    const { token } = getState().login;

    let response = await api.sendGet("/product/item/inventory", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let payload = json.model;
      dispatch({
        type: GET_INVENTORY_SUCCESS,
        payload
      });
    } else {
      dispatch({ type: GET_INVENTORY_FAILED });
    }
  };
}

export function clearItems() {
  return {
    type: CLEAR_ITEMS
  };
}
