import * as api from "services/api";

import ls from "Localization";

export const GET_ITEMS = "[PRODUCT DETAIL] GET_ITEMS";
export const GET_ITEMS_SUCCESS = "[PRODUCT DETAIL] GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAILED = "[PRODUCT DETAIL] GET_ITEMS_FAILED";

export const SET_VALUE = "[PRODUCT DETAIL] SET_VALUE";
export const CLEAR_VALUE = "[PRODUCT DETAIL] CLEAR_VALUE";

export const ADD_ITEM = "[PRODUCT DETAIL] ADD_ITEM";
export const ADD_ITEM_SUCCESS = "[PRODUCT DETAIL] ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILED = "[PRODUCT DETAIL] ADD_ITEM_FAILED";

export const EDIT_ITEM = "[PRODUCT DETAIL] EDIT_ITEM";
export const EDIT_ITEM_SUCCESS = "[PRODUCT DETAIL] EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAILED = "[PRODUCT DETAIL] EDIT_ITEM_FAILED";

export const DELETE_ITEM = "[PRODUCT DETAIL] DELETE_ITEM";
export const DELETE_ITEM_SUCCESS = "[PRODUCT DETAIL] DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILED = "[PRODUCT DETAIL] DELETE_ITEM_FAILED";

export function getProductItems(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ITEMS,
    });

    const { token } = getState().login;

    let response = await api.sendGet("/product/" + id + "/item", {
      Authorization: "Bearer " + token,
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_ITEMS_SUCCESS,
        payload: json.model,
      });
    } else {
      dispatch({ type: GET_ITEMS_FAILED });
    }
  };
}

export function addItem(productId, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_ITEM,
    });

    const { login, productDetailItem } = getState();

    let {
      code,
      locationCounter,
      note,
      status,
      boughtIn,
      soldIn,
      error,
    } = productDetailItem;

    error = "";

    if (!code) error = ls.required;

    if (error) return dispatch({ type: ADD_ITEM_FAILED, payload: error });

    let response = await api.sendPost(
      "/product/" + productId + "/item",
      {
        code,
        locationCounter,
        note,
        status,
        boughtIn,
        soldIn,
      },
      {
        Authorization: "Bearer " + login.token,
      }
    );

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: ADD_ITEM_SUCCESS,
        payload: json.model,
      });

      dispatch(clearValue());

      callback && callback();
    } else {
      try {
        let result = await response.json();

        let errors = "";

        for (const error of result.errors) {
          const err = ls[error];

          if (err) errors += err + ",";
          else errors += error + ",";
        }

        errors = errors.substr(0, errors.length - 1);

        dispatch({ type: ADD_ITEM_FAILED, payload: errors });
        callback && callback(errors);
      } catch (error) {
        dispatch({ type: ADD_ITEM_FAILED, payload: error });
        callback && callback(error);
      }
    }
  };
}

export function editItem(productId, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_ITEM,
    });

    const { login, productDetailItem } = getState();

    let {
      locationCounter,
      note,
      status,
      edit,
      boughtIn,
      soldIn,
    } = productDetailItem;

    edit.locationCounter = locationCounter;
    edit.note = note;
    edit.status = status;
    edit.soldIn = soldIn;
    edit.boughtIn = boughtIn;

    let response = await api.sendPut("/product/" + productId + "/item", edit, {
      Authorization: "Bearer " + login.token,
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: EDIT_ITEM_SUCCESS,
        payload: json.model,
      });

      dispatch(clearValue());

      callback && callback();
    } else {
      try {
        let result = await response.json();

        let errors = "";

        for (const error of result.errors) {
          const err = ls[error];

          if (err) errors += err + ",";
          else errors += error + ",";
        }

        errors = errors.substr(0, errors.length - 1);

        dispatch({ type: EDIT_ITEM_FAILED, payload: errors });
        callback && callback(errors);
      } catch (error) {
        dispatch({ type: EDIT_ITEM_FAILED, payload: error });
        callback && callback(error);
      }
    }
  };
}

export function deleteItem(id, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_ITEM,
    });

    const { token } = getState().login;

    let response = await api.sendDelete("/product/item/" + id, {
      Authorization: "Bearer " + token,
    });

    if (response.ok) {
      dispatch({
        type: DELETE_ITEM_SUCCESS,
        payload: id,
      });

      callback && callback();
    } else {
      dispatch({ type: DELETE_ITEM_FAILED });

      try {
        let result = await response.json();

        let errors = "";

        for (const error of result.errors) {
          const err = ls[error];

          if (err) errors += err + ",";
          else errors += error + ",";
        }

        errors = errors.substr(0, errors.length - 1);

        callback && callback(errors);
      } catch (error) {
        callback && callback(error);
      }
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload,
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE,
  };
}
