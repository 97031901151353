import * as actions from "../../../actions/user/create";

const initialUserState = {
	name: "",
	lastName: "",
	email: "",
	role: "",
	password: "",
	confirmPassword: "",
	roles: [],
	loading: {},
	errors: {}
};

const initialState = {
	...initialUserState
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...initialUserState
			};
		}
		case actions.ADD_USER: {
			return {
				...state,
				loading: { add: true }
			};
		}
		case actions.ADD_USER_FAILED: {
			return {
				...state,
				loading: { add: false }
			};
		}
		case actions.ADD_USER_SUCCESS: {
			return {
				...state,
				...initialUserState,
				loading: { add: false }
			};
		}
		case actions.GET_ROLES: {
			return {
				...state,
				loading: { roles: true }
			};
		}
		case actions.GET_ROLES_FAILED: {
			return {
				...state,
				loading: { roles: false }
			};
		}
		case actions.GET_ROLES_SUCCESS: {
			return {
				...state,
				roles: payload,
				loading: { roles: false }
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
