import * as api from "services/api";

import _ from "lodash";
import { isValidNumber } from "libphonenumber-js";
import {
  CPFValidation,
  CNPJValidation,
  emailValidation
} from "../../../../helpers/validators";

import history from "../../../../history";

export const ADD_CLIENT = "[CLIENT CREATE] ADD_CLIENT";
export const ADD_CLIENT_SUCCESS = "[CLIENT CREATE] ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILED = "[CLIENT CREATE] ADD_CLIENT_FAILED";

export const SET_VALUE = "[CLIENT CREATE] SET_VALUE";
export const CLEAR_VALUE = "[CLIENT CREATE] CLEAR_VALUE";

export function addClient() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_CLIENT
    });

    const { login, clientCreate } = getState();

    let {
      type,
      name,
      lastName,
      vip,
      email,
      document,
      zipCode,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      country,
      gender,
      nationality,
      phone,
      isOwner
    } = clientCreate;

    const errors = {};

    if (phone && !isValidNumber(phone)) {
      errors.phone = "Telefone inválido";
    }

    if (document) {
      if (type === 0) {
        if (!CPFValidation(document)) {
          errors.document = "CPF inválido";
        }
      } else if (type === 1) {
        if (!CNPJValidation(document)) {
          errors.document = "CNPJ inválido";
        }
      }
    }

    if (email && !emailValidation(email)) {
      errors.email = "E-mail inválido";
    }

    if (type !== 1 && gender === 0) {
      errors.gender = "Selecione um sexo";
    }

    if (!name) {
      errors.name = type === 0 ? "Informe um nome" : "Informe a razão social";
    }

    if (!lastName) {
      errors.lastName =
        type === 0 ? "Informe um sobrenome" : "Informe o nome fantasia";
    }

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0) return dispatch({ type: ADD_CLIENT_FAILED });

    let response = await api.sendPost(
      "/client",
      {
        name,
        lastName,
        email,
        document,
        gender,
        nationality,
        vip,
        isOwner,
        type,
        address: {
          street,
          number,
          complement,
          neighborhood,
          city,
          state,
          country,
          zipCode
        },
        phoneNumber: {
          number: phone
        }
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      const result = await response.json();

      dispatch({
        type: ADD_CLIENT_SUCCESS
      });

      dispatch(clearValue());

      history.push("/clients/" + result.model.id);
    } else {
      dispatch({ type: ADD_CLIENT_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
