import * as api from "services/api";

export const GET_CASHIER = "[CASHIER DETAIL] GET_CASHIER";
export const GET_CASHIER_FAILED = "[CASHIER DETAIL] GET_CASHIER_FAILED";
export const GET_CASHIER_SUCCESS = "[CASHIER DETAIL] GET_CASHIER_SUCCESS";

export const SET_VALUE = "[CASHIER DETAIL] SET_VALUE";
export const CLEAR_VALUE = "[CASHIER DETAIL] CLEAR_VALUE";

export function getCashier(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CASHIER
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(`/cashier/${id}`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CASHIER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_CASHIER_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_CASHIER_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
