import * as actions from "../../../actions/financialAccount/create";

const initialFinancialAccountState = {
  name: "",
  agency: "",
  account: "",
  type: 0,
  openingBalance: 0,
  note: "",
  errors: {}
};

const initialState = {
  loading: false,
  ...initialFinancialAccountState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialFinancialAccountState
      };
    }
    case actions.ADD_FINANCIAL_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_FINANCIAL_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_FINANCIAL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
