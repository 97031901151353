import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "localforage";
import reducers from "store/reducers";
import thunk from "redux-thunk";

import { jwt } from "./middlewares/jwt";

const persistConfig = {
	key: "root",
	storage,
	blacklist: [
		"clientReport",
		"orderReport",
		"stockReport",
		"budgetReport",
		"inventoryReport",
		"productCategoryReport",
		"userReport",
		"sale"
	]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers =
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

const enhancer = composeEnhancers(applyMiddleware(jwt, thunk));

export default () => {
	let store = createStore(persistedReducer, enhancer);
	let persistor = persistStore(store);
	return { store, persistor };
};
