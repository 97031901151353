import * as api from "services/api";

export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILED = "GET_CLIENTS_FAILED";
export const SET_SEARCH_TEXT = "[CLIENT REPORT] SET_SEARCH_TEXT";

export const SET_VALUE = "[CLIENT REPORT] SET_VALUE";
export const CLEAR_VALUE = "[CLIENT REPORT] CLEAR_VALUE";

export function getClientsV2(
	skip = 0,
	take = 10,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENTS
		});

		const { token } = getState().login;

		let response = await api.sendGet(
			`/client/v2?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
				"&filters=null"}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let clients = json.model;

			if (toExport) {
				dispatch(setValue({ loading: false }));
			} else {
				dispatch({
					type: GET_CLIENTS_SUCCESS,
					payload: clients
				});
			}

			callback && callback(null, clients);
		} else {
			dispatch({ type: GET_CLIENTS_FAILED });
			callback && callback(true);
		}
	};
}

export function getClients(term, callback, startDate, endDate) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENTS
		});

		const { token } = getState().login;

		let response = await api.sendGet(
			`/client?term=${term || ""}&startDate=${startDate}&endDate=${endDate}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let clients = json.model;
			dispatch({
				type: GET_CLIENTS_SUCCESS,
				payload: clients
			});

			callback && callback(null, clients);
		} else {
			dispatch({ type: GET_CLIENTS_FAILED });
			callback && callback(true);
		}
	};
}

export function getClientsTiny(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENTS
		});

		const { token } = getState().login;

		let response = await api.sendGet("/client/Tiny", {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let clients = json.model;
			dispatch({
				type: GET_CLIENTS_SUCCESS,
				payload: clients
			});

			callback && callback(null, clients);
		} else {
			dispatch({ type: GET_CLIENTS_FAILED });
			callback && callback(true);
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
