import * as actions from "../../../actions/productCategory/create";

const initialProductCategoryState = {
  name: "",
  nameError: "",
  description: "",
  descriptionError: "",
  errors: {}
};

const initialState = {
  loading: false,
  ...initialProductCategoryState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialProductCategoryState
      };
    }
    case actions.ADD_PRODUCTCATEGORY: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_PRODUCTCATEGORY_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_PRODUCTCATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
