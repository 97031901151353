import * as api from "services/api";

import history from "../../../../history";

export const GET_BUDGET = "[BUDGET DETAIL] GET_BUDGET";
export const GET_BUDGET_SUCCESS = "[BUDGET DETAIL] GET_BUDGET_SUCCESS";
export const GET_BUDGET_FAILED = "[BUDGET DETAIL] GET_BUDGET_FAILED";

export const SEND_EMAIL = "[BUDGET DETAIL] SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "[BUDGET DETAIL] SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILED = "[BUDGET DETAIL] SEND_EMAIL_FAILED";

export const ADD_PRODUCT = "[BUDGET DETAIL] ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "[BUDGET DETAIL] ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILED = "[BUDGET DETAIL] ADD_PRODUCT_FAILED";

export const EDIT_PRODUCT = "[BUDGET DETAIL] EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "[BUDGET DETAIL] EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "[BUDGET DETAIL] EDIT_PRODUCT_FAILED";

export const DELETE_PRODUCT = "[BUDGET DETAIL] DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "[BUDGET DETAIL] DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "[BUDGET DETAIL] DELETE_PRODUCT_FAILED";

export const CANCEL_BUDGET = "[BUDGET DETAIL] CANCEL_BUDGET";
export const CANCEL_BUDGET_FAILED = "[BUDGET DETAIL] CANCEL_BUDGET_FAILED";
export const CANCEL_BUDGET_SUCCESS = "[BUDGET DETAIL] CANCEL_BUDGET_SUCCESS";

export const CREATE_CHANGE_BUDGET = "[BUDGET DETAIL] CREATE_CHANGE_BUDGET";
export const CREATE_CHANGE_BUDGET_SUCCESS =
	"[BUDGET DETAIL] CREATE_CHANGE_BUDGET_SUCCESS";
export const CREATE_CHANGE_BUDGET_FAILED =
	"[BUDGET DETAIL] CREATE_CHANGE_BUDGET_FAILED";

export const CHANGE_CHARGE_COURTESY = "[BUDGET DETAIL] CHANGE_CHARGE_COURTESY";
export const CHANGE_CHARGE_COURTESY_SUCCESS =
	"[BUDGET DETAIL] CHANGE_CHARGE_COURTESY_SUCCESS";
export const CHANGE_CHARGE_COURTESY_FAILED =
	"[BUDGET DETAIL] CHANGE_CHARGE_COURTESY_FAILED";

export function getBudget(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BUDGET
		});

		const { token } = getState().login;

		let response = await api.sendGet("/budget/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_BUDGET_SUCCESS,
				payload: json.model
			});
		} else {
			dispatch({ type: GET_BUDGET_FAILED });
		}
	};
}

export function convertItem(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BUDGET
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendPut(
			"/budget/" + budgetDetail.item.id + "/To/Order",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			dispatch({
				type: GET_BUDGET_SUCCESS
			});

			callback();

			history.push("/orders/" + budgetDetail.item.id);
		} else {
			dispatch({ type: GET_BUDGET_FAILED });
			callback("Ocorreu um erro ao tentar enviar sua solicitação");
		}
	};
}

export function sendEmail(email, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: SEND_EMAIL
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendPost(
			"/budget/" + budgetDetail.item.id + "/Email/" + email,
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			dispatch({
				type: SEND_EMAIL_SUCCESS
			});

			callback && callback();
		} else {
			try {
				var json = await response.json();

				if (json.errors) {
					dispatch({ type: SEND_EMAIL_FAILED });
					callback && callback(json.errors.join());
				} else {
					dispatch({ type: SEND_EMAIL_FAILED });
					callback &&
						callback(
							"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
						);
				}
			} catch (error) {
				dispatch({ type: SEND_EMAIL_FAILED });
				callback &&
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
			}
		}
	};
}

export function addProduct(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_PRODUCT
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendPost(
			"/budget/" + budgetDetail.item.id + "/Add",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			try {
				var json = await response.json();

				if (json.errors) {
					dispatch({ type: ADD_PRODUCT_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: ADD_PRODUCT_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: ADD_PRODUCT_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function editProduct(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendPut(
			"/budget/" + budgetDetail.item.id + "/Edit",
			model,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: EDIT_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			try {
				let json = await response.json();

				if (json.errors) {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(json.errors.join());
				} else {
					dispatch({ type: EDIT_PRODUCT_FAILED });
					callback(
						"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
					);
				}
			} catch (error) {
				dispatch({ type: EDIT_PRODUCT_FAILED });
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
			}
		}
	};
}

export function deleteProduct(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PRODUCT
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendDelete(
			"/budget/" + budgetDetail.item.id + "/" + id,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: DELETE_PRODUCT_SUCCESS,
				payload: json.model
			});

			callback();
		} else {
			dispatch({ type: DELETE_PRODUCT_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function cancelBudget(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CANCEL_BUDGET
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendDelete("/budget/" + budgetDetail.item.id, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			dispatch({
				type: CANCEL_BUDGET_SUCCESS
			});

			callback();
		} else {
			dispatch({ type: CANCEL_BUDGET_FAILED });
			callback(
				"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
			);
		}
	};
}

export function createChangeBudget(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CHANGE_BUDGET
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendPost(
			"/budget/" + budgetDetail.item.id + "/Change/Create",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CREATE_CHANGE_BUDGET_SUCCESS,
				payload: json.model
			});

			callback && callback(null, json.model);
		} else {
			dispatch({ type: CREATE_CHANGE_BUDGET_FAILED });
			callback &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}

export function changeChargeCourtesy(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHANGE_CHARGE_COURTESY
		});

		const { login, budgetDetail } = getState();

		let response = await api.sendPut(
			"/budget/" + budgetDetail.item.id + "/Courtesy/Toggle",
			null,
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CHANGE_CHARGE_COURTESY_SUCCESS,
				payload: json.model
			});

			callback && callback(null, json.model);
		} else {
			dispatch({ type: CHANGE_CHARGE_COURTESY_FAILED });
			callback &&
				callback(
					"Ocorreu um erro ao tentar enviar sua solicitação, tente novamente"
				);
		}
	};
}
