import * as actions from "../../../actions/lot/create";

const initialState = {
  loading: false,
  productId: "",
  moreInfo: "",
  errors: {},
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...initialState,
      };
    }
    case actions.ADD_LOT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.ADD_LOT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.ADD_LOT_SUCCESS: {
      return {
        ...initialState,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
