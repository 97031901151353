import * as actions from "../../../actions/laundry/detail";

const initialState = {
	loading: false,
	item: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: { ...payload },
				loading: false
			};
		}
		case actions.ADD_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_PRODUCT_SUCCESS: {
			return {
				...state,
				item: {
					...payload,
					products: payload.products.filter(c => !c.deleted)
				},
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_PRODUCT_SUCCESS: {
			return {
				...state,
				item: {
					...payload,
					products: payload.products.filter(c => !c.deleted)
				},
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELETE_PRODUCT_SUCCESS: {
			return {
				...state,
				item: {
					...payload,
					products: payload.products.filter(c => !c.deleted)
				},
				loading: false
			};
		}
		case actions.CONFIRM_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CONFIRM_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CONFIRM_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.ONTHEWAYCOLLECTION_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ONTHEWAYCOLLECTION_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ONTHEWAYCOLLECTION_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.COLLECTED_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.COLLECTED_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.COLLECTED_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.WASHINGPREPARATION_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.WASHINGPREPARATION_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.WASHINGPREPARATION_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.WASHING_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.WASHING_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.WASHING_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DELIVERYPREPARATION_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DELIVERYPREPARATION_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELIVERYPREPARATION_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.ONTHEWAYDELIVERY_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ONTHEWAYDELIVERY_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ONTHEWAYDELIVERY_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DELIVERED_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DELIVERED_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELIVERED_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DONE_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DONE_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DONE_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.CANCEL_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.CANCEL_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CANCEL_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.MARK_AS_PAID_LAUNDRYORDER: {
			return {
				...state,
				loading: true
			};
		}
		case actions.MARK_AS_PAID_LAUNDRYORDER_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.MARK_AS_PAID_LAUNDRYORDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.DELETE_PAYMENT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.DELETE_PAYMENT_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.DELETE_PAYMENT_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.CHANGE_CHARGE_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
