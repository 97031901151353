import * as actions from "../../../actions/paymentMethod/edit";

const initialPaymentMethodState = {
  name: "",
  description: ""
};

const initialState = {
  loading: false,
  item: null,
  errors: {},
  ...initialPaymentMethodState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      const { item } = state;

      return {
        ...state,
        ...initialPaymentMethodState,
        item,
        name: item.name,
        receiveDays: item.receiveDays,
        fee: item.fee / 100 || 0,
        feeType: item.feeType
        // chartOfAccountsId: item.subChartOfAccounts.chartOfAccountsId,
        // subChartOfAccountsId: item.subChartOfAccountsId,
        // feeChartOfAccountsId: item.feeSubChartOfAccounts
        //   ? item.feeSubChartOfAccounts.chartOfAccountsId
        //   : "",
        // feeSubChartOfAccountsId: item.feeSubChartOfAccountsId,
        // financialAccountId: item.financialAccountId
      };
    }
    case actions.GET_PAYMENT_METHOD: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.EDIT_PAYMENT_METHOD: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.EDIT_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.GET_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        item: payload,
        name: payload.name,
        receiveDays: payload.receiveDays,
        fee: payload.fee / 100 || 0,
        feeType: payload.feeType,
        // chartOfAccountsId: payload.subChartOfAccounts.chartOfAccountsId,
        // subChartOfAccountsId: payload.subChartOfAccountsId,
        // feeChartOfAccountsId: payload.feeSubChartOfAccounts
        //   ? payload.feeSubChartOfAccounts.chartOfAccountsId
        //   : "",
        // feeSubChartOfAccountsId: payload.feeSubChartOfAccountsId,
        // financialAccountId: payload.financialAccountId,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
