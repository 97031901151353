import * as actions from "../../actions/transaction";

const initialState = {
	loading: false,
	items: [],
	item: null,
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...initialState
			};
		}
		case actions.GET_TRANSACTION:
		case actions.GET_TRANSACTIONS:
		case actions.EDIT_TRANSACTION: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_TRANSACTION_FAILED:
		case actions.GET_TRANSACTIONS_FAILED:
		case actions.EDIT_TRANSACTION_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_TRANSACTION_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.GET_TRANSACTION_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: false
			};
		}
		case actions.GET_TRANSACTIONS_SUCCESS: {
			return {
				...state,
				items: payload,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
