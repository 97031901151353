import * as api from "services/api";

export const GET_CASHIER = "[CASHIER] GET_CASHIER";
export const GET_CASHIER_FAILED = "[CASHIER] GET_CASHIER_FAILED";
export const GET_CASHIER_SUCCESS = "[CASHIER] GET_CASHIER_SUCCESS";

export const GET_LAST_CASHIER = "[CASHIER] GET_LAST_CASHIER";
export const GET_LAST_CASHIER_FAILED = "[CASHIER] GET_LAST_CASHIER_FAILED";
export const GET_LAST_CASHIER_SUCCESS = "[CASHIER] GET_LAST_CASHIER_SUCCESS";

export const CREATE_CASHIER = "[CASHIER] CREATE_CASHIER";
export const CREATE_CASHIER_FAILED = "[CASHIER] CREATE_CASHIER_FAILED";
export const CREATE_CASHIER_SUCCESS = "[CASHIER] CREATE_CASHIER_SUCCESS";

export const CLOSE_CASHIER = "[CASHIER] CLOSE_CASHIER";
export const CLOSE_CASHIER_FAILED = "[CASHIER] CLOSE_CASHIER_FAILED";
export const CLOSE_CASHIER_SUCCESS = "[CASHIER] CLOSE_CASHIER_SUCCESS";

export const CASHIER_WITHDRAW = "[CASHIER] CASHIER_WITHDRAW";
export const CASHIER_WITHDRAW_FAILED = "[CASHIER] CASHIER_WITHDRAW_FAILED";
export const CASHIER_WITHDRAW_SUCCESS = "[CASHIER] CASHIER_WITHDRAW_SUCCESS";

export const ADD_CREDIT = "[CASHIER] ADD_CREDIT";
export const ADD_CREDIT_FAILED = "[CASHIER] ADD_CREDIT_FAILED";
export const ADD_CREDIT_SUCCESS = "[CASHIER] ADD_CREDIT_SUCCESS";

export const PRINT_CREDIT = "[PRODUCT SALE] PRINT_CREDIT";
export const PRINT_CREDIT_FAILED = "[PRODUCT SALE] PRINT_CREDIT_FAILED";
export const PRINT_CREDIT_SUCCESS = "[PRODUCT SALE] PRINT_CREDIT_SUCCESS";

export const SET_VALUE = "[CASHIER] SET_VALUE";
export const CLEAR_VALUE = "[CASHIER] CLEAR_VALUE";

export function getCurrentCashier() {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CASHIER
		});

		const { token } = getState().login;

		let response = await api.sendGet("/cashier/current", {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_CASHIER_SUCCESS,
				payload: json.model
			});
		} else {
			dispatch({ type: GET_CASHIER_FAILED });
		}
	};
}

export function getLastCashier() {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_LAST_CASHIER
		});

		const { token } = getState().login;

		let response = await api.sendGet("/cashier/last", {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: GET_LAST_CASHIER_SUCCESS,
				payload: json.model
			});
		} else {
			dispatch({ type: GET_LAST_CASHIER_FAILED });
		}
	};
}

export function openCashier() {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CASHIER
		});

		const { login, cashier } = getState();
		const { token } = login;
		const { openValue, last, differenceNote } = cashier;

		let response = await api.sendPost(
			"/cashier",
			{
				openValue: Math.round(openValue * 100),
				differenceNote,
				lastCashierId: last ? last.id : null
			},
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CREATE_CASHIER_SUCCESS,
				payload: json.model
			});
		} else {
			dispatch({ type: CREATE_CASHIER_FAILED });
		}
	};
}

export function closeCashier(paymentMethods, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CLOSE_CASHIER
		});

		const { login, cashier } = getState();
		const { token } = login;
		const { closeValue, item } = cashier;

		let model = {
			...item,
			sales: null,
			withdraws: null,
			transactions: null,
			paymentMethods: paymentMethods.map(p => ({
				...p,
				paymentMethod: null
			})),
			closeValue: closeValue * 100
		};

		let response = await api.sendPut(`/cashier/${item.id}/close`, model, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CLOSE_CASHIER_SUCCESS,
				payload: json.model
			});

			callback && callback();
		} else {
			dispatch({ type: CLOSE_CASHIER_FAILED });
		}
	};
}

export function createWithdraw(withdraw, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CASHIER_WITHDRAW
		});

		const { login, cashier } = getState();
		const { token } = login;
		const { item } = cashier;

		let response = await api.sendPost(
			`/cashier/${item.id}/Withdraw`,
			withdraw,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: CASHIER_WITHDRAW_SUCCESS,
				payload: json.model
			});

			callback && callback();
		} else {
			try {
				let json = await response.json();

				dispatch({ type: CASHIER_WITHDRAW_FAILED, payload: json.errors });
				callback && callback(json.errors);
			} catch (error) {
				dispatch({ type: CASHIER_WITHDRAW_FAILED, payload: error });
				callback && callback(error);
			}
		}
	};
}

export function createCredit(credit, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_CREDIT
		});

		const { login, cashier } = getState();
		const { token } = login;
		const { item } = cashier;

		let response = await api.sendPost(`/cashier/${item.id}/Credit`, credit, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			dispatch({
				type: ADD_CREDIT_SUCCESS,
				payload: json.model
			});

			callback && callback();
		} else {
			try {
				let json = await response.json();

				dispatch({ type: ADD_CREDIT_FAILED, payload: json.errors });
				callback && callback(json.errors);
			} catch (error) {
				dispatch({ type: ADD_CREDIT_FAILED, payload: error });
				callback && callback(error);
			}
		}
	};
}

export function printCreditReceipt(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: PRINT_CREDIT
		});

		const { login, cashier } = getState();

		const { token } = login;
		const { printCredit } = cashier;

		let response = await api.sendPost(
			`/cashier/credit/${printCredit.id}/print?local=${cashier.local}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			callback && callback();

			dispatch({
				type: PRINT_CREDIT_SUCCESS
			});
		} else {
			var json = await response.json();

			callback && callback(json.errors);

			dispatch({ type: PRINT_CREDIT_FAILED, payload: json.errors });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(payload) {
	return {
		type: CLEAR_VALUE,
		payload
	};
}
