import * as actions from "../../../../actions/chartOfAccount/sub/create";

const initialSubChartOfAccountState = {
  name: "",
  code: "",
  chartOfAccountsId: "",
  description: "",
  errors: {}
};

const initialState = {
  loading: false,
  ...initialSubChartOfAccountState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialSubChartOfAccountState
      };
    }
    case actions.ADD_SUB_CHART_OF_ACCOUNT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_SUB_CHART_OF_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_SUB_CHART_OF_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
