import * as actions from "../../actions/financialAccount";

const initialState = {
  items: [],
  loading: false,
  errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialState
      };
    }
    case actions.GET_FINANCIAL_ACCOUNTS: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.GET_FINANCIAL_ACCOUNTS_FAILED: {
      return {
        ...state,
        errors: payload,
        loading: false
      };
    }
    case actions.GET_FINANCIAL_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        items: payload || [],
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
