import _ from "lodash";

import * as api from "services/api";

export const EDIT_TRANSACTION = "[TRANSACTION EDIT] EDIT_TRANSACTION";
export const EDIT_TRANSACTION_SUCCESS =
	"[TRANSACTION EDIT] EDIT_TRANSACTION_SUCCESS";
export const EDIT_TRANSACTION_FAILED =
	"[TRANSACTION EDIT] EDIT_TRANSACTION_FAILED";

export const GET_TRANSACTION = "[TRANSACTION EDIT] GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS =
	"[TRANSACTION EDIT] GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILED =
	"[TRANSACTION EDIT] GET_TRANSACTION_FAILED";

export const GET_TRANSACTIONS = "[TRANSACTIONS] GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS =
	"[TRANSACTIONS] GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILED = "[TRANSACTIONS] GET_TRANSACTIONS_FAILED";

export const SET_VALUE = "[TRANSACTION EDIT] SET_VALUE";
export const CLEAR_VALUE = "[TRANSACTION EDIT] CLEAR_VALUE";

export function getTransactionState(startDate, endDate, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_TRANSACTIONS
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/transaction/state?startDate=${startDate}&endDate=${endDate}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_TRANSACTIONS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_TRANSACTIONS_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_TRANSACTIONS_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}

export function getTransaction(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_TRANSACTION
		});

		const { token } = getState().login;

		let response = await api.sendGet("/transaction/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let item = json.model;
			dispatch({
				type: GET_TRANSACTION_SUCCESS,
				payload: item
			});
		} else {
			dispatch({ type: GET_TRANSACTION_FAILED });
		}
	};
}

export function editTransaction(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_TRANSACTION
		});

		const { login } = getState();

		let response = await api.sendPut("/transaction/" + id, model, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			let result = await response.json();

			dispatch({
				type: EDIT_TRANSACTION_SUCCESS,
				payload: result
			});

			callback && callback(null, result.model);
		} else {
			dispatch({ type: EDIT_TRANSACTION_FAILED });
			callback && callback("Error");
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
