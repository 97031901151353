import { sendGet } from "services/api";

export const GET_BUDGETS = "GET_BUDGETS";
export const GET_BUDGETS_SUCCESS = "GET_BUDGETS_SUCCESS";
export const GET_BUDGETS_FAILED = "GET_BUDGETS_FAILED";
export const SET_SEARCH_TEXT = "[BUDGET REPORT] SET_SEARCH_TEXT";

export function getBudgets() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_BUDGETS
    });

    const { token } = getState().login;

    let response = await sendGet("/budget", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      let budgets = json.model;
      dispatch({
        type: GET_BUDGETS_SUCCESS,
        payload: budgets
      });
    } else {
      dispatch({ type: GET_BUDGETS_FAILED });
    }
  };
}
