import * as actions from "../../../actions/clientStock/edit";

const initialClientStockEdit = {
	status: "1",
	clientId: "",
	userId: "",
	visitsByMonth: 0,
	displacement: 0,
	errors: {}
};

const initialState = {
	loading: false,
	...initialClientStockEdit
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			if (state.item && !payload) {
				return {
					...state,
					...initialClientStockEdit,
					...state.item,
					item: state.item
				};
			} else {
				return {
					...state,
					...initialClientStockEdit
				};
			}
		}
		case actions.GET_STOCK: {
			return {
				...state,
				loading: true
			};
		}
		case actions.EDIT_STOCK: {
			return {
				...state,
				loading: true
			};
		}
		case actions.GET_STOCK_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_STOCK_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.EDIT_STOCK_SUCCESS: {
			return {
				...state,
				...initialClientStockEdit,
				loading: false
			};
		}
		case actions.GET_STOCK_SUCCESS: {
			return {
				...state,
				item: payload,
				...payload,
				moreInfo: payload.moreInfo || "",
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
