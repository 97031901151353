import { sendGet } from "services/api";

export const GET_LOTS = "[LOT REPORT] GET_LOTS";
export const GET_LOTS_SUCCESS = "[LOT REPORT] GET_LOTS_SUCCESS";
export const GET_LOTS_FAILED = "[LOT REPORT] GET_LOTS_FAILED";

export const SET_VALUE = "[LOT REPORT] SET_VALUE";
export const CLEAR_VALUE = "[LOT REPORT] CLEAR_VALUE";

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload,
  };
}

export function clearValue(payload) {
  return {
    type: CLEAR_VALUE,
    payload,
  };
}

export function getLots(
  startDate,
  endDate,
  skip,
  take,
  filter,
  sort,
  desc,
  toExport,
  callback
) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LOTS,
    });

    const { login } = getState();

    const { token } = login;

    let response = await sendGet(
      `/Lot?startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
        "&filters=null"}`,
      {
        Authorization: "Bearer " + token,
      }
    );

    if (response.ok) {
      let result = await response.json();

      if (result.isValid) {
        if (toExport) {
          dispatch(setValue({ loading: false }));
        } else {
          dispatch({
            type: GET_LOTS_SUCCESS,
            payload: result.model,
          });

          if (result.params)
            dispatch(
              setValue({
                pages: Math.ceil(result.params.count / take),
                ...result.params,
              })
            );
        }

        callback && callback(null, result.model);
      } else {
        dispatch({ type: GET_LOTS_FAILED, payload: result.errors });
        callback && callback(result.errors, result.model);
      }
    } else {
      dispatch({ type: GET_LOTS_FAILED });

      callback && callback("Ocorreu um erro ao tentar criar a venda");
    }
  };
}
