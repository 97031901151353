import * as actions from "../../../actions/clientStock/create";

const initialClientStockCreate = {
	status: "1",
	clientId: "",
	userId: "",
	visitsByMonth: 0,
	displacement: 0,
	errors: {}
};

const initialState = {
	loading: false,
	...initialClientStockCreate
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUE: {
			return {
				...state,
				...initialClientStockCreate
			};
		}
		case actions.ADD_STOCK: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ADD_STOCK_FAILED: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_STOCK_SUCCESS: {
			return {
				...state,
				...initialClientStockCreate,
				loading: false
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
