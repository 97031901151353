import * as api from "services/api";

export const GET_BILLING = "[BILLING] GET_BILLING";
export const GET_BILLING_FAILED = "[BILLING] GET_BILLING_FAILED";
export const GET_BILLING_SUCCESS = "[BILLING] GET_BILLING_SUCCESS";

export const SET_VALUE = "[BILLING] SET_VALUE";
export const CLEAR_ITEMS = "[BILLING] CLEAR_ITEMS";

export function getBilling(from, to) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BILLING
		});

		const { token } = getState().login;

		let response = await api.sendGet(`/billing/${from}/${to}`, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;

			dispatch({
				type: GET_BILLING_SUCCESS,
				payload
			});

			if (json.params)
				dispatch(
					setValue({
						...json.params
					})
				);
		} else {
			dispatch({ type: GET_BILLING_FAILED });
		}
	};
}

export function clearItems() {
	return {
		type: CLEAR_ITEMS
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}
