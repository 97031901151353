import * as api from "services/api";

export const GET_ACCOUNT_RECEIVABLES =
  "[ACCOUNT_RECEIVABLE] GET_ACCOUNT_RECEIVABLES";
export const GET_ACCOUNT_RECEIVABLES_FAILED =
  "[ACCOUNT_RECEIVABLE] GET_ACCOUNT_RECEIVABLES_FAILED";
export const GET_ACCOUNT_RECEIVABLES_SUCCESS =
  "[ACCOUNT_RECEIVABLE] GET_ACCOUNT_RECEIVABLES_SUCCESS";

export const SET_VALUE = "[ACCOUNT_RECEIVABLE] SET_VALUE";
export const CLEAR_VALUE = "[ACCOUNT_RECEIVABLE] CLEAR_VALUE";

export function getAccountReceivables() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ACCOUNT_RECEIVABLES
    });

    const { token } = getState().login;

    let response = await api.sendGet("/AccountReceivable", {
      Authorization: "Bearer " + token
    });

    if (response.ok) {
      let json = await response.json();

      dispatch({
        type: GET_ACCOUNT_RECEIVABLES_SUCCESS,
        payload: json.model
      });
    } else {
      dispatch({ type: GET_ACCOUNT_RECEIVABLES_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
