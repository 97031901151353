import * as api from "services/api";
import _ from "lodash";
import history from "../../../../history";

export const EDIT_STOCK = "[STOCK EDIT] EDIT_STOCK";
export const EDIT_STOCK_SUCCESS = "[STOCK EDIT] EDIT_STOCK_SUCCESS";
export const EDIT_STOCK_FAILED = "[STOCK EDIT] EDIT_STOCK_FAILED";

export const GET_STOCK = "[STOCK EDIT] GET_STOCK";
export const GET_STOCK_SUCCESS = "[STOCK EDIT] GET_STOCK_SUCCESS";
export const GET_STOCK_FAILED = "[STOCK EDIT] GET_STOCK_FAILED";

export const SET_VALUE = "[STOCK EDIT] SET_VALUE";
export const CLEAR_VALUE = "[STOCK EDIT] CLEAR_VALUE";

export function getStock(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_STOCK
		});

		const { token } = getState().login;

		let response = await api.sendGet("/stock/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let order = json.model;
			dispatch({
				type: GET_STOCK_SUCCESS,
				payload: order
			});
		} else {
			dispatch({ type: GET_STOCK_FAILED });
		}
	};
}

export function editStock() {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_STOCK
		});

		const { login, stockEdit } = getState();

		let { item, clientId, amount, displacement, visitsByMonth } = stockEdit;

		const errors = {};

		dispatch(
			setValue({
				errors
			})
		);

		if (_.keys(errors).length > 0) return dispatch({ type: EDIT_STOCK_FAILED });

		let response = await api.sendPut(
			"/stock/" + item.id,
			{
				...item,
				clientId,
				amount,
				displacement,
				visitsByMonth
			},
			{
				Authorization: "Bearer " + login.token
			}
		);

		if (response.ok) {
			const result = await response.json();

			dispatch({
				type: EDIT_STOCK_SUCCESS
			});

			dispatch(clearValue());

			history.push("/stocks/" + result.model.id);
		} else {
			dispatch({ type: EDIT_STOCK_FAILED });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(force) {
	return {
		type: CLEAR_VALUE,
		payload: force
	};
}
