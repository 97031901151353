import _ from "lodash";

import * as api from "services/api";
import ls from "Localization";
import history from "../../../../history";

export const EDIT_PRODUCT = "[PRODUCT EDIT] EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "[PRODUCT EDIT] EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "[PRODUCT EDIT] EDIT_PRODUCT_FAILED";

export const GET_PRODUCT = "[PRODUCT EDIT] GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "[PRODUCT EDIT] GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "[PRODUCT EDIT] GET_PRODUCT_FAILED";

export const SET_VALUE = "[PRODUCT EDIT] SET_VALUE";
export const CLEAR_VALUE = "[PRODUCT EDIT] CLEAR_VALUE";

export function getProduct(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PRODUCT
		});

		const { token } = getState().login;

		let response = await api.sendGet("/product/" + id, {
			Authorization: "Bearer " + token
		});

		if (response.ok) {
			let json = await response.json();

			let item = json.model;
			dispatch({
				type: GET_PRODUCT_SUCCESS,
				payload: item
			});
		} else {
			dispatch({ type: GET_PRODUCT_FAILED });
		}
	};
}

export function editProduct() {
	return async (dispatch, getState) => {
		dispatch({
			type: EDIT_PRODUCT
		});

		const { login, productEdit } = getState();

		let {
			code,
			name,
			description,
			buyPrice,
			rentalPrice,
			rentalPrices,
			sellPrice,
      stockPrice,
			status,
			productCategoryId,
			maxLocation,
			weight,
			baseAmount,
			baseYear,
			item,
			lotLimit,
			type,
			file
		} = productEdit;

		const errors = {};

		if (!code) errors.code = ls.required;
		if (!name) errors.name = ls.required;
		if (!weight || weight < 1) errors.weight = ls.required;

		dispatch(setValue({ errors }));

		if (_.keys(errors).length > 0)
			return dispatch({ type: EDIT_PRODUCT_FAILED });

		if (file) {
			let response = await api.sendPostFile("/product/upload", file, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				const result = await response.json();
				item.image = result.model;
			}
		}

		item.buyPrice = (buyPrice * 100).toFixed(0);
		item.rentalPrice = (rentalPrice * 100).toFixed(0);
		item.rentalPrices = rentalPrices;
		item.sellPrice = (sellPrice * 100).toFixed(0);
		item.stockPrice = (stockPrice * 100).toFixed(0);

		item.baseAmount = baseAmount;
		item.baseYear = baseYear;
		item.code = code;
		item.name = name;
		item.description = description;
		item.status = status;
		item.maxLocation = maxLocation;
		item.productCategoryId = productCategoryId;
		item.weight = weight;
		item.type = type;
		item.lotLimit = lotLimit;

		let response = await api.sendPut("/product/" + item.id, item, {
			Authorization: "Bearer " + login.token
		});

		if (response.ok) {
			let result = await response.json();

			dispatch({
				type: EDIT_PRODUCT_SUCCESS
			});

			dispatch(clearValue());

			history.push("/products/" + result.model.id);
		} else {
			dispatch({ type: EDIT_PRODUCT_FAILED });
		}
	};
}

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue() {
	return {
		type: CLEAR_VALUE
	};
}
