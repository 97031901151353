import * as api from "services/api";

import history from "../../../../history";

import _ from "lodash";

export const ADD_PAYMENT_METHOD = "[PAYMENT METHOD CREATE] ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_SUCCESS =
  "[PAYMENT METHOD CREATE] ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_FAILED =
  "[PAYMENT METHOD CREATE] ADD_PAYMENT_METHOD_FAILED";

export const SET_VALUE = "[PAYMENT METHOD CREATE] SET_VALUE";
export const CLEAR_VALUE = "[PAYMENT METHOD CREATE] CLEAR_VALUE";

export function addPaymentMethod() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_PAYMENT_METHOD
    });

    const { login, paymentMethodCreate } = getState();

    let {
      name,
      receiveDays,
      fee,
      feeType,
      // chartOfAccountsId,
      // subChartOfAccountsId,
      // feeChartOfAccountsId,
      // feeSubChartOfAccountsId,
      financialAccountId
    } = paymentMethodCreate;

    const errors = {};

    if (!name) errors.name = "Informe um nome";
    if (receiveDays < 0) errors.receiveDays = "Dia inválido";
    // if (!chartOfAccountsId)
    //   errors.chartOfAccountsId = "Informe um plano de contas";
    // if (!subChartOfAccountsId)
    //   errors.subChartOfAccountsId = "Informe um sub plano de contas";

    if (fee < 0) {
      if (!fee) errors.fee = "Taxa inválida";
    }

    // if (fee > 0) {
    //   if (!feeChartOfAccountsId)
    //     errors.feeChartOfAccountsId = "Informe um plano de contas";
    //   if (!feeSubChartOfAccountsId)
    //     errors.feeSubChartOfAccountsId = "Informe um sub plano de contas";
    // }

    if (!financialAccountId)
      errors.financialAccountId = "Informe um conta financeira";

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0)
      return dispatch({ type: ADD_PAYMENT_METHOD_FAILED });

    let response = await api.sendPost(
      "/PaymentMethod",
      {
        name,
        receiveDays,
        fee: Math.round(fee * 100),
        feeType,
        // subChartOfAccountsId,
        // feeSubChartOfAccountsId,
        financialAccountId
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      dispatch({
        type: ADD_PAYMENT_METHOD_SUCCESS
      });

      dispatch(clearValue());

      history.push("/PaymentMethod");
    } else {
      dispatch({ type: ADD_PAYMENT_METHOD_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
