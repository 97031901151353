import * as actions from "../../../../actions/product/detail/item";

const productItemInitialState = {
  code: "340000000000000000000",
  locationCounter: 0,
  boughtIn: null,
  soldIn: null,
  status: 1,
  note: "",
  error: "",
};

const initialState = {
  loading: false,
  items: [],
  edit: null,
  ...productItemInitialState,
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...productItemInitialState,
        edit: null,
      };
    }
    case actions.GET_ITEMS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_ITEMS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_ITEMS_SUCCESS: {
      return {
        ...state,
        items: payload ? payload : [],
        loading: false,
      };
    }
    case actions.ADD_ITEM: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.ADD_ITEM_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case actions.ADD_ITEM_SUCCESS: {
      state.items.push(payload);
      return {
        ...state,
        items: [...state.items],
        loading: false,
      };
    }
    case actions.EDIT_ITEM: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.EDIT_ITEM_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case actions.EDIT_ITEM_SUCCESS: {
      const items = state.items.filter((c) => c.id !== payload.id);
      items.push(payload);
      return {
        ...state,
        items,
        loading: false,
      };
    }
    case actions.DELETE_ITEM: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.DELETE_ITEM_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.DELETE_ITEM_SUCCESS: {
      const items = state.items.filter((c) => c.id !== payload);

      return {
        ...state,
        items,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
