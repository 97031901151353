import * as api from "services/api";

export const GET_STOCKS = "[STOCK] GET_STOCKS";
export const GET_STOCK_FAILED = "[STOCK] GET_STOCK_FAILED";
export const GET_STOCK_SUCCESS = "[STOCK] GET_STOCK_SUCCESS";

export const GET_ITEMS_PER_STOCK = "[STOCK] GET_ITEMS_PER_STOCK";
export const GET_ITEMS_PER_STOCK_FAILED = "[STOCK] GET_ITEMS_PER_STOCK_FAILED";
export const GET_ITEMS_PER_STOCK_SUCCESS =
	"[STOCK] GET_ITEMS_PER_STOCK_SUCCESS";

export const SET_VALUE = "[STOCK] SET_VALUE";
export const CLEAR_VALUE = "[STOCK] CLEAR_VALUE";

export function setValue(payload) {
	return {
		type: SET_VALUE,
		payload
	};
}

export function clearValue(payload) {
	return {
		type: CLEAR_VALUE,
		payload
	};
}

export function getStocks(
	startDate,
	endDate,
	skip,
	take,
	filter,
	sort,
	desc,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_STOCKS
		});

		const { login } = getState();

		const { token } = login;

		let response = await api.sendGet(
			`/Stock?startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
				"&filters=null"}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_STOCK_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({ type: GET_STOCK_FAILED, payload: result.errors });
				callback && callback(result.errors, result.model);
			}
		} else {
			dispatch({ type: GET_STOCK_FAILED });

			callback && callback("Ocorreu um erro ao tentar criar a venda");
		}
	};
}
