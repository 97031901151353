import * as actions from "../../../actions/paymentMethod/create";

const initialPaymentMethodState = {
  name: "",
  receiveDays: 0,
  fee: 0,
  feeType: 0,
  chartOfAccountsId: "",
  subChartOfAccountsId: "",
  feeChartOfAccountsId: "",
  feeSubChartOfAccountsId: "",
  financialAccountId: "",
  errors: {}
};

const initialState = {
  loading: false,
  ...initialPaymentMethodState
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.SET_VALUE: {
      return {
        ...state,
        ...payload
      };
    }
    case actions.CLEAR_VALUE: {
      return {
        ...state,
        ...initialPaymentMethodState
      };
    }
    case actions.ADD_PAYMENT_METHOD: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.ADD_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case actions.ADD_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
