import * as api from "services/api";

import history from "../../../../history";

import _ from "lodash";

export const ADD_PRODUCTCATEGORY =
  "[PRODUCTCATEGORY CREATE] ADD_PRODUCTCATEGORY";
export const ADD_PRODUCTCATEGORY_SUCCESS =
  "[PRODUCTCATEGORY CREATE] ADD_PRODUCTCATEGORY_SUCCESS";
export const ADD_PRODUCTCATEGORY_FAILED =
  "[PRODUCTCATEGORY CREATE] ADD_PRODUCTCATEGORY_FAILED";

export const SET_VALUE = "[PRODUCTCATEGORY CREATE] SET_VALUE";
export const CLEAR_VALUE = "[PRODUCTCATEGORY CREATE] CLEAR_VALUE";

export function addProductCategory() {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_PRODUCTCATEGORY
    });

    const { login, productCategoryCreate } = getState();

    let { name, description } = productCategoryCreate;

    const errors = {};

    if (!name) {
      errors.name = "Informe um nome";
    }

    dispatch(
      setValue({
        errors
      })
    );

    if (_.keys(errors).length > 0)
      return dispatch({ type: ADD_PRODUCTCATEGORY_FAILED });

    let response = await api.sendPost(
      "/product/category",
      {
        name,
        description
      },
      {
        Authorization: "Bearer " + login.token
      }
    );

    if (response.ok) {
      dispatch({
        type: ADD_PRODUCTCATEGORY_SUCCESS
      });

      dispatch(clearValue());

      history.push("/products/categories");
    } else {
      dispatch({ type: ADD_PRODUCTCATEGORY_FAILED });
    }
  };
}

export function setValue(payload) {
  return {
    type: SET_VALUE,
    payload
  };
}

export function clearValue() {
  return {
    type: CLEAR_VALUE
  };
}
