import LocalizedStrings from "react-localization";

import pt from "./pt";
// import en from "./en";

const strings = new LocalizedStrings({
	pt
	// en
});

export default strings;
