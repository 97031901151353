import * as api from "services/api";

export const GET_AVAILABILITY = "[PRODUCT ITEM AVALLABILITY] GET_AVAILABILITY";
export const GET_AVAILABILITY_FAILED =
	"[PRODUCT ITEM AVALLABILITY] GET_AVAILABILITY_FAILED";
export const GET_AVAILABILITY_SUCCESS =
	"[PRODUCT ITEM AVALLABILITY] GET_AVAILABILITY_SUCCESS";

export const GET_REQUIRED = "[PRODUCT ITEM REQUIRED] GET_AVAILABILITY";
export const GET_REQUIRED_FAILED =
	"[PRODUCT ITEM REQUIRED] GET_REQUIRED_FAILED";
export const GET_REQUIRED_SUCCESS =
	"[PRODUCT ITEM REQUIRED] GET_REQUIRED_SUCCESS";

export const SET_SEARCH_TEXT = "[PRODUCT ITEM AVALLABILITY] SET_SEARCH_TEXT";
export const CLEAR_ITEMS = "[PRODUCT ITEM AVALLABILITY] CLEAR_ITEMS";

export function getItemsAvailability(from, to, intelligent) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_AVAILABILITY
		});

		const { token } = getState().login;

		let response = await api.sendGet(
			"/product/item/Availability/" +
				from +
				"/" +
				to +
				"?intelligent=" +
				intelligent,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;
			dispatch({
				type: GET_AVAILABILITY_SUCCESS,
				payload
			});
		} else {
			dispatch({ type: GET_AVAILABILITY_FAILED });
		}
	};
}

export function getRequiredItems(from, to) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_REQUIRED
		});

		const { token } = getState().login;

		let response = await api.sendGet(
			"/product/item/Required/" + from + "/" + to,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.ok) {
			let json = await response.json();

			let payload = json.model;
			dispatch({
				type: GET_REQUIRED_SUCCESS,
				payload
			});
		} else {
			dispatch({ type: GET_REQUIRED_FAILED });
		}
	};
}

export function clearItems() {
	return {
		type: CLEAR_ITEMS
	};
}
